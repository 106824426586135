import qs from 'qs';

export const getHandbookQuery = search => qs.parse(search, { ignoreQueryPrefix: true });

export const formHandbookUrl = ({
  pathname,
  search,
  article
}) => {
  const url = `${pathname}${article || search ? '?' : ''}${search ? `search=${encodeURIComponent(search)}` : ''}${search && article ? '&' : ''}${article ? `article=${article}` : ''}`;

  return url;
};

export const getFolderUrl = ({ portalCollabSlug, url }) => `/${portalCollabSlug}/handbook${url}`;
