
import {strings} from './i18n';
const translate = {
  buttons: {
    Save: strings("buttons.Save"),
    Confirm: strings("buttons.Confirm"),
    Send: strings("buttons.Send"),
    Cancel: strings("buttons.Cancel"),
    yes: strings("buttons.yes"),
    no: strings("buttons.no"),
    goBack: strings("buttons.goBack"),
    Edit: strings("buttons.Edit"),
    Delete: strings("buttons.Delete"),
    Publish: strings("buttons.Publish")
  },
  article: {
    source: strings("article.source"),
    editArticles: strings("article.editArticles"),
    yourArticles: strings("article.yourArticles"),
    notificationHeader: strings("article.notificationHeader"),
    notificationTitle: strings("article.notificationTitle"),
    pregnantWeek: strings("article.pregnantWeek"),
    parentWeek: strings("article.parentWeek")
  },
  articleEdit: {
    validate_required: strings("articleEdit.validate_required"),
    validate_maxLength: strings("articleEdit.validate_maxLength"),
    validate_minLength: strings("articleEdit.validate_minLength"),
    validate_default: strings("articleEdit.validate_default"),
    validate_carefeedItemsWeek: strings("articleEdit.validate_carefeedItemsWeek"),
    validate_higherThenWeekFrom: strings("articleEdit.validate_higherThenWeekFrom"),
    validate_youtubeUrl: strings("articleEdit.validate_youtubeUrl"),
    fieldName_title: strings("articleEdit.fieldName_title"),
    fieldName_mainVideoUrl: strings("articleEdit.fieldName_mainVideoUrl"),
    video_tooltip_label: strings("articleEdit.video_tooltip_label"),
    video_tooltip_input_placeholder: strings("articleEdit.video_tooltip_input_placeholder"),
    link_tooltip_label: strings("articleEdit.link_tooltip_label"),
    fieldName_oneliner: strings("articleEdit.fieldName_oneliner"),
    fieldName_summary: strings("articleEdit.fieldName_summary"),
    fieldName_keyTakeaway: strings("articleEdit.fieldName_keyTakeaway"),
    fieldName_body: strings("articleEdit.fieldName_body"),
    preview: strings("articleEdit.preview"),
    createNewArticle: strings("articleEdit.createNewArticle"),
    editPhoto: strings("articleEdit.editPhoto"),
    addPhoto: strings("articleEdit.addPhoto"),
    target: strings("articleEdit.target"),
    addCarefeedItem: strings("articleEdit.addCarefeedItem"),
    showInCarefeed: strings("articleEdit.showInCarefeed"),
    showInCarefeedValues_inCarefeed: strings("articleEdit.showInCarefeedValues_inCarefeed"),
    showInCarefeedValues_notCarefeed: strings("articleEdit.showInCarefeedValues_notCarefeed"),
    target_noModuleSelected: strings("articleEdit.target_noModuleSelected"),
    target_pregnancyAndBirth: strings("articleEdit.target_pregnancyAndBirth"),
    target_parent: strings("articleEdit.target_parent"),
    reallyDeleteArticle: strings("articleEdit.reallyDeleteArticle"),
    chooseCarefeedItemWeeks: strings("articleEdit.chooseCarefeedItemWeeks"),
    carefeeedItemWeeksModal: strings("articleEdit.carefeeedItemWeeksModal"),
    carefeedItemWeekButton: strings("articleEdit.carefeedItemWeekButton"),
    carefeedItemWeekFromLabel: strings("articleEdit.carefeedItemWeekFromLabel"),
    carefeedItemWeekToLabel: strings("articleEdit.carefeedItemWeekToLabel"),
    reachedTheMaxAmountOfArticles: strings("articleEdit.reachedTheMaxAmountOfArticles")
  },
  carefeed: {
    weeks: strings("carefeed.weeks"),
    week: strings("carefeed.week"),
    pregnantHeader: strings("carefeed.pregnantHeader"),
    parentHeader: strings("carefeed.parentHeader"),
    childAge: strings("carefeed.childAge"),
    age: strings("carefeed.age"),
    months: strings("carefeed.months"),
    month: strings("carefeed.month")
  },
  nav: {
    admin: strings("nav.admin"),
    carefeedPregnancy: strings("nav.carefeedPregnancy"),
    carefeedParents: strings("nav.carefeedParents"),
    choosePortal: strings("nav.choosePortal"),
    handbook: strings("nav.handbook"),
    analytics: strings("nav.analytics"),
    logout: strings("nav.logout")
  },
  portal: {
    addPhoneNumber: strings("portal.addPhoneNumber"),
    addPhoneNumberHeader: strings("portal.addPhoneNumberHeader"),
    admin: strings("portal.admin"),
    admini: strings("portal.admini"),
    surName: strings("portal.surName"),
    name: strings("portal.name"),
    phoneNumber: strings("portal.phoneNumber"),
    editor: strings("portal.editor"),
    editori: strings("portal.editori"),
    enterPhoneNumber: strings("portal.enterPhoneNumber"),
    choosePortal: strings("portal.choosePortal")
  },
  analytics: {
    loading: strings("analytics.loading"),
    loadingMunicipality: strings("analytics.loadingMunicipality"),
    loadingUserInsight: strings("analytics.loadingUserInsight")
  },
  stats: {
    statistics: strings("stats.statistics"),
    newUserChartLabel: strings("stats.newUserChartLabel"),
    newUsersInWeeks: strings("stats.newUsersInWeeks"),
    top10Read: strings("stats.top10Read"),
    week: strings("stats.week"),
    thisWeek: strings("stats.thisWeek"),
    userCount: strings("stats.userCount"),
    weekPercentUp: strings("stats.weekPercentUp"),
    weekPercentDown: strings("stats.weekPercentDown"),
    weekNoChange: strings("stats.weekNoChange")
  },
  notFound: {
    oops: strings("notFound.oops"),
    pageNotFoundTitle: strings("notFound.pageNotFoundTitle"),
    pageNotFoundDescription: strings("notFound.pageNotFoundDescription")
  },
  error: {
    noAccesToPhoneNumbersInPortal: strings("error.noAccesToPhoneNumbersInPortal"),
    serverError: strings("error.serverError"),
    phoneNumberIsInvalid: strings("error.phoneNumberIsInvalid")
  },
  handbook: {
    search: strings("handbook.search"),
    title: strings("handbook.title"),
    searchResults: strings("handbook.searchResults"),
    noArticles: strings("handbook.noArticles")
  },
  alt: {
    article: strings("alt.article"),
    handbookFolder: strings("alt.handbookFolder"),
    logo: strings("alt.logo"),
    portal: strings("alt.portal"),
    linkImage: strings("alt.linkImage")
  }
}

export default translate;