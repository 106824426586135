import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Container, ColumnsWrapper } from '../../components';
import HandbookSearchAndFolders from './HandbookSearchAndFolders';
import HandbookArticle from './HandbookArticle';
import {
  getFolderAndArticles,
  cleanFolderAndArticle,
  selectFolderOrArticle,
} from '../../actions/handbookActions';
import { getArticleContent } from '../../actions/articlesAction';
import { deSelectArticle } from '../../actions/articlesAction';
import { TYPE_ARTICLE } from '../../config/constants/constants';
import lang from '../../translationWrapper';
import { getHandbookQuery } from './Handbook.helpers';
import analytics, { Events } from '../../services/analytics/Analytics';

const Handbook = () => {
  const mapStateToProps = ({ sanity: { handbook: { levelsOfFolders } = {} } = {} }) =>
    levelsOfFolders[0];
  const firstLevel = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  useEffect(() => {
    !firstLevel && dispatch(getFolderAndArticles());
    analytics.logEvent(Events.HANDBOOK);
    return () => {
      dispatch(cleanFolderAndArticle());
    };
  }, []);

  useEffect(() => {
    if (search) {
      const { article } = getHandbookQuery(search);
      if (article) {
        dispatch(getArticleContent(article));
        dispatch(selectFolderOrArticle({ type: TYPE_ARTICLE, id: article }));
      }
    }

    return () => {
      // https://github.com/Helseoversikt/helseoversikt-reactjs/pull/100/files#r700826422
      setTimeout(() => {
        dispatch(deSelectArticle());
      }, 0);
    };
  }, [search]);

  return (
    <Container>
      <h1 className="ui dividing header">
        <div className="content">{lang.handbook.title}</div>
      </h1>
      <ColumnsWrapper>
        <div className="ui six wide column">
          <HandbookSearchAndFolders pathname={pathname} urlSearch={search} />
        </div>
        <div className="ui eight wide column">
          <HandbookArticle />
        </div>
      </ColumnsWrapper>
    </Container>
  );
};

export default Handbook;
