import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { show } from 'redux-modal';
import { useDispatch } from 'react-redux';
import { Button } from '../../components';
import {
  deletePhoneNumberFromPortalCollab,
  selectEditUserInPortalCollab,
} from '../../actions/adminActions';
import lang from '../../translationWrapper';
import { ADD_PHONE_NUMBER_TO_PORTAL_ACCESS_MODAL } from '../../config/constants/constants';

const ButtonsForUser = ({ id, name, surname, phoneNumber, adminStatus, editStatus, portalCollab }) => {
  const dispatch = useDispatch();
  const [deleteButton, toggleDeleteButton] = useState(false);

  return deleteButton ?
    <Fragment>
      <Button
        className="ui button icon warning"
        onClick={() => dispatch(deletePhoneNumberFromPortalCollab(id))}
      >
        <i className="trash icon" />
      </Button>
      <Button className="ui button" onClick={() => toggleDeleteButton(false)}>
        {lang.buttons.Cancel}
      </Button>
    </Fragment> :
    <Fragment>
      <Button className="ui basic button" onClick={() => toggleDeleteButton(true)}>
        {lang.buttons.Delete}
      </Button>
      <Button
        className="ui button icon"
        onClick={() => {
          dispatch(
            selectEditUserInPortalCollab({
              id,
              name,
              surname,
              phoneNumber,
              admin: adminStatus,
              edit: editStatus,
              portalCollab,
            })
          );
          dispatch(show(ADD_PHONE_NUMBER_TO_PORTAL_ACCESS_MODAL));
        }}
      >
        <i className="edit icon" />
      </Button>
    </Fragment>;
};

ButtonsForUser.propTypes = {
  adminStatus: PropTypes.bool,
  editStatus: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  surname: PropTypes.string,
  phoneNumber: PropTypes.string,
  portalCollab: PropTypes.string,
};

export default ButtonsForUser;
