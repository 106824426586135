import React, { Fragment, useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import { connectModal, hide } from 'redux-modal';
import {
  Modal,
  Button,
  PhoneNumberInputWithCountry,
  Checkbox,
  ErrorLabel,
  InputText,
} from '../../components';
import {
  addPhoneNumberToPortalCollab,
  editPhoneNumberForPortalCollab,
  selectEditUserInPortalCollab,
} from '../../actions/adminActions';
import {
  ADD_PHONE_NUMBER_TO_PORTAL_ACCESS_MODAL,
  STATUS_CODE_SERVER_ERROR,
  STATUS_CODE_USER_DATA_IS_INVALID,
} from '../../config/constants/constants';
import lang from '../../translationWrapper';

const AddPhoneNumberModal = () => {
  const mapStateToProps = ({ addPhoneModal, adminPanelPhoneNumbers: { selectedUser } = {} }) => ({
    error: addPhoneModal,
    selectedUser,
  });
  const { error, selectedUser } = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const [name, setName] = useState(selectedUser && selectedUser.name ? selectedUser.name : '');
  const [surname, setSurname] = useState(
    selectedUser && selectedUser.surname ? selectedUser.surname : ''
  );
  const [phoneNumber, onPhoneNumberChange] = useState(
    selectedUser && selectedUser.phoneNumber ? selectedUser.phoneNumber : ''
  );
  const [admin, toggleAdmin] = useState(
    selectedUser && selectedUser.admin ? selectedUser.admin : false
  );
  const [edit, toggleEdit] = useState(
    selectedUser && selectedUser.edit ? selectedUser.edit : false
  );
  const [isTruePhoneNumber, checkPhoneNumber] = useState(true);

  return (
    <Modal>
      <h2 className="header">{lang.portal.addPhoneNumberHeader}</h2>
      <div className="content">
        {error === STATUS_CODE_SERVER_ERROR ? (
          <div className="content">
            <ErrorLabel message={lang.error.serverError} />
          </div>
        ) : error === STATUS_CODE_USER_DATA_IS_INVALID || !isTruePhoneNumber ? (
          <ErrorLabel
            message={lang.error.phoneNumberIsInvalid}
            className="ui pointing below red basic label"
          />
        ) : null}
        <InputText label={lang.portal.name} value={name} onChange={setName} />
        <InputText label={lang.portal.surName} value={surname} onChange={setSurname} />

        <PhoneNumberInputWithCountry
          label={lang.portal.phoneNumber}
          value={phoneNumber}
          onChange={onPhoneNumberChange}
        />
        {selectedUser && selectedUser.id ? null : (
          <Fragment>
            <Checkbox
              hasMargin
              label={lang.portal.admin}
              name="admin"
              checked={admin}
              onChange={e => toggleAdmin(e.target.checked)}
            />
            <Checkbox
              hasMargin
              label={lang.portal.editor}
              name="edit"
              checked={edit}
              onChange={e => toggleEdit(e.target.checked)}
            />
          </Fragment>
        )}
      </div>
      <div className="actions">
        <Button
          className="ui basic button"
          onClick={() => {
            dispatch(selectEditUserInPortalCollab(null));
            dispatch(hide(ADD_PHONE_NUMBER_TO_PORTAL_ACCESS_MODAL));
          }}
        >
          {lang.buttons.Cancel}
        </Button>
        <Button
          className="ui basic button"
          onClick={() => {
            const checkedPhoneNumber = isValidPhoneNumber(phoneNumber);

            checkPhoneNumber(checkedPhoneNumber);
            if (checkedPhoneNumber) {
              if (selectedUser && selectedUser.id && selectedUser.portalCollab) {
                dispatch(
                  editPhoneNumberForPortalCollab({
                    id: selectedUser.id,
                    name,
                    surname,
                    phoneNumber,
                    admin,
                    edit,
                    portalCollab: selectedUser.portalCollab,
                    hideModal: true,
                  })
                );
              } else {
                dispatch(addPhoneNumberToPortalCollab({ name, surname, phoneNumber, admin, edit }));
              }
            }
          }}
        >
          {lang.buttons.Save}
        </Button>
      </div>
    </Modal>
  );
};

export default connectModal({ name: ADD_PHONE_NUMBER_TO_PORTAL_ACCESS_MODAL })(AddPhoneNumberModal);
