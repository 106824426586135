import React from 'react';
import lang from '../../translationWrapper';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import NotFoundImage from '../../assets/img/not_found.svg';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import colors from '../../config/styles/colors';
import fonts from '../../config/styles/fonts';

const Page404 = ({ portalCollab }) => {
  const history = useHistory();

  return (
    <Container maxWidth="md">
      <Grid container alignItems="center" direction="column">
        <Image width="620" height="320" src={NotFoundImage} />
        <SecondaryTitle>{lang.notFound.oops}</SecondaryTitle>
        <Title>{lang.notFound.pageNotFoundTitle}</Title>
        <Description>{lang.notFound.pageNotFoundDescription}</Description>
        <GoBackButton
          onClick={() =>
            portalCollab && portalCollab.slug ? history.goBack() : history.push('/portals')
          }
          variant="contained"
        >
          {lang.buttons.goBack}
        </GoBackButton>
      </Grid>
    </Container>
  );
};

const mapStateToProps = ({
  userInformation: { portalCollab } = {},
  sanity: { portalCollabs: { portalCollabsData } = {} } = {},
}) => ({
  portalCollab: portalCollabsData && portalCollabsData[portalCollab],
});

export default connect(mapStateToProps)(Page404);

const Image = styled.img`
  max-width: 620px;
  margin-bottom: 13px;
  ${({ theme }) => `
  ${theme.breakpoints.down('md')} {
    max-width: 320px;
  }
  `}
`;

const GoBackButton = styled(Button)`
  background-color: ${colors.darkMainColor};
  text-transform: inherit;
  color: ${colors.white};
  padding: 13px 58px;
  border-radius: 12px;
  ${fonts.lato(18, 400, 22)};
`;

const SecondaryTitle = styled.h2`
  color: ${colors.darkMainColor};
  ${fonts.lato(62, 900, 22)};
  margin-bottom: 50px;
`;

const Title = styled.h1`
  color: ${colors.text};
  ${fonts.lato(40, 900, 12)};
  margin-bottom: 20px;
`;

const Description = styled.p`
  color: ${colors.grey};
  ${fonts.lato(24, 400, 29)};
  margin-bottom: 60px;
`;
