import {
  ARTICLE_FIELD_MAINIMAGE,
  ARTICLE_FIELD_MAINVIDEO,
} from '../../../config/constants/constants';
import { getLang } from '../../../translationWrapper';
import deserializeBlockContent from './blockContentDeserialize';
import { convertFromCarefeedItemOptions } from './carefeedItems';

const lookForChangeInStrings = (obj, values, setValues) =>
  Object.keys(obj)
    .map(name => {
      if (values[name] !== obj[name]) {
        setValues(prevState => ({
          ...prevState,
          [name]: obj[name],
        }));
        return { name, value: obj[name] };
      } else return null;
    })
    .filter(obj => !!obj);

export default ({ article, values, formValues, blockValues, setValues }) => {
  return {
    ...[
      ...lookForChangeInStrings(formValues, values, setValues),
      ...deserializeBlockContent(blockValues, setValues),
    ].reduce((obj, { name, value }) => {
      let fieldValue = (() => {
        switch (name) {
          case ARTICLE_FIELD_MAINIMAGE:
            return value;
          case ARTICLE_FIELD_MAINVIDEO:
            if (value !== null) {
              return value;
            }
            break;
          default:
            return {
              ...(article && article[name] ? article[name] : {}),
              [getLang()]: value,
            };
        }
      })();
      return {
        ...obj,
        [name]: fieldValue,
      };
    }, {}),
    carefeedItems: convertFromCarefeedItemOptions(values.carefeedItems),
  };
};
