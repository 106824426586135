import {
  CAREFEEDITEM_SHOWINCAREFEED,
  CAREFEEDITEM_OPTIONS_SHOWINCAREFEED,
  CAREFEEDITEM_OPTIONS_SHOWINCAREFEED_NOTINCAREFEED,
  CAREFEEDITEM_TARGET,
  CAREFEEDITEM_OPTIONS_TARGET_NOMODULE,
  CAREFEEDITEM_OPTIONS_SHOWINCAREFEED_INCAREFEED,
  CAREFEEDITEM_TARGET_NOMODULE,
} from '../../../config/constants/constants';
import lang from '../../../translationWrapper';

export const convertCarefeedItemToOptions = carefeedItems => {
  if (!(carefeedItems && carefeedItems.length)) {
    carefeedItems = [{}];
  }
  return carefeedItems.map(({ _id, moduleId, weekFrom, weekTo }) => ({
    _id,
    moduleId,
    weekFrom: weekFrom || weekFrom === 0 ? weekFrom.toString() : '',
    weekTo: weekTo || weekTo === 0 ? weekTo.toString() : '',
    values: {
      [CAREFEEDITEM_TARGET]: moduleId ? moduleId : CAREFEEDITEM_TARGET_NOMODULE,
      [CAREFEEDITEM_SHOWINCAREFEED]:
        weekFrom || weekFrom === 0
          ? CAREFEEDITEM_OPTIONS_SHOWINCAREFEED_INCAREFEED
          : CAREFEEDITEM_OPTIONS_SHOWINCAREFEED_NOTINCAREFEED,
    },
  }));
};

export const compareCarefeedItems = (firstCarefeedItem, secondCarefeedItem) =>
  firstCarefeedItem.length === secondCarefeedItem.length &&
  !firstCarefeedItem.filter(
    ({ _id, values, weekFrom, weekTo }, index) =>
      !(
        secondCarefeedItem &&
        secondCarefeedItem[index] &&
        secondCarefeedItem[index]._id === _id &&
        secondCarefeedItem[index].values[CAREFEEDITEM_TARGET] === values[CAREFEEDITEM_TARGET] &&
        secondCarefeedItem[index].values[CAREFEEDITEM_SHOWINCAREFEED] ===
          values[CAREFEEDITEM_SHOWINCAREFEED] &&
        secondCarefeedItem[index].weekFrom === weekFrom &&
        secondCarefeedItem[index].weekTo === weekTo
      )
  ).length;

export const convertFromCarefeedItemOptions = carefeedItems =>
  carefeedItems &&
  carefeedItems.length &&
  carefeedItems
    .map(({ _id, weekFrom, weekTo, values }) => ({
      _id,
      moduleId:
        values[CAREFEEDITEM_TARGET] !== CAREFEEDITEM_TARGET_NOMODULE
          ? values[CAREFEEDITEM_TARGET]
          : null,
      weekFrom:
        values[CAREFEEDITEM_SHOWINCAREFEED] === CAREFEEDITEM_OPTIONS_SHOWINCAREFEED_INCAREFEED
          ? weekFrom
            ? parseInt(weekFrom)
            : null
          : null,
      weekTo:
        values[CAREFEEDITEM_SHOWINCAREFEED] === CAREFEEDITEM_OPTIONS_SHOWINCAREFEED_INCAREFEED
          ? weekFrom && !!weekTo
            ? parseInt(weekTo)
            : null
          : null,
    }))
    .filter(({ moduleId }) => !!moduleId);

export const getOptionsForShowInCarefeedValues = () =>
  CAREFEEDITEM_OPTIONS_SHOWINCAREFEED.map(({ key, value }) => ({
    key,
    value,
    text: lang.articleEdit[`showInCarefeedValues_${key}`],
  }));

export const getOptionsForTarget = modules =>
  modules
    ? [
        CAREFEEDITEM_OPTIONS_TARGET_NOMODULE,
        ...Object.keys(modules).map(key => ({
          key: key,
          value: key,
          text: modules[key],
        })),
      ]
    : [CAREFEEDITEM_OPTIONS_TARGET_NOMODULE];
