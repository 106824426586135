import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import SanityBlockContent from '@sanity/block-content-to-react';
import lang from '../../translationWrapper';
import serializers from './articleData/blockContentSerializers';
import Editor from '../../components/editor/Editor';

const BlockContent = ({ blocks = [], editing, name, onChange }) => {
  const [text, setText] = useState(null);
  useEffect(() => {
    text === null &&
      setTimeout(() => {
        const element = document.getElementById(name);
        const html = element ? element.innerHTML : '';
        setText(html);
      }, 500);
  }, [blocks, name]);

  const _onChange = val => {
    setText(val);
    onChange && onChange(name, val);
  };
  return (
    <Wrapper isHidden={!blocks || !(blocks.type || blocks.length || editing)}>
      <BlockContentWrapper isHidden={editing}>
        <div id={name}>
          {blocks && blocks[0] && blocks[0]._type ? (
            <SanityBlockContent
              blocks={blocks}
              serializers={serializers}
              imageOptions={{ w: 320, h: 240, fit: 'max' }}
              projectId={process.env.REACT_APP_SANITY_ID}
              dataset={process.env.REACT_APP_SANITY_DATASET}
            />
          ) : (
            blocks
          )}
        </div>
      </BlockContentWrapper>
      {editing && text !== null ? (
        <div>
          <Label>
            {lang.articleEdit[`fieldName_${name}`] ? lang.articleEdit[`fieldName_${name}`] : name}
          </Label>
          <Editor value={text} onChange={_onChange} />
        </div>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  color: #3b3d44;
  letter-spacing: 0px;
  font: normal normal medium 20px/30px Lato;

  #title {
    font: normal normal 900 40px/48px Lato;
  }
  #oneliner {
    font: normal normal bold 24px/36px Lato;
    margin-bottom: 20px;
  }
  #summary {
    margin-bottom: 50px;
  }
  #keyTakeaway {
    background: #f4f6fa 0% 0% no-repeat padding-box;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 50px;
  }

  .RichTextEditor__root___2QXK- {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  ${props =>
    props.isHidden &&
    css`
      display: none;
    `}
`;

const BlockContentWrapper = styled.div`
  ${props =>
    props.isHidden &&
    css`
      display: none;
    `}
`;

const Label = styled.label`
  text-align: left;
  font: normal normal medium 16px/19px Lato;
  letter-spacing: 0;
  color: #3b3d44;
`;

BlockContent.propTypes = {
  name: PropTypes.string,
  blocks: PropTypes.any,
  editing: PropTypes.bool,
  onChange: PropTypes.func,
};

export default BlockContent;
