import React from 'react';
import YouTube from 'react-youtube';
import AudioCard from 'audiocard';
import InternalLink from './InternalLink';
import ExternalLink from './ExternalLink';
import getYouTubeId from 'get-youtube-id';
import { sanityImage } from '../../../services/sanity/sanityClient';

export default {
  marks: {
    internalLink: props => {
      return <InternalLink refId={props.mark.reference && props.mark.reference._ref} content={props.children} />
      
    },
    link: props => {
      return <ExternalLink href={props.mark && props.mark.href} content={props.children} />
      
    },
  },
  types: {
    videoUrl: props => {
      const id = getYouTubeId(props.node.url);
      return <YouTube videoId={id} id={id} opts={{ title: props.node.title }} />;
    },
    podcastUrl: props => (
      <AudioCard
        art={
          props.node.thumbnail &&
          sanityImage(props.node.thumbnail.asset).width(300).height(300).url()
        }
        source={props.node.url}
        skipBackSeconds={10}
        skipForwardSeconds={30}
      />
    ),
    collapsiblePanel: props => <div />,
  },
};
