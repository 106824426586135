import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { capitalize } from '@material-ui/core/utils';
import clsx from 'clsx';

const PendingIndicator = <CircularProgress color="inherit" size={25} />;

const useStyles = makeStyles({
  /* Styles applied to the root element. */
  root: {
    position: 'relative',
  },
  /* Styles applied to the pendingIndicator element. */
  pendingIndicator: {
    display: 'inline-block',
    position: 'absolute',
    visibility: 'visible',
  },
  /* Styles applied to the pendingIndicator element if `pendingPosition="start"`. */
  pendingIndicatorStart: {
    left: 21,
  },
  /* Styles applied to the pendingIndicator element if `pendingPosition="end"`. */
  pendingIndicatorEnd: {
    right: 21,
  },
});

const Button = ({
  onClick,
  children,
  selected,
  type = 'button',
  pending = false,
  pendingIndicator = PendingIndicator,
  pendingPosition = 'start',
  selectedClassName = 'fluid ui blue button',
  disabled = false,
  className = 'fluid ui basic button',
}) => {
  const classes = useStyles();
  return (
    <button
      type={type}
      className={clsx(
        classes.root,
        disabled ? 'ui disabled button' : selected ? selectedClassName : className
      )}
      disabled={disabled || pending}
      onClick={onClick}
    >
      {pending && (
        <div
          className={clsx(
            classes.pendingIndicator,
            classes[`pendingIndicator${capitalize(pendingPosition)}`]
          )}
        >
          {pendingIndicator}
        </div>
      )}
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  selectedClassName: PropTypes.string,
  pending: PropTypes.bool,
  pendingPosition: PropTypes.oneOf(['start', 'end']),
  pendingIndicator: PropTypes.element,
};

export default Button;
