class Events {
  LOGIN = 'login';
  LANDING_PAGE='landing_page';
  LANDING_PAGE_CLICKTOP10 = 'landing_page_clicktop10';
  LANDING_PAGE___CLICK_EDITABLE_ARTICLE = 'landing_page___click_editable_article';
  ARTICLE_EDITOLD = 'article_editold';
  ARTICLE_CREATENEW = 'article_createnew';
  ARTICLE_DELETEOLD = 'article_deleteold';
  ARTICLE_PUBLISHNEW = 'article_publishnew';
  ARTICLE_PUBLISHOLD = 'article_publishold';
  ADMIN = 'admin';
  ADMIN_ADDNUMBER = 'admin_addnumber';
  PREGGUIDE = 'pregguide';
  PREGGUIDE_CLICKWEEK = 'pregguide_clickweek';
  PREGGUIDE_CLICKARTICLE = 'pregguide_clickarticle';
  PARENTGUIDE = 'parentguide';
  PARENTGUIDE_CLICKMONTH = 'parentguide_clickmonth';
  PARENTGUIDE_CLICKARTICLE = 'parentguide_clickarticle'; 
  HANDBOOK = 'handbook'; 
  HANDBOOK_CLICKFOLDER = 'handbook_clickfolder';
  HANDBOOK_CLICKFOLDER_CLICKARTICLE = 'handbook_clickfolder_clickarticle';
  HANDBOOK_SEARCHTERM = 'handbook_searchterm';
  HANDBOOK_SEARCH_CLICKARTICLE = 'handbook_search_clickarticle';
  LOGOUT = 'logout';
}

export default new Events();
