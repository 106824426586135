import { handleActions } from 'redux-actions';
import { setListUsers, setLoadProgress } from '../actions/analyticsAction';

const initialState = {
  json: null,
  loadProgress: {
    municipalities: false,
    listUsers: false,
  },
};

export default handleActions(
  {
    [setListUsers]: (state, { payload }) => ({
      ...state,
      json: payload,
    }),
    [setLoadProgress]: (state, { payload: { municipalities, listUsers } }) => ({
      ...state,
      loadProgress: {
        ...state.loadProgress,
        ...(listUsers ? { listUsers } : {}),
        ...(municipalities ? { municipalities } : {}),
      },
    }),
  },
  initialState
);
