import styled from 'styled-components';
import colors from '../../config/styles/colors';
import fonts from '../../config/styles/fonts';
import { default as MaterialUITable } from '@material-ui/core/Table';

export const StickyTable = styled.div`
  ${({ theme }) => `
  ${theme.breakpoints.up('lg')} {
    position: -webkit-sticky; /* Safari */  
    position: sticky;
    top: 160px;
  }
  `}
`;

export const Table = styled(MaterialUITable)`
  border-radius: 8px;
  color: ${colors.text};
  ${fonts.lato(24, 400, 26)};
`;

export const Row = styled.tr`
  background-color: ${colors.lighterBlue};
  &:last-child > td:last-child {
    border-radius: 0 0 8px 0;
  }
  &:last-child > td:first-child {
    border-radius: 0 0 0 8px;
  }
`;

export const Cell = styled.td`
  padding: 10px 0;
  &:last-child {
    padding: 0 10px 0 0;
  }
  &:nth-child(2) {
    padding: 0 0 0 10px;
  }
  text-align: center;
`;
