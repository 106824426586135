import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ListItem } from '../../components';
import lang from '../../translationWrapper';
import { formHandbookUrl } from './Handbook.helpers';
import { TYPE_ARTICLE } from '../../config/constants/constants';
import colors from '../../config/styles/colors';
import analytics, { Events } from '../../services/analytics/Analytics';

const HandbookSearchResults = ({ searchText, pathname, search }) => {
  let history = useHistory();
  const mapStateToProps = ({
    foldersAndArticles,
    loader,
    sanity: { handbook: { searchArticles } = {} } = {},
  }) => ({
    searchArticles,
    loader,
    article: foldersAndArticles[TYPE_ARTICLE],
  });
  const { searchArticles, loader, article } = useSelector(mapStateToProps);
  
  const onClickHandler = ({ _id, title }) => {
    analytics.logEvent(Events.HANDBOOK_SEARCH_CLICKARTICLE, { title });
    history.push(formHandbookUrl({ pathname, search, article: _id }));
  };

  return searchText && searchArticles && searchArticles.length ? (
    <div>
      <Title>{lang.handbook.searchResults}</Title>
      <Results>
        {searchArticles.map(({ _id, title }) => (
          <li key={_id} title={title}>
            <ListItem onClick={() => onClickHandler({ _id, title })}>
              <ItemTitle selected={_id === article}>{title}</ItemTitle>
            </ListItem>
          </li>
        ))}
      </Results>
    </div>
  ) : searchText && !loader && searchArticles ? (
    <Title>{lang.handbook.noArticles}</Title>
  ) : null;
};

HandbookSearchResults.propTypes = {
  pathname: PropTypes.string,
  search: PropTypes.string,
  searchText: PropTypes.string,
};

const Results = styled.ul`
  list-style-type: none;
  padding-left: 0px;
`;

const Title = styled.h2`
  font-weight: 900;
`;

const ItemTitle = styled.p`
  margin: 0;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props =>
    props.selected &&
    css`
      color: ${colors.blue};
    `}
`;

export default HandbookSearchResults;
