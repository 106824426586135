import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connectModal, hide } from 'redux-modal';
import { Modal } from '../../components';
import { deleteSelectedArticle } from '../../actions/articlesAction';
import { DELETE_ARTICLE_MODAL } from '../../config/constants/constants';
import lang from '../../translationWrapper';
import { Link } from 'react-router-dom';
import analytics, { Events } from '../../services/analytics/Analytics';

const ReallyDeleteModal = () => {
  const dispatch = useDispatch();
  const portalCollabSlug = useSelector(({ userInformation: { portalCollabSlug } = {} }) => portalCollabSlug);
  
  const onClickDeleteHandler = () => {
    analytics.logEvent(Events.ARTICLE_DELETEOLD);
    dispatch(deleteSelectedArticle());
  };

  return (
    <Modal basic>
      <div className="ui icon header">
        <i className="trash icon"></i>
        {lang.articleEdit.reallyDeleteArticle}
      </div>
      <div className="actions">
        <button
          className="ui red basic cancel inverted button"
          onClick={() => {
            dispatch(hide(DELETE_ARTICLE_MODAL));
          }}
        >
          <i className="remove icon"></i>
          {lang.buttons.no.toUpperCase()}
        </button>
        <Link
          className="ui green ok inverted button"
          to={`/${portalCollabSlug}/home`}
          onClick={onClickDeleteHandler}
        >
          <i className="trash icon"></i>
          {lang.buttons.yes.toUpperCase()}
        </Link>
      </div>
    </Modal>
  );
};

export default connectModal({ name: DELETE_ARTICLE_MODAL })(ReallyDeleteModal);
