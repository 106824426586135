import React from 'react';
import PropTypes from 'prop-types';

const ExternalLink = ({ href, content }) => {
  return (
    <a rel="noopener noreferrer" target="_blank" href={href}>
      {content}
    </a>
  );
};

ExternalLink.propTypes = {
  content: PropTypes.arrayOf(PropTypes.string),
  href: PropTypes.string,
};

export default ExternalLink;
