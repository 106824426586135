import React from 'react';
import { useParams, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const InternalLink = ({ refId, content }) => {
  let { portalSlug } = useParams();
  return <Link to={`/${portalSlug}/article/${refId}`}>{content}</Link>;
};

InternalLink.propTypes = {
  refId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  content: PropTypes.arrayOf(PropTypes.string),
};

export default InternalLink;
