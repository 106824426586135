import { handleActions } from "redux-actions";
import { logInUser, setUserData } from "../actions/userAction";

const initialState = {
  inited: false,
  phoneNumber: null,
  token: null,
  uid: null,
};

export default handleActions(
  {
    [logInUser]: (state, { payload: { phoneNumber, token, uid, inited } }) => ({
      ...state,
      phoneNumber,
      token,
      uid,
      inited,
    }),
    [setUserData]: state => ({
      ...state,
      inited: true,
    }),
  },
  initialState
);
