import styled from 'styled-components';
import colors from '../../config/styles/colors';

export const CurrentWeekCounterContainer = styled.div`
  background: ${colors.backgroundSurface} 0% 0% no-repeat padding-box;
  border-radius: 12px;
  display: flex;
  flex-direction: columns;
  max-width: 380px;
  padding: 20px;
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 50px;
`;

export const MainText = styled.div`
  color: ${colors.text};
  font-weight: 900;
  text-align: center;
  font: normal normal 900 50px Lato;
  letter-spacing: 0px;
  color: rgba(59, 61, 68, 1);
`;

export const InfoContainer = styled.div`
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  justify-content: center;
`;

export const InfoTextHeader = styled.div`
    color: ${colors.text};
    font: normal normal medium 16px/24px Lato;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    opacity: 1;
`;

export const InfoSubHeaderContainer = styled.div`
  color: ${colors.text};
  text-align: left;
  display: flex;
  flex-direction: row;
  font: normal normal medium 12px/24px Lato;
  letter-spacing: 0px;
`;

export const UpTag = styled.div`
  width: 0;
  height: 0;
  margin-top: 8px;
  margin-right: 8px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #22c08d;
`;

export const DownTag = styled.div`
    width: 0;
    height: 0;
    margin-top: 8px;
    margin-right: 8px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid red;
`;
