import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import colors from '../../config/styles/colors';

const ListItem = ({ children, onClick, hideIcon = false, hideBorder = false }) =>
  <Button onClick={onClick} hideBorder={hideBorder}>
    {children}
    {
      hideIcon ?
        null :
        <i className="angle right icon" />
    }
  </Button>

ListItem.propTypes = {
  children: PropTypes.node,
  hideBorder: PropTypes.bool,
  hideIcon: PropTypes.bool,
  onClick: PropTypes.func,
};

const Button = styled.button`
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-width: 0px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.lightGrey};
  padding-bottom: 12px;
  margin-top: 15px;
  text-align: start;
  cursor: pointer;

  ${props => props.hideBorder && css`
    border-bottom-width: 0px;
  `}
`;

export default ListItem;
