import React from 'react';
import { useSelector } from 'react-redux';
import TableHeaders from './TableHeaders';
import UserInPortalCollab from './UserInPortalCollab';
import lang from '../../translationWrapper';

const UsersWithAccessToPortalCollab = () => {
  const mapStateToProps = ({
    sanity: { portalCollabs: { selectedPortalCollab } = {} } = {},
    adminPanelPhoneNumbers,
  }) => {
    const phoneNumbersFromPortalCollab = adminPanelPhoneNumbers[selectedPortalCollab];

    if (phoneNumbersFromPortalCollab && !phoneNumbersFromPortalCollab.errStatus) {
      const { phoneNumbersId, phoneNumbers } = adminPanelPhoneNumbers[selectedPortalCollab];

      return {
        phoneNumbers:
          selectedPortalCollab && adminPanelPhoneNumbers[selectedPortalCollab]
            ? phoneNumbersId.map(key => phoneNumbers[key])
            : [],
      };
    } else if (phoneNumbersFromPortalCollab && phoneNumbersFromPortalCollab.errStatus) {
      return { errStatus: phoneNumbersFromPortalCollab.errStatus };
    } else {
      return { phoneNumbers: [] };
    }
  };
  const { phoneNumbers, errStatus } = useSelector(mapStateToProps);

  return (
    <div className={`ui ${errStatus ? 'center aligned text' : ''} twelve wide column grid`}>
      <TableHeaders />
      {errStatus ? (
        <h1>{lang.error.noAccesToPhoneNumbersInPortal}</h1>
      ) : (
        phoneNumbers.map(({ name, surname, phoneNumber, id, admin, edit, portalCollab }) => (
          <UserInPortalCollab
            key={id}
            id={id}
            portalCollab={portalCollab}
            admin={admin}
            edit={edit}
            phoneNumber={phoneNumber}
            name={name}
            surname={surname}
          />
        ))
      )}
    </div>
  );
};

export default UsersWithAccessToPortalCollab;
