import React from 'react';
import PropTypes from 'prop-types';

const Search = ({
  placeholder,
  value,
  onChange,
}) =>
  <div className="ui fluid left icon input">
    <i className="search icon"></i>
    <input
      type="text"
      value={value}
      onChange={event => onChange(event.target.value)}
      placeholder={placeholder} />
  </div>

Search.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default Search;
