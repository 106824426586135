import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from 'semantic-ui-react';
import lang from '../../translationWrapper';
import ToggleSelect from '../../components/select/ToggleSelect';
import styled from 'styled-components';
import { getModules } from '../../actions/articlesAction';
import {
  CAREFEEDITEM_OPTIONS_SHOWINCAREFEED_NOTINCAREFEED,
  CAREFEEDITEM_SHOWINCAREFEED,
  CAREFEEDITEM_TARGET,
  CAREFEEDITEM_TARGET_NOMODULE,
} from '../../config/constants/constants';
import CarefeedItemWeeksButton from './CarefeedItemWeeksButton';
import colors from '../../config/styles/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOptionsForShowInCarefeedValues,
  getOptionsForTarget,
} from './articleData/carefeedItems';

const CarefeedItemContainer = styled.div`
  margin-bottom: 20px;
  .ui.grid {
    margin: 0;
  }
`;
const Row = styled(Grid.Row)`
  margin-bottom: 0;
  padding-bottom: 0 !important;
`;

const Trash = styled(Button)`
  background-color: ${colors.warning} !important;
  color: #fff !important;
  margin-top: 20px !important;
  margin-left: 15px !important;
`;

const ArticleCarefeedItem = ({ items, onChange, editing }) => {
  const modules = useSelector(
    ({
      sanity: {
        articles: { modules },
      },
    }) => modules
  );
  const dispatch = useDispatch();

  useEffect(() => {
    !modules && dispatch(getModules());
  }, [modules, dispatch]);

  const addCarefeedItems = event => {
    event.preventDefault();
    onChange({ add: true });
  };
  const removeCarefeedItems = (event, index) => {
    event.preventDefault();
    onChange({ remove: true, index });
  };
  return (
    <CarefeedItemContainer>
      <Grid>
        {items.map(({ _id, values, weekFrom, weekTo }, index) => (
          <Row key={_id || `carefeeditem${index}`}>
            <ToggleSelect
              name={CAREFEEDITEM_TARGET}
              editing={editing}
              label={lang.articleEdit.target}
              options={getOptionsForTarget(modules)}
              defaultValue={values[CAREFEEDITEM_TARGET]}
              onChange={(event, { name, value }) => onChange({ patch: { name, value }, index })}
            />
            {values[CAREFEEDITEM_TARGET] !== CAREFEEDITEM_TARGET_NOMODULE && (
              <Fragment>
                {editing && (
                  <ToggleSelect
                    name={CAREFEEDITEM_SHOWINCAREFEED}
                    editing={editing}
                    label={lang.articleEdit.showInCarefeed}
                    options={getOptionsForShowInCarefeedValues()}
                    defaultValue={values[CAREFEEDITEM_SHOWINCAREFEED]}
                    onChange={(event, { name, value }) =>
                      onChange({ patch: { name, value }, index })
                    }
                  />
                )}
                {values[CAREFEEDITEM_SHOWINCAREFEED] !==
                  CAREFEEDITEM_OPTIONS_SHOWINCAREFEED_NOTINCAREFEED && (
                  <CarefeedItemWeeksButton
                    editing={editing}
                    values={values}
                    weekFrom={weekFrom}
                    weekTo={weekTo}
                    onSave={({ weekFrom, weekTo }) => {
                      onChange({ patch: { name: 'weekFrom', value: weekFrom }, index });
                      onChange({ patch: { name: 'weekTo', value: weekTo }, index });
                    }}
                  />
                )}
              </Fragment>
            )}
            {editing && <Trash icon="trash" onClick={e => removeCarefeedItems(e, index)} />}
          </Row>
        ))}
        {editing ? (
          <Row>
            <Button
              content={lang.articleEdit.addCarefeedItem}
              icon="add"
              labelPosition="left"
              onClick={e => addCarefeedItems(e)}
            />
          </Row>
        ) : null}
      </Grid>
    </CarefeedItemContainer>
  );
};

ArticleCarefeedItem.propTypes = {
  items: PropTypes.array,
  editing: PropTypes.bool,
  carefeedItems: PropTypes.array,
};

export default ArticleCarefeedItem;
