import React from 'react';
import PropTypes from 'prop-types';

const ColumnsWrapper = ({ children, className = "ui two column grid" }) => (
  <div className={className}>{children}</div>
);

ColumnsWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ColumnsWrapper;
