import { handleActions } from 'redux-actions';
import { getItemContentFromValue } from '../pages/article/articleData/valueConvertions';
import {
  setArticles,
  setClusterPills,
  setArticleContent,
  setModules,
  updateArticleContent,
  publishArticleContent,
  deSelectArticle,
  createNewArticleContent,
  deleteArticle,
  resetClusterPills,
  setArticleId,
  fetchingArticleData,
  successFetchingArticleData,
  failureFetchingArticleData
} from '../actions/articlesAction';

const initialState = {
  isFetching: false,
  articlesIds: [],
  modules: null,
  articles: {},
  content: {},
  clusterPills: {},
  editableArticle: false,
  selectedArticleId: null,
};

export default handleActions(
  {
    [fetchingArticleData]: (state) => ({
      ...state,
      isFetching: true
    }),
    [successFetchingArticleData]: (state) => ({
      ...state,
      isFetching: false
    }),
    [failureFetchingArticleData]: (state) => ({
      ...state,
      isFetching: false
    }),
    [resetClusterPills]: (state) => ({
      ...state,
      clusterPills: {}
    }),
    [setClusterPills]: (state, { payload }) => ({
      ...state,
      clusterPills: {
        ...state.clusterPills,
        [payload.id]: payload
      },
    }),
    [setArticles]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [deSelectArticle]: state => ({
      ...state,
      selectedArticleId: null,
      editableArticle: null,
    }),
    [setArticleId]: (state, { payload }) => ({
      ...state,
      selectedArticleId: payload,
      editableArticle: state.content[payload].editable,
    }),
    [setArticleContent]: (state, { payload }) => ({
      ...state,
      content: { ...state.content, [payload._id]: payload },
      selectedArticleId: payload._id,
      editableArticle: payload.editable,
    }),
    [setModules]: (state, { payload }) => ({
      ...state,
      modules: payload,
    }),
    [createNewArticleContent]: (state, { payload: { articleId, content } }) => ({
      ...state,
      selectedArticleId: articleId,
      editableArticle: true,
      articlesIds: [...state.articlesIds, articleId],
      content: {
        ...state.content,
        [articleId]: {
          ...state.content[articleId],
          ...content,
        },
      },
      articles: {
        ...state.articles,
        [articleId]: {
          ...state.articles[articleId],
          ...getItemContentFromValue(content),
        },
      },
    }),
    [publishArticleContent]: (state, { payload: { articleId, content } }) => ({
      ...state,
      content: {
        ...state.content,
        [articleId]: {
          ...state.content[articleId],
          ...content,
        },
      },
      articles: {
        ...state.articles,
        [articleId]: {
          ...state.articles[articleId],
          ...getItemContentFromValue(content),
        },
      },
    }),
    [updateArticleContent]: (state, { payload: { articleId, content } }) => ({
      ...state,
      content: {
        ...state.content,
        [articleId]: {
          ...content,
        },
      },
      articles: {
        ...state.articles,
        [articleId]: {
          ...getItemContentFromValue(content),
        },
      },
    }),
    [deleteArticle]: (state, { payload }) => {
      const nextContent = { ...state.content };
      delete nextContent[payload];
      const nextArticlesIds = [...state.articlesIds];
      nextArticlesIds.splice(nextArticlesIds.indexOf(payload), 1);
      const nextArticles = { ...state.articles };
      delete nextArticles[payload];
      return {
        ...state,
        content: nextContent,
        articles: nextArticles,
        articlesIds: nextArticlesIds,
      };
    },
  },
  initialState
);
