import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import rest from '../../services/helseoversikt/rest';
import { useParams } from 'react-router';
import ReactJsonPrint from 'react-json-print';

const DebugUserPage = () => {
  const { tlf } = useParams();
  const [result, setResult] = useState({ status: 'waiting' });

  useEffect(() => {
    rest
      .debugUser({ urlKeys: { phoneNr: tlf } })
      .then(res => {
        setResult(res);
      })
      .catch(err => {
        setResult({ error: err });
      });
  }, []);

  return (
    <Wrapper>
      <div>
        <h3>{tlf}:</h3>
        {result?.status === 'waiting' ? (
          <h3>...loading</h3>
        ) : (
          <ReactJsonPrint dataObject={result} />
        )}
      </div>
    </Wrapper>
  );
};

export default DebugUserPage;

const Wrapper = styled.div`
  padding: 20px;
`;
