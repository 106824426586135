import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Search } from '../../components';
import { saveSearchHandbookArticles } from '../../actions/handbookActions';
import { formHandbookUrl } from './Handbook.helpers';
import { TYPE_ARTICLE } from '../../config/constants/constants';
import colors from '../../config/styles/colors';
import lang from '../../translationWrapper';

const HandbookSearch = ({ searchText, onTextChange, pathname }) => {
  const dispatch = useDispatch();
  const mapStateToProps = ({ foldersAndArticles }) => foldersAndArticles[TYPE_ARTICLE];
  const article = useSelector(mapStateToProps);
  let history = useHistory(pathname);
  const debouncedFunc = useCallback(
    debounce(value => {
      !value && dispatch(saveSearchHandbookArticles(null));
      history.push(formHandbookUrl({ pathname, article, search: value }));
    }, 500),
    [article]);

  return <SearchWrapper isSearchNotEmpty={!!searchText}>
    <Search
      value={searchText}
      onChange={value => {
        onTextChange(value);
        debouncedFunc(value);
      }}
      placeholder={lang.handbook.search} />
  </SearchWrapper>
}

HandbookSearch.propTypes = {
  onTextChange: PropTypes.func,
  pathname: PropTypes.string,
  searchText: PropTypes.string,
};

const SearchWrapper = styled.div`
  &&> div {
    > input {
      border-radius: 0px;
      border-top-left-radius: 8px ;
      border-top-right-radius: 8px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    ${props => props.isSearchNotEmpty && css`
      > input:focus {
        background-color: ${colors.backgroundSurface};
      }
    `}
  }
`;

export default HandbookSearch;
