import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import HandbookFolder from './HandbookFolder';
import colors from '../../config/styles/colors';

const HandbookFolders = ({ id, url = '', pathname, search }) => {
  const mapStateToProps = ({
    sanity: { handbook: { levelsOfFolders, folderAndArticles } = {} } = {},
  }) => {
    const level = levelsOfFolders[id];
    return (
      level &&
      level.map(id => folderAndArticles[id]).filter(({ image, title }) => !!image && !!title)
    );
  };
  const level = useSelector(mapStateToProps);
  return level ? (
    <Wrapper id={id}>
      {level.map(({ title, _id, _type }) => (
        <HandbookFolder
          key={_id}
          id={_id}
          search={search}
          title={title}
          isFirst={!id}
          pathname={pathname}
          url={url}
          type={_type}
        />
      ))}
    </Wrapper>
  ) : null;
};

HandbookFolders.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pathname: PropTypes.string,
  search: PropTypes.string,
  url: PropTypes.string,
};

const Wrapper = styled.ul`
  padding-left: 10px;
  list-style-type: none;
  ${props =>
    props.id &&
    css`
      margin-left: 20px;
      border-left: 1px solid ${colors.lightGrey};
    `}
`;

export default HandbookFolders;
