import { createAction } from 'redux-actions';
import rest from '../services/helseoversikt/rest';

export const setUserCount = createAction('STATS/USER_COUNT');
export const setCountByWeeks = createAction('STATS/SET_COUNT_BY_WEEKS');
export const setMostReadArticles = createAction('STATS/SET_MOST_READ_ARTICLES');
export const setStats = createAction('STATS/SET');

export const getStatistics = () => async (dispatch, getState) => {
  const {
    userInformation: { portalCollab },
  } = getState();
  rest
    .getStats({
      urlKeys: {
        portalCollab,
      },
    })
    .then(({ result: { userCount, countByWeek, mostRead } = {} }) => {
      dispatch(setStats({ userCount, countByWeek, mostRead }));
    })
    .catch(e => console.error(e));
};
