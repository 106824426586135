import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import { Cell } from './Carefeed.styles';
import colors from '../../config/styles/colors';
import fonts from '../../config/styles/fonts';


const CalendarCell = ({ item, selected, onClick }) => {
  return (
    <Cell>
      {item || typeof item === 'number' ? (
        <CalendarCellButton
          selected={selected}
          onClick={onClick}
        >
            {item}
        </CalendarCellButton>
      ) : null}
    </Cell>
  );
};

CalendarCell.propTypes = {
  item: PropTypes.number,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

const CalendarCellButton = styled(Button)`
  width: 48px;
  height: 48px;
  min-width: 48px;
  border-radius: 50%;
  padding: 0;
  color: ${colors.text};
  ${fonts.lato(24, 400, 26)};
  ${props =>
    props.selected &&
    css`
      color: ${colors.white};
      background-color: ${colors.darkMainColor};
      &:hover {
        background-color: ${colors.darkMainColor};
      }
    `}
`;

export default CalendarCell;
