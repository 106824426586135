import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import fonts from '../../config/styles/fonts';
import { sanityImage } from '../../services/sanity/sanityClient';
import lang from '../../translationWrapper';

const ArticleSourceItem = ({ name, hasComma, sourceUrl, image }) => {
  const View = sourceUrl ? StyledLink : PlaceholderLink;
  return (
    <View target="_blank" rel="noopener noreferrer" href={sourceUrl}>
      {image && (
        <PortalImage
          alt={lang.alt.linkImage}
          src={sanityImage(image.asset).size(100, 100).crop('center').format('png').url()}
        />
      )}
      <p>
        {name ? name : sourceUrl}
        {hasComma ? ', ' : ''}
      </p>
    </View>
  );
};

ArticleSourceItem.propTypes = {
  hasComma: PropTypes.bool,
  name: PropTypes.string,
  sourceUrl: PropTypes.string,
};

const commonStyles = css`
  ${fonts.lato(14, 400, 20)};
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  align-items: center;
`;

const StyledLink = styled.a`
  ${commonStyles}
`;

const PlaceholderLink = styled.div`
  ${commonStyles}
`;

const PortalImage = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 8px;
  object-fit: contain;
`;

export default ArticleSourceItem;
