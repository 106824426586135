import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'semantic-ui-react';
import { sanityImage } from '../../services/sanity/sanityClient';
import PropTypes from 'prop-types';
import colors from '../../config/styles/colors';
import lang from '../../translationWrapper';
import { BLOCKTYPE_NEW_ASSET } from '../../config/constants/constants';
import { Fragment } from 'react';

const MainImage = ({ hasMainVideo, mainImage, editing, onChange, name }) => {
  const [image, setImage] = useState();
  const openFile = event => {
    const input = event.target;
    let reader = new FileReader();
    reader.onload = function () {
      const dataURL = reader.result;
      onChange(name, {
        _type: BLOCKTYPE_NEW_ASSET,
        src: dataURL,
      });
      setImage(dataURL);
    };
    reader.readAsDataURL(input.files[0]);
  };

  const removeImage = event => {
    event.preventDefault();
    setImage(null);
    onChange(name, null);
  };

  const hasImage = typeof image === 'undefined' ? !!(mainImage && mainImage.asset) : !!image;
  return (
    <div>
      {/* Show main image if there is no main video or in editor mode */}
      {(!hasMainVideo && (image || (mainImage && mainImage.asset))) || editing ? (
        <ImageContainer>
          {hasImage ? (
            <Image
              alt={lang.alt.article}
              src={image ? image : sanityImage(mainImage.asset).url()}
            />
          ) : null}
          {editing ? (
            <Fragment>
              {hasImage ? <Trash icon="trash" onClick={removeImage} /> : null}
              <EditContainer rightCorner={hasImage}>
                <Edit
                  icon="camera"
                  content={hasImage ? lang.articleEdit.editPhoto : lang.articleEdit.addPhoto}
                  onClick={event => {
                    event.preventDefault();
                    document.getElementById('mainimage_input').click();
                  }}
                />
              </EditContainer>
              <Input id="mainimage_input" type="file" accept="image/*" onChange={openFile} />
            </Fragment>
          ) : null}
        </ImageContainer>
      ) : null}
    </div>
  );
};

const EditContainer = styled.div`
  ${props =>
    props.rightCorner &&
    css`
      position: absolute;
      bottom: 20px;
      right: 20px;
    `}
`;

const Edit = styled(Button)`
  background-color: rgb(19, 88, 140) !important;
  color: #fff !important;
`;
const Trash = styled(Button)`
  background-color: ${colors.warning} !important;
  color: #fff !important;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const Input = styled.input`
  display: none;
`;
const Image = styled.img`
  height: inherit;
`;
const ImageContainer = styled.div`
  height: 384px;
  width: 670px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 2px;
  overflow: hidden;
  background-size: 16px 16px;
  background-position: 0 0, 0 8px, 8px -8px, -8px 0;
  background-color: rgba(228, 232, 237, 0.5);
  background-image: linear-gradient(45deg, rgba(123, 140, 168, 0.1) 25%, transparent 0),
    linear-gradient(-45deg, rgba(123, 140, 168, 0.1) 25%, transparent 0),
    linear-gradient(45deg, transparent 75%, rgba(123, 140, 168, 0.1) 0),
    linear-gradient(-45deg, transparent 75%, rgba(123, 140, 168, 0.1) 0);
`;

MainImage.propTypes = {
  editing: PropTypes.bool,
  name: PropTypes.string,
  blocks: PropTypes.any,
  onChange: PropTypes.func,
  mainImage: PropTypes.shape({
    _type: PropTypes.oneOf(['image']),
    asset: PropTypes.shape({
      _ref: PropTypes.string,
      type: PropTypes.oneOf(['reference']),
    }),
  }),
};

export default MainImage;
