import imageUrlBuilder from '@sanity/image-url';
import sanityClient from '@sanity/client';

const SanityClient = sanityClient({
  projectId: process.env.REACT_APP_SANITY_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  token: '',
  useCdn: process.env.REACT_APP_SANITY_CDN === 'true',
});

const builder = imageUrlBuilder(SanityClient);

export const sanityImage = source => {
  return builder.image(source);
};
