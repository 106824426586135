import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledNumberThumbnails = styled.div`
  text-align: center;
  font: normal normal bold 28px Lato;
  line-height: 46px;
  letter-spacing: 0px;
  color: #93bae1;
  height: 54px;
  width: 80px;
`;

const NumberThumbnails = ({ number }) => {
  return (
    <StyledNumberThumbnails className="image">
      {number}
    </StyledNumberThumbnails>
  );
};

NumberThumbnails.propTypes = {
  number: PropTypes.number,
};

export default NumberThumbnails;
