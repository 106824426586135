import React from 'react';
import PropTypes from 'prop-types';
import { useTextValidation } from '../../hooks';
import { Label } from 'semantic-ui-react';
import Description from '../label/Label';
import Input from './Input';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 10px;
`;

const InputText = ({ label, value = '', refferance, validate, onChange, rules }) => {
  const validation = useTextValidation(validate, rules, value);
  return (
    <Container className="row">
      <Description>{label}</Description>
      <Input
        ref={refferance}
        type="text"
        name={label}
        value={value}
        onChange={e => onChange(e.target.value)}
        error={!!validate}
      />
      {validation ? (
        <Label basic color="red" pointing>
          {validation}
        </Label>
      ) : null}
    </Container>
  );
};

InputText.propTypes = {
  onChange: PropTypes.func,
  refferance: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  validate: PropTypes.object,
  rules: PropTypes.object,
};

export default InputText;
