import { createAction } from 'redux-actions';
import rest from '../services/helseoversikt/rest';
import { parseReferenceCarefeedItem } from '../helpers/articles.helpers';
import { getItemContentFromValue } from '../pages/article/articleData/valueConvertions';
import { hide } from 'redux-modal';
import { DELETE_ARTICLE_MODAL } from '../config/constants/constants';
import { getLang } from '../translationWrapper';

export const setArticles = createAction('ARTICLES/SET', articles => ({
  articlesIds: articles.map(({ _id }) => _id),
  articles: articles.reduce((obj, article) => ({ ...obj, [article._id]: article }), {}),
}));
export const updateArticleContent = createAction('ARTICLES/UPDATE_CONTENT');
export const publishArticleContent = createAction('ARTICLES/PUBSLISH_CONTENT');

export const createNewArticleContent = createAction('ARTICLES/CREATE_NEW_ARTICLE');

export const fetchingArticleData = createAction('ARTICLES/FETCHING_ARTICLE_DATA');
export const successFetchingArticleData = createAction('ARTICLES/SUCCESS_FETCHING_ARTICLE_DATA');
export const failureFetchingArticleData = createAction('ARTICLES/FAILURE_FETCHING_ARTICLE_DATA');

export const setArticleId = createAction('ARTICLES/SET_ARTICLE_ID');
export const setClusterPills = createAction('ARTICLES/SET_CLUSTER_PILLS');
export const setArticleContent = createAction('ARTICLES/SET_CONTENT');
export const deSelectArticle = createAction('ARTICLES/UNSELECT_ARTICLE');
export const deleteArticle = createAction('ARTICLES/DELETE_ARTICLE');
export const resetClusterPills = createAction('ARTICLES/RESET_ARTICLE_PILLS');
export const setModules = createAction('ARTICLES/SET_MODULES', modules =>
  modules.reduce((obj, module) => ({ ...obj, [module._id]: module.title }), {})
);

export const getArticleList = () => (dispatch, getState) => {
  const {
    userInformation: { portalCollab },
  } = getState();
  try {
    rest
      .getArticlesInMunicipality({
        urlKeys: {
          portalCollab,
        },
      })
      .then(({ items }) => {
        dispatch(setArticles(parseReferenceCarefeedItem(items)));
      });
  } catch (err) {}
};

export const getArticleContent = articleId => (dispatch, getState) => {
  const { sanity: { articles: { content } = {} } = {}, userInformation: { portalCollab } = {} } =
    getState();
  try {
    content[articleId]
      ? dispatch(setArticleId(articleId))
      : rest
          .getArticle({
            urlKeys: {
              portalCollab,
              articleId,
            },
          })
          .then(({ article, carefeedItems }) => {
            if (article.refSet && article.refSet.length) {
              article.refSet.forEach(({ article, pill }) => {
                dispatch(setArticleContent({ ...article }));
                dispatch(setClusterPills({ id: article?._id, title: pill?.title }));
              });
              dispatch(setArticleId(article.refSet[0].article._id));
            } else {
              dispatch(setArticleContent({ ...article, carefeedItems }));
            }
          });
  } catch (err) {}
};

export const getModules = () => (dispatch, getState) => {
  const {
    sanity: { articles: { modules } = {} },
  } = getState();
  try {
    !modules && rest.getModules().then(modules => dispatch(setModules(modules)));
  } catch (err) {}
};

export const updateArticle = (articleId, fields, setEditing) => async (dispatch, getState) => {
  const {
    userInformation: { portalCollab },
  } = getState();
  try {
    dispatch(fetchingArticleData());
    rest
      .updateArticle({ ...fields, urlKeys: { articleId, portalCollab } })
      .then(({ article }) =>
        dispatch(
          updateArticleContent({
            articleId,
            content: { draft: true, ...article },
          })
        )
      )
      .then(() => {
        dispatch(successFetchingArticleData());
        setEditing(false);
      });
  } catch (err) {
    dispatch(failureFetchingArticleData());
    setEditing(false);
  }
};

export const publishArticle = articleId => async (dispatch, getState) => {
  const {
    userInformation: { portalCollab },
  } = getState();
  try {
    rest
      .publishArticle({ urlKeys: { articleId, portalCollab } })
      .then(id => dispatch(publishArticleContent({ articleId, content: { draft: false } })));
  } catch (err) {}
};

export const addNewArticle = (doc, setEditing) => async (dispatch, getState) => {
  const {
    userInformation: { portalCollab },
  } = getState();
  try {
    dispatch(fetchingArticleData());
    rest
      .addNewArticle({
        ...doc,
        urlKeys: { portalCollab },
        languages: { [getLang()]: true, _type: 'localeBoolean' },
      })
      .then(({ article }) => {
        dispatch(
          createNewArticleContent({
            articleId: article._id,
            content: article,
          })
        );
        dispatch(successFetchingArticleData());
        setEditing(false);
      });
  } catch (err) {
    dispatch(failureFetchingArticleData());
    setEditing(false);
  }
};

export const deleteSelectedArticle = () => async (dispatch, getState) => {
  const {
    sanity: {
      articles: { selectedArticleId },
    },
    userInformation: { portalCollab },
  } = getState();
  try {
    rest.deleteArticle({ urlKeys: { portalCollab, articleId: selectedArticleId } }).then(() => {
      dispatch(deleteArticle(selectedArticleId));
      dispatch(hide(DELETE_ARTICLE_MODAL));
    });
  } catch (err) {}
};
