import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import fonts from '../../config/styles/fonts';
import colors from '../../config/styles/colors';

const ClusterPills = ({ clusterPills, selectedArticleId, setArticleId }) => {
  return (
    <TillsContainer>
      {Object.values(clusterPills).map(({ title, id }) => {
        return (
          <TillButton
            key={id}
            selected={selectedArticleId === id}
            onClick={() => {
              if (selectedArticleId !== id) {
                setArticleId(id);
              }
            }}
          >
            {title}
          </TillButton>
        );
      })}
    </TillsContainer>
  );
};

ClusterPills.propTypes = {
  clusterPills: PropTypes.object,
  selectedArticleId: PropTypes.string,
  setArticleId: PropTypes.func,
};

export default ClusterPills;

const TillButton = styled(Button)`
  background-color: #f4f6fa;
  margin-right: 24px;
  text-transform: inherit;
  color: ${colors.text};
  padding: 11px 24px;
  border-radius: 12px;
  ${fonts.lato(18, 400, 22)};
  &:hover {
    color: ${colors.hoverText};
    background-color: #f4f6fa;
  }

  ${props =>
    props.selected &&
    css`
      color: ${colors.white};
      background-color: ${colors.darkMainColor};
      &:hover {
        color: ${colors.white};
        background-color: ${colors.darkMainColor};
      }ƒ
    `}
`;

const TillsContainer = styled.div`
  padding-bottom: 25px;
`;
