import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../config/styles/colors';

const H2Container = styled.div`
  display: flex;
  flex-direction: columns;
  margin-bottom: 20px;
  margin-left: 21px;
`;

const H2Text = styled.h1`
  text-align: left;
  font: normal normal 900 24px/29px Lato;
  line-height: 34px;
  letter-spacing: 0px;
  color: ${colors.text};
  margin: 0;
`;


const H2 = ({ title }) => {
  return (
    <H2Container>
      <H2Text>{title}</H2Text>
    </H2Container>
  );
};

H2.propTypes = {
  title: PropTypes.string,
};
export default H2;
