import { createAction } from 'redux-actions';
import rest from '../services/helseoversikt/rest';
import axios from 'axios';

export const setActivePortalCollab = createAction('USER/SET_PORTAL');
export const setActivePortalCollabSlug = createAction('USER/SET_PORTAL_SLUG');
export const setActivePortalCollabName = createAction('USER/SET_PORTAL_NAME');
export const setActivePortalCollabEndorsement = createAction('USER/SET_PORTAL_ENDORSEMENT');

export const logInUser = createAction('USER/LOGIN_USER', payload => ({
  phoneNumber: payload && payload.phoneNumber,
  token: payload && payload.token,
  uid: payload && payload.uid,
  inited: !(payload && payload.uid),
}));
export const setUserData = createAction('USER/SET_DATA', ({ portalAccess, ...data }) => ({
  ...data,
  portalAccess: {
    super_admin: !!portalAccess.filter(({ super_admin }) => super_admin).length,
    admin: !!portalAccess.filter(({ admin }) => admin).length,
    edit: !!portalAccess.filter(({ edit }) => edit).length,
    status: portalAccess.reduce((obj, connection) => {
      if (connection.edit) {
        obj.editId = obj.editId
          ? [...obj.editId, connection.portalCollab]
          : [connection.portalCollab];
      }
      if (connection.admin) {
        obj.adminId = obj.adminId
          ? [...obj.adminId, connection.portalCollab]
          : [connection.portalCollab];
      }
      if (!(connection.admin && connection.edit)) {
        obj.readId = obj.readId
          ? [...obj.readId, connection.portalCollab]
          : [connection.portalCollab];
      }
      return obj;
    }, {}),
    portalConnections: portalAccess.reduce((obj, connection) => {
      obj[connection.id] = connection;
      return obj;
    }, {}),
  },
  portalIds: portalAccess.map(connection => connection._id),
  portals: portalAccess.reduce((obj, { _id, name, description, endorsement, logo, slug }) => {
    obj[_id] = { _id, name, description, endorsement, logo, slug };
    return obj;
  }, {}),
}));

export const getUserData = () => dispatch =>
  rest
    .getUserData()
    .then(data => dispatch(setUserData(data)))
    .catch(() => console.warn('failed to get user data'));

export const authStateChange = user => async dispatch => {
  if (user) {
    const idTokenResult = await user.getIdTokenResult();
    const token = idTokenResult.token;
    if (token) {
      dispatch(
        logInUser({
          phoneNumber: user.phoneNumber,
          token: idTokenResult.token,
          uid: user.uid,
        })
      );
      axios.defaults.headers.common['authtoken'] = token;
      axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      dispatch(getUserData(user.uid));
    }
  } else {
    dispatch(logInUser(null));
  }
};
