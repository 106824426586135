import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '../../components';
import { selectPortalCollab } from '../../actions/adminActions';

const PortalCollabsPanel = () => {
  const mapStateToProps = ({
    sanity: {
      portalCollabs: { portalCollabsIds, portalCollabsData, selectedPortalCollab } = {},
    } = {},
  }) => ({
    selectedPortalCollab,
    portalCollabs: portalCollabsIds.map(id => portalCollabsData[id]),
  });
  const { portalCollabs, selectedPortalCollab } = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <div className="ui segment four wide column">
        {portalCollabs.map(({ name, _id }) => (
          <div className="ui vertical segment" key={_id}>
            <Button
              selected={selectedPortalCollab === _id}
              onClick={() => dispatch(selectPortalCollab(_id))}
            >
              {name}
            </Button>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ui.segment {
    background: #f4f6fa 0% 0% no-repeat padding-box;
    border-radius: 8px;
    border: none;
    box-shadow: none;
    padding: 10px 20px;
  }
`;

export default PortalCollabsPanel;
