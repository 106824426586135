import { createAction } from 'redux-actions';
import rest from '../services/helseoversikt/rest';
const moment = require('moment');

export const setListUsers = createAction('ANALYTICS/LIST_USERS');
export const setLoadProgress = createAction('ANALYTICS/LOAD_PROGRESS');

const monthNames = {
  1: 'januar',
  2: 'februar',
  3: 'mars',
  4: 'april',
  5: 'mai',
  6: 'juni',
  7: 'juli',
  8: 'august',
  9: 'september',
  10: 'oktober',
  11: 'november',
  12: 'desember',
};

const getMunicipalityNameAndConsentByUid = async dispatch => {
  let municipalities = { municipalitiesByUid: {}, consentsByUid: {} };
  let progressIndex = 1;
  const getNextMunicipalityNameAndConsentByUid = async nextPageToken => {
    dispatch(setLoadProgress({ municipalities: progressIndex }));
    progressIndex++;
    if (!nextPageToken) {
      nextPageToken = null;
    }
    const { municipalitiesByUid, consentsByUid, pageToken } =
      await rest.getMunicipalityNameAndConsentByUid({ urlKeys: { nextPageToken } });
    municipalities = {
      municipalitiesByUid: {
        ...municipalities.municipalitiesByUid,
        ...municipalitiesByUid,
      },
      consentsByUid: {
        ...municipalities.consentsByUid,
        ...consentsByUid,
      },
    };
    if (pageToken) {
      await getNextMunicipalityNameAndConsentByUid(pageToken);
    }
  };
  await getNextMunicipalityNameAndConsentByUid();
  dispatch(setLoadProgress({ municipalities: true }));
  return municipalities;
};
const getCountAllUsers = async (dispatch, municipalitiesByUid, consentsByUid) => {
  let userCount = 0;
  let added = {};
  let total = { noConsent: 0, notSelectedMunicipality: 0 };
  let after15August = {
    users: 0,
    noConsent: 0,
    notSelectedMunicipality: 0,
  };
  const afterMunicipality = moment('2019-08-15');
  let progressIndex = 1;
  const countAllUsers = async nextPageToken => {
    await rest
      .listUsers({ urlKeys: { nextPageToken } })
      .then(async listUsersResult => {
        dispatch(setLoadProgress({ listUsers: progressIndex }));
        progressIndex++;
        listUsersResult.users.forEach(function (userRecord) {
          userCount++;
          if (!consentsByUid[userRecord.uid]) {
            total.noConsent += 1;
          } else if (!municipalitiesByUid[userRecord.uid]) {
            total.notSelectedMunicipality += 1;
          }
          const creationTime = moment(userRecord.metadata.creationTime);
          if (afterMunicipality.isBefore(creationTime)) {
            after15August.users++;
            if (!consentsByUid[userRecord.uid]) {
              after15August.noConsent += 1;
            } else if (!municipalitiesByUid[userRecord.uid]) {
              after15August.notSelectedMunicipality += 1;
            }
          }
          added = {
            ...added,
            [creationTime.year()]: {
              ...(added[creationTime.year()] ? added[creationTime.year()] : {}),
              [creationTime.month() + 1]: {
                ...(added[creationTime.year()] &&
                added[creationTime.year()][creationTime.month() + 1]
                  ? added[creationTime.year()][creationTime.month() + 1]
                  : {}),
                [creationTime.date()]: {
                  ...(added[creationTime.year()] &&
                  added[creationTime.year()][creationTime.month() + 1] &&
                  added[creationTime.year()][creationTime.month() + 1][creationTime.date()]
                    ? added[creationTime.year()][creationTime.month() + 1][creationTime.date()]
                    : {}),

                  [creationTime.format('HH:mm:ss')]: municipalitiesByUid[userRecord.uid]
                    ? municipalitiesByUid[userRecord.uid]
                    : consentsByUid[userRecord.uid]
                    ? 'not selected'
                    : 'NO CONSENT',
                },
              },
            },
          };
        });
        if (listUsersResult.pageToken) {
          await countAllUsers(listUsersResult.pageToken);
        }
      })
      .catch(function (error) {
        console.error('Error listing users:', error);
      });
  };
  await countAllUsers();
  dispatch(setLoadProgress({ listUsers: true }));
  return { total, after15August, userCount, added };
};

export const getListUsers = () => async dispatch => {
  try {
    const { municipalitiesByUid, consentsByUid } = await getMunicipalityNameAndConsentByUid(
      dispatch
    );
    const { total, after15August, userCount, added } = await getCountAllUsers(
      dispatch,
      municipalitiesByUid,
      consentsByUid
    );

    const monthCount = {};
    const dayCount = {};
    const time = {};
    const last7Days = moment().subtract(7, 'days').startOf('day');
    const currentYear = last7Days.year();
    const last7DaysMonths = parseInt(last7Days.month()) + 1;
    const last7DaysDays = last7Days.date();
    let daySorted;
    Object.keys(added).forEach(year => {
      monthCount[year] = {};
      dayCount[year] = {};
      let thisMonth;
      Object.keys(added[year]).forEach(month => {
        dayCount[year][monthNames[month]] = {};
        thisMonth = 0;
        Object.keys(added[year][month]).forEach(day => {
          const registeredDay = Object.values(added[year][month][day]).length;
          thisMonth += registeredDay;
          dayCount[year][monthNames[month]][day] = registeredDay;
          if (
            currentYear < parseInt(year) ||
            (currentYear == year &&
              ((last7DaysMonths == month && last7DaysDays <= parseInt(day)) ||
                last7DaysMonths < parseInt(month)))
          ) {
            daySorted = {};
            Object.keys(added[year][month][day])
              .sort()
              .forEach(key => {
                if (daySorted[key]) {
                  daySorted[`${key}-${Math.random()}`] = added[year][month][day][key];
                } else {
                  daySorted[key] = added[year][month][day][key];
                }
              });
            time[`${year}/${parseInt(month)}/${day}`] = daySorted;
          }
        });
        monthCount[year] = {
          ...monthCount[year],
          [monthNames[month]]: thisMonth,
        };
      });
    });
    const countMunicipalities = {};
    Object.values(municipalitiesByUid).forEach(m => {
      countMunicipalities[m] = (countMunicipalities[m] || 0) + 1;
    });
    let municipalitiesByUsers = {};
    Object.keys(countMunicipalities)
      .sort((a, b) => countMunicipalities[b] - countMunicipalities[a])
      .forEach(m => (municipalitiesByUsers[m] = countMunicipalities[m]));

    let municipalities = {};
    Object.keys(countMunicipalities)
      .sort()
      .forEach(m => (municipalities[m] = countMunicipalities[m]));
    const result = {
      totalCount: userCount,
      total,
      after15August,
      monthCount,
      dayCount,
      time,
      municipalitiesByUsers,
      municipalities,
    };
    dispatch(setListUsers(result));
  } catch (err) {
    console.error(err);
  }
};
