import { isTestUser } from './analytics.helpers';
import Events from './Events';

class Analytics {
  constructor() {
    this.tester = isTestUser() || process.env.NODE_ENV === 'development';
  }

  setUserProperty = (propertyName, value) => {
    window.gtag('set', 'user_properties', { [propertyName]: value });
  };

  setUserId = () => {
    this.tester = isTestUser() || process.env.NODE_ENV === 'development';

    const userType = !this.tester ? 'user' : 'developer';
    window.gtag('set', 'user_properties', { userType });
  };

  logEvent = (title, parameters = {}) => {
    if (!this.tester && title) {
      window.gtag('event', title, parameters);
    }
  };
}

export default new Analytics();
export { Events };
