import React from 'react';
import PropTypes from 'prop-types';
import Label from '../label/Label';
import styled from 'styled-components';
import { Dropdown as DropdownUi } from 'semantic-ui-react';

const Container = styled.div`
  margin-right: 15px !important;
`;
const StyledDropdown = styled(DropdownUi)`
  background: #caddf1 0% 0% no-repeat padding-box !important;
  min-width: 170px !important;
  margin-left: 0px !important;
  border: none !important;
  border-radius: 4px !important;
  .default.text {
    color: #3b3d44;
  }
`;

const CarefeedItem = styled.div`
  background: #caddf1 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: fit-content;
  padding: 8px 30px;
  margin-right: 15px;
`;

const ToggleSelect = ({
  name,
  editing,
  label,
  options,
  clearable,
  defaultValue,
  noneSelectedText,
  onChange,
}) => {
  const value = options.find(({ key }) => key === defaultValue);
  return (
    <div>
      {editing ? (
        <Container>
          <Label>{label}</Label>
          <StyledDropdown
            name={name}
            clearable={clearable}
            fluid
            selection
            defaultValue={defaultValue}
            options={options}
            onChange={onChange}
          />
        </Container>
      ) : (
        <CarefeedItem>{(value && value.text) || noneSelectedText}</CarefeedItem>
      )}
    </div>
  );
};

ToggleSelect.propTypes = {
  name: PropTypes.string,
  editing: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
  clearable: PropTypes.bool,
  defaultValue: PropTypes.string,
  noneSelectedText: PropTypes.string,
  onChange: PropTypes.func,
};

export default ToggleSelect;
