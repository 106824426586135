import { auth } from '../firebase';

const TEST_USERES = [
  '+4712345678', // zqpEuPeLHHPZwR17o053WvLZ2Kq2
  '+4791716770', // Preben = U3FiUXYRErVGdQ37tOtNp0cyRoL2
  '+4795120122', // Carl Filip = 	Iavy3S8O3ZUOcm0ELtQrAO1q0dx1
  '+4797051008', // Alexander = 	zZ843FMHzdY3BXziIBSrt1VXEJ32
  '+4791118216', // Peter = 		2bcMk07Ck6QVZwqezLWERwa8gkf2
  '+4787654321', // Test
  '+4790290274', // solgunn Matre = dVF0w02KIGNz0lhFS5A3Au1IxUr2
  '+4797508445', // TOnje = SGOqvD5uHWdEVyHOG1Q8xMvpBbw2,
  '+4745283193‬', // Pops = 8aA3lDfe5IeswFgPq9D7WraorxP2
];

export const isTestUser = () =>
  auth().currentUser
    ? TEST_USERES.includes(auth().currentUser.phoneNumber)
    : false;
