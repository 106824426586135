import { createAction } from 'redux-actions';
import rest from '../services/helseoversikt/rest';
import { hasWeeksInRange } from '../helpers/articles.helpers';
import { PARENT_MODULE } from '../config/constants/constants';

export const setCarefeedArticles = createAction('CAREFEED/SET', ({ module, articles }) => ({
  module,
  articlesIds: articles.map(({ carefeedItemId }) => carefeedItemId),
  articles: articles.reduce((obj, article) => ({ ...obj, [article.carefeedItemId]: article }), {}),
}));
export const setCarefeedWeeksRange = createAction('CAREFEED/SET_RANGE');
export const setCarefeedMonthRange = createAction('CAREFEED/SET_MONTH_RANGE');

export const getCarefeed = module => async (dispatch, getState) => {
  const {
    userInformation: { portalCollab },
    sanity: { carefeed } = {}
  } = getState();

  const { weekFrom, weekTo, weeks } = carefeed[module];
  const isParentModuleHasWeeks = PARENT_MODULE === module && hasWeeksInRange({ weeks, weekFrom, weekTo });
  !isParentModuleHasWeeks &&
    rest
      .getCarefeed({
        urlKeys: {
          module,
          portalCollab,
          weekFrom,
          weekTo,
        },
      })
      .then(({ items }) => dispatch(setCarefeedArticles({ module, articles: items })));
};
