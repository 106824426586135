import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import lang from '../../../translationWrapper';
import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import MobileMenuButton from './MobileMenuButton';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { auth } from '../../../services/firebase';
import { logInUser } from '../../../actions/userAction';
import Drawer from '@material-ui/core/Drawer';
import analytics, { Events } from '../../../services/analytics/Analytics';

const MobileViewDrawer = ({ loggedIn, portalCollab, hasAccessToPortal, hasMorePortals }) => {
  const [open, setOpenState] = React.useState(false);
  let history = useHistory();
  const location = useLocation();
  const toggleDrawer = event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpenState(!open);
  };
  const logout = () => {
    analytics.logEvent(Events.LOGOUT);
    auth().signOut();
    logInUser(null);
    history.push('/login');
  };

  const isCurrentPage = linkUrl => linkUrl === location.pathname;

  return (
    <div>
      <React.Fragment>
        {loggedIn && <MobileMenuButton open={open} onClickHandler={toggleDrawer} />}
        <StyledDrawer
          transitionDuration="0"
          anchor="top"
          open={open}
          onClose={() => setOpenState(false)}
        >
          <div onClick={() => setOpenState(false)} onKeyDown={() => setOpenState(false)}>
            <MainNavigation>
              {hasAccessToPortal ? (
                <MainNavigationLink curentpage={isCurrentPage('/admin')} to="/admin">
                  {lang.nav.admin}
                </MainNavigationLink>
              ) : null}
              {portalCollab && portalCollab.slug ? (
                <Fragment>
                  <MainNavigationLink
                    curentpage={isCurrentPage(`/${portalCollab.slug}/carefeed/pregnancyAndBirth`)}
                    to={`/${portalCollab.slug}/carefeed/pregnancyAndBirth`}
                  >
                    {lang.nav.carefeedPregnancy}
                  </MainNavigationLink>

                  <MainNavigationLink
                    curentpage={isCurrentPage(`/${portalCollab.slug}/carefeed/parent`)}
                    to={`/${portalCollab.slug}/carefeed/parent`}
                  >
                    {lang.nav.carefeedParents}
                  </MainNavigationLink>

                  <MainNavigationLink
                    curentpage={isCurrentPage(`/${portalCollab.slug}/handbook`)}
                    to={`/${portalCollab.slug}/handbook`}
                  >
                    {lang.nav.handbook}
                  </MainNavigationLink>
                </Fragment>
              ) : null}
            </MainNavigation>

            <div>{loggedIn && <LogoutButton onClick={logout}>{lang.nav.logout}</LogoutButton>}</div>
          </div>
        </StyledDrawer>
      </React.Fragment>
    </div>
  );
};

const mapStateToProps = ({
  user: { uid, phoneNumber } = {},
  userInformation: { portalCollab, portalAccess: { super_admin, admin } = {} } = {},
  sanity: { portalCollabs: { portalCollabsData } = {} } = {},
}) => ({
  loggedIn: !!uid,
  phoneNumber,
  hasAccessToPortal: admin || super_admin,
  portalCollab: portalCollabsData && portalCollabsData[portalCollab],
});

export default connect(mapStateToProps, { logInUser })(MobileViewDrawer);

const StyledDrawer = styled(Drawer)`
  & .MuiDrawer-paperAnchorTop {
    top: 58px;
    min-height: 100%;
    padding: 20px;
  }
  & .MuiBackdrop-root {
    background-color: inherit;
  }
`;

const MainNavigation = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MainNavigationLink = styled(Link)`
  display: flex;
  color: #285888;
  font-size: 20px;
  padding: 35px 15px;
  &:hover {
    color: #285888b3;
  }
  ${props =>
    props.curentpage &&
    css`
      font-weight: 900;
    `}
`;

const LogoutButton = styled(Button)`
  height: 59px;
  width: 100%;
  padding: 16px 38px;
  box-sizing: border-box;
  color: #285888;
  font-size: 20px;
  text-transform: lowercase;
  background-color: inherit;
  border: 1px solid #3b3d4499;
  border-radius: 12px;
  &:hover {
    background-color: #caddf14d;
  }
`;
