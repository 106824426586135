import blockTools from '@sanity/block-tools';
import parse from 'html-react-parser';
import blockSchema from './blockSchema';
import {
  SANITY_TYPE_ARTICLE,
  BLOCKTYPE_VIDEO_URL,
  BLOCKTYPE_IMAGE,
  BLOCKTYPE_REF,
  BLOCKTYPE_BLOCKCONTENT,
  BLOCKTYPE_NEW_ASSET,
  HTMLTAG_IFRAME,
  HTMLTAG_IMG,
  DOMIAN_SANITY,
  IMAGE_SRC_DATA,
} from '../../../config/constants/constants';

const deserializeBlockContent = html => {
  return blockTools.htmlToBlocks(
    html,
    blockSchema.get(SANITY_TYPE_ARTICLE).fields.find(field => field.name === BLOCKTYPE_BLOCKCONTENT)
      .type,
    {
      rules: [
        {
          deserialize(el, next, block) {
            switch (el.tagName.toLowerCase()) {
              case HTMLTAG_IFRAME:
                return block({
                  _type: BLOCKTYPE_VIDEO_URL,
                  title: el.attributes.title.value.substring(8), // removed "Youtube " added by react-youtube
                  url: el.attributes.src.value,
                });
              case HTMLTAG_IMG:
                const src = el.attributes.src.value;
                if (src.includes(DOMIAN_SANITY)) {
                  const path = src.substring(29);
                  const params = path.split('/');
                  const imageIdAndParams = params[2].split('.');
                  const imageId = imageIdAndParams[0];
                  const imageType = imageIdAndParams[1].split('?')[0];
                  return block({
                    _type: BLOCKTYPE_IMAGE,
                    asset: { _ref: `image-${imageId}-${imageType}`, _type: BLOCKTYPE_REF },
                  });
                } else if (src.startsWith(IMAGE_SRC_DATA)) {
                  return block({
                    _type: BLOCKTYPE_NEW_ASSET,
                    src,
                  });
                }
                return undefined;
              default:
                return undefined;
            }
          },
        },
      ],
    }
  );
};

export default (obj, setValues) =>
  Object.keys(obj).map(name => {
    if (!obj[name]) {
      obj[name] = {};
    }
    const isObject = typeof obj[name] === 'object';
    const html = obj[name].toString('html');
    const value = parse(html);
    !isObject &&
      setValues(prevState => ({
        ...prevState,
        [name]: value,
      }));
    return {
      name,
      value: isObject ? obj[name] : deserializeBlockContent(html),
    };
  });
