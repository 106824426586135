import lang from '../../translationWrapper';
export const ADD_PHONE_NUMBER_TO_PORTAL_ACCESS_MODAL = 'addPhoneNumberToPortalAccessModal';
export const DELETE_ARTICLE_MODAL = 'deleteArticleModal';

export const STATUS_CODE_SERVER_ERROR = 500;
export const STATUS_CODE_USER_DATA_IS_INVALID = 400;
export const PREGNANCY_MODULE = 'pregnancyAndBirth';
export const PARENT_MODULE = 'parent';

export const PREGNANCY_WEEKS_LIMIT = 43;

export const CAREFEEDITEM_OPTIONS_SHOWINCAREFEED_INCAREFEED = 'inCarefeed';
export const CAREFEEDITEM_OPTIONS_SHOWINCAREFEED_NOTINCAREFEED = 'notCarefeed';

export const CAREFEEDITEM_SHOWINCAREFEED = 'carefeedShowInCarefeed';
export const CAREFEEDITEM_OPTIONS_SHOWINCAREFEED = [
  {
    key: CAREFEEDITEM_OPTIONS_SHOWINCAREFEED_INCAREFEED,
    value: CAREFEEDITEM_OPTIONS_SHOWINCAREFEED_INCAREFEED,
  },
  {
    key: CAREFEEDITEM_OPTIONS_SHOWINCAREFEED_NOTINCAREFEED,
    value: CAREFEEDITEM_OPTIONS_SHOWINCAREFEED_NOTINCAREFEED,
  },
];

export const CAREFEEDITEM_TARGET = 'carefeedTarget';
export const CAREFEEDITEM_TARGET_NOMODULE = 'carefeed_target_noModuleSelected';
export const CAREFEEDITEM_OPTIONS_TARGET_NOMODULE = {
  key: CAREFEEDITEM_TARGET_NOMODULE,
  value: CAREFEEDITEM_TARGET_NOMODULE,
  text: lang.articleEdit.target_noModuleSelected,
};

export const TYPE_FOLDER = 'folder';
export const TYPE_ARTICLE = 'article';

export const HTMLTAG_IMG = 'img';
export const HTMLTAG_IFRAME = 'iframe';

export const SANITY_TYPE_ARTICLE = 'article';
export const BLOCKTYPE_BLOCKCONTENT = 'blockContent';
export const BLOCKTYPE_VIDEO_URL = 'videoUrl';
export const BLOCKTYPE_IMAGE = 'image';
export const BLOCKTYPE_NEW_ASSET = 'newAsset';
export const BLOCKTYPE_REF = 'reference';

export const DOMIAN_SANITY = 'cdn.sanity.io';
export const IMAGE_SRC_DATA = 'data:';

export const ARTICLE_FIELD_MAINIMAGE = 'mainImage';
export const ARTICLE_FIELD_MAINVIDEO = 'mainVideoUrl';
