import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import analytics from '../../services/analytics/Analytics';

const useUserAnalyticProperties = () => {
  const portalCollabNameSelector = ({ userInformation: { portalCollabName } = {} }) =>
    portalCollabName;
  
  const portalCollabName = useSelector(portalCollabNameSelector);

  useEffect(() => {
    if (portalCollabName) {
      analytics.setUserProperty('Portal', portalCollabName);
    }
  }, [portalCollabName]);

  useEffect(() => {
    analytics.setUserId();
  }, []);
};

export default useUserAnalyticProperties;
