import React, { Fragment, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import MobileNavigation from './MobileView/MobileNavigation';
import { auth } from '../../services/firebase';
import icons from '../../assets/img';
import { useTheme } from '@material-ui/core/styles';
import { logInUser } from '../../actions/userAction';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import lang from '../../translationWrapper';
import analytics, { Events } from '../../services/analytics/Analytics';
import styled, { css } from 'styled-components';

const Nav = ({
  loggedIn,
  logInUser,
  hasAccessToPortal,
  portalCollab,
  super_admin,
  hasMorePortals,
}) => {
  let history = useHistory();

  const logout = () => {
    analytics.logEvent(Events.LOGOUT);
    auth().signOut();
    logInUser(null);
    history.push('/login');
  };

  const goToPortalUrl = useMemo(() => {
    if (hasMorePortals) {
      return '/portals';
    } else if (portalCollab) {
      return `/${portalCollab.slug}/home`;
    }
  }, [hasMorePortals, portalCollab]);

  const location = useLocation();

  const isCurrentPage = linkUrl => linkUrl === location.pathname;

  const theme = useTheme();
  const isDesktopView = useMediaQuery(theme.breakpoints.up('lg'));

  return isDesktopView ? (
    <NavAppBar>
      <StyledToolbar>
        <LogoWrapper>
          <NavLink to={portalCollab ? `/${portalCollab.slug}/home` : '/portals'}>
            <img src={icons.logo} height="80" width="80" alt={lang.alt.logo} />
          </NavLink>

          <PortalLink to={goToPortalUrl}>
            {loggedIn && portalCollab ? portalCollab.name : lang.nav.choosePortal}
          </PortalLink>
        </LogoWrapper>
        {loggedIn && (
          <MainNavigation>
            {hasAccessToPortal ? (
              <MainNavigationLink curentpage={isCurrentPage('/admin')} to="/admin">
                {lang.nav.admin}
              </MainNavigationLink>
            ) : null}
            {portalCollab && portalCollab.slug ? (
              <Fragment>
                <MainNavigationLink
                  curentpage={isCurrentPage(`/${portalCollab.slug}/carefeed/pregnancyAndBirth`)}
                  to={`/${portalCollab.slug}/carefeed/pregnancyAndBirth`}
                >
                  {lang.nav.carefeedPregnancy}
                </MainNavigationLink>

                <MainNavigationLink
                  curentpage={isCurrentPage(`/${portalCollab.slug}/carefeed/parent`)}
                  to={`/${portalCollab.slug}/carefeed/parent`}
                >
                  {lang.nav.carefeedParents}
                </MainNavigationLink>

                <MainNavigationLink
                  curentpage={isCurrentPage(`/${portalCollab.slug}/handbook`)}
                  to={`/${portalCollab.slug}/handbook`}
                >
                  {lang.nav.handbook}
                </MainNavigationLink>
              </Fragment>
            ) : null}
            {super_admin ? (
              <MainNavigationLink curentpage={isCurrentPage('/analytics')} to="/analytics">
                {lang.nav.analytics}
              </MainNavigationLink>
            ) : null}
          </MainNavigation>
        )}

        <div>{loggedIn && <LogoutButton onClick={logout}>{lang.nav.logout}</LogoutButton>}</div>
      </StyledToolbar>
    </NavAppBar>
  ) : (
    <MobileNavigation />
  );
};

const mapStateToProps = ({
  user: { uid, phoneNumber } = {},
  userInformation: { portalCollab, portalAccess: { super_admin, admin } = {} } = {},
  sanity: { portalCollabs: { portalCollabsData } = {} } = {},
}) => ({
  loggedIn: !!uid,
  phoneNumber,
  hasAccessToPortal: admin || super_admin,
  super_admin,
  portalCollab: portalCollabsData && portalCollabsData[portalCollab],
  hasMorePortals: portalCollabsData && Object.keys(portalCollabsData).length > 1,
});

export default connect(mapStateToProps, { logInUser })(Nav);

const NavAppBar = styled(AppBar)`
  display: flex;
  height: 120px;
  background-color: #f4f6fa;
`;

const StyledToolbar = styled(Toolbar)`
  height: 100%;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const MainNavigation = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0 35px;
  border-left: 1px solid #3b3d4433;
`;

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 20px;
  height: 100%;
  color: #3b3d44;
  &:hover {
    color: #3b3d4499;
  }
`;

const MainNavigationLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #285888;
  font-size: 20px;
  height: 100%;
  padding: 0 35px;
  &:hover {
    color: #285888b3;
  }
  ${props =>
    props.curentpage &&
    css`
      font-weight: 900;
    `}
`;

const PortalLink = styled(NavLink)`
  padding: 0 20px;
`;

const LogoutButton = styled(Button)`
  height: 59px;
  padding: 16px 38px;
  box-sizing: border-box;
  color: #285888;
  font-size: 20px;
  text-transform: lowercase;
  background-color: inherit;
  border: 1px solid #3b3d4499;
  border-radius: 12px;
  &:hover {
    background-color: #caddf14d;
  }
`;
