import { useState, useEffect } from 'react';
import lang from '../translationWrapper';
const getTextByValidation = type => {
  switch (type) {
      case 'pattern':
        return lang.articleEdit.validate_youtubeUrl;
    default:
      return lang.articleEdit.validate_default;
  }
};

const useYoutubeUrlValidation = (validation, rules, value) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    if (validation) {
      setError(getTextByValidation(validation.type, value, rules));
    } else {
      setError(null);
    }
  }, [validation, value, rules]);

  return error;
};


export default useYoutubeUrlValidation;
