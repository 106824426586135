import React, { Fragment } from 'react';
import AddPhoneNumberModal from '../admin/AddPhoneNumberModal';
import DeleteArticleModal from '../article/DeleteArticleModal';

const Modals = () => (
  <Fragment>
    <AddPhoneNumberModal />
    <DeleteArticleModal />
  </Fragment>
);

export default Modals;
