import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledCheckbox = styled.div`
  .label {
    width: 100%;
    margin-bottom: 10px;
  }
  label {
    font: normal normal medium 16px/19px Lato;
    letter-spacing: 0px;
    color: #3b3d44;
  }
  .ui.checkbox .box::before,
  .ui.checkbox label::before {
    border: 1px solid #3b3d4499;
    border-radius: 4px;
  }

  .ui.checkbox input:checked ~ .box::before,
  .ui.checkbox input:checked ~ label::before {
    border: 1px solid #c9dcf1;
    background: #c9dcf1;
  }
`;

const CheckboxTopMargin = styled.div`
  margin-top: 20px;
`;

const Checkbox = ({ label, name, checked, onChange, hasMargin = false }) => (
  <StyledCheckbox className="row">
     {hasMargin && <CheckboxTopMargin />}
    {label ? (
      <div className="label">
        <label>{label}</label>
      </div>
    ) : null}
    <div className="ui checkbox">
      <input type="checkbox" name={name} checked={checked} value={label} onChange={onChange} />
      <label className="ui"></label>
    </div>
  </StyledCheckbox>
);

Checkbox.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default Checkbox;
