import { useState, useEffect } from 'react';
import lang from '../translationWrapper';

const useTextValidation = (validation, rules, value) => {
  const [error, setError] = useState(null);
  const getTextByValidation = (type, value) => {
    switch (type) {
      case 'maxLength':
        return lang.articleEdit.validate_maxLength.replace('$max', rules.maxLength);
      case 'minLength':
        return lang.articleEdit.validate_minLength.replace('$min', rules.minLength);
      case 'required':
        return lang.articleEdit.validate_required;
      default:
        return lang.articleEdit.validate_default;
    }
  };
  useEffect(() => {
    if (validation) {
      setError(getTextByValidation(validation.type, value));
    } else {
      setError(null);
    }
  });

  return error;
};

export default useTextValidation;
