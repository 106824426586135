import banner from './banner.png';
import logo from './logo.svg';
import folder from './folder.png';
import folder_opened from './folder_opened.png';

export default {
  banner,
  logo,
  folder,
  folder_opened
};
