import { handleActions } from 'redux-actions';
import { saveSearchHandbookArticles, saveHandbookFoldersAndArticles } from '../actions/handbookActions';

const initialState = {
  searchArticles: null,
  folderAndArticles: {},
  levelsOfFolders: {},
};

export default handleActions(
  {
    [saveSearchHandbookArticles]: (state, { payload }) => ({
      ...state,
      searchArticles: payload,
    }),
    [saveHandbookFoldersAndArticles]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  initialState
);
