import { handleActions } from 'redux-actions';
import {
  setUserData,
  setActivePortalCollab,
  setActivePortalCollabSlug,
  setActivePortalCollabName,
  setActivePortalCollabEndorsement,
} from '../actions/userAction';

const initialState = {
  portalCollab: null,
  portalCollabName: null,
  portalCollabSlug: '',
  portalCollabEndorsement: null,
};

export default handleActions(
  {
    [setUserData]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [setActivePortalCollab]: (state, { payload }) => ({
      ...state,
      portalCollab: payload,
    }),
    [setActivePortalCollabEndorsement]: (state, { payload }) => ({
      ...state,
      portalCollabEndorsement: payload,
    }),
    [setActivePortalCollabSlug]: (state, { payload }) => ({
      ...state,
      portalCollabSlug: payload,
    }),
    [setActivePortalCollabName]: (state, { payload }) => ({
      ...state,
      portalCollabName: payload,
    }),
  },
  initialState
);
