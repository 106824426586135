import { selectPortalCollab, loadListOfPhoneNumbers } from '../../actions/adminActions';

const adminPanelMiddleware = ({ dispatch, getState }) => next => action => {
  if (action.type === String(selectPortalCollab)) {
    action.payload && dispatch(loadListOfPhoneNumbers(action.payload));
  }

  return next(action)
}

export default adminPanelMiddleware;
