import { Quill } from 'react-quill';
let BlockEmbed = Quill.import('blots/block/embed');

class ImageBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    const [url, alt] = value.url ? [value.url, value.alt] : [value, 'image'];
    node.setAttribute('alt', alt);
    node.setAttribute('src', url);
    node.style.maxWidth = '100%';
    return node;
  }

  static value(node) {
    return {
      alt: node.getAttribute('alt'),
      url: node.getAttribute('src'),
    };
  }
}
ImageBlot.blotName = 'image';
ImageBlot.tagName = 'img';

export default ImageBlot;
