import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../config/styles/colors';

const H1Container = styled.div`
  display: flex;
  flex-direction: columns;
  margin-bottom: 50px;
`;

const Rectangle = styled.div`
  width: 5px;
  height: 34px;
  background: ${colors.darkMainColor} 0% 0% no-repeat padding-box;
  margin-right: 16px;
`;

const H1Text = styled.h1`
  text-align: left;
  font: normal normal 900 28px Lato;
  line-height: 34px;
  letter-spacing: 0px;
  color: ${colors.text};
  margin: 0;
`;

const H1 = ({ title }) => {
  return (
    <H1Container>
      <Rectangle />
      <H1Text >{title}</H1Text>
    </H1Container>
  );
};

H1.propTypes = {
  title: PropTypes.string,
};
export default H1;
