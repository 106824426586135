import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './reducers/configureStore';
import App from './pages/App/App';
import CustomMuiTheme from './themes/CustomMuiTheme';
import './index.css';
import 'semantic-ui-css/semantic.min.css';

if (
  !(
    window.location.host.startsWith('127.0.0.1') ||
    window.location.host.startsWith('localhost') ||
    window.location.host.startsWith('stage')
  ) &&
  window.location.protocol !== 'https:'
)
  window.location.protocol = 'https:';

ReactDOM.render(
  <Provider store={store()}>
    <BrowserRouter>
      {/* <AuthProvider> */}
      <CustomMuiTheme>
        <App />
      </CustomMuiTheme>
      {/* </AuthProvider> */}
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
