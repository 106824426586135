import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ListItem } from '../../components';
import HandbookFolders from './HandbookFolders';
import { selectFolderOrArticle } from '../../actions/handbookActions';
import { formHandbookUrl, getFolderUrl } from './Handbook.helpers';
import lang from '../../translationWrapper';
import colors from '../../config/styles/colors';
import icons from '../../assets/img';
import { TYPE_FOLDER, TYPE_ARTICLE } from '../../config/constants/constants';
import analytics, { Events } from '../../services/analytics/Analytics';

const HandbookFolder = ({ id, title, type, isFirst, url = '', pathname, search }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [isOpened, toggle] = useState(pathname.includes(id));
  const isFolder = type === TYPE_FOLDER;
  const mapStateToProps = ({ foldersAndArticles, userInformation: { portalCollabSlug } = {} }) => ({
    portalCollabSlug,
    isSelected: foldersAndArticles[type] === id,
    article: foldersAndArticles[TYPE_ARTICLE],
  });
  const folderUrl = type === TYPE_FOLDER ? `${url}/${id}` : url;

  const { portalCollabSlug, isSelected, article } = useSelector(mapStateToProps);

  return (
    <li title={title}>
      <Folder isSelected={isSelected} isFolder={isFolder}>
        <ListItem
          hideIcon
          hideBorder={isFirst}
          onClick={() => {
            if (type === TYPE_FOLDER) {
              toggle(!isOpened);
              dispatch(selectFolderOrArticle({ type, id }));
              analytics.logEvent(Events.HANDBOOK_CLICKFOLDER, { title });
            } else {
              analytics.logEvent(Events.HANDBOOK_CLICKFOLDER_CLICKARTICLE, { title });
            }
            const urlForPush = getFolderUrl({ portalCollabSlug, url: folderUrl });
            const lastSlash = urlForPush.lastIndexOf('/');
            const url = formHandbookUrl({
              pathname: isOpened ? urlForPush.slice(0, lastSlash) : urlForPush,
              article: type === TYPE_FOLDER ? article : id,
              search,
            });
            history.push(url);
          }}
        >
          <FolderWrapper>
            {isFolder ? (
              <img
                src={isOpened ? icons.folder_opened : icons.folder}
                alt={lang.alt.handbookFolder}
              />
            ) : null}
            <ItemTitle id={isFirst ? 1 : 0}>{title}</ItemTitle>
          </FolderWrapper>
        </ListItem>
      </Folder>
      {isFolder && isOpened ? (
        <HandbookFolders pathname={pathname} id={id} url={folderUrl} />
      ) : null}
    </li>
  );
};

HandbookFolder.propTypes = {
  id: PropTypes.string,
  isFirst: PropTypes.bool,
  pathname: PropTypes.string,
  search: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
};

const FolderWrapper = styled.div`
  display: flex;
  align-items: baseline;
  overflow: hidden;
`;

const ItemTitle = styled.p`
  margin: 0;
  margin-left: 5px;
  position: relative;
  bottom: 3px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props =>
    props.id &&
    css`
      font-weight: 900;
    `};
`;

const Folder = styled.div`
  ${props =>
    props.isFolder &&
    (props.isSelected
      ? css`
          background-color: ${colors.backgroundSurface};
        `
      : css`
          &:hover {
            background-color: ${colors.lightBlue};
            p {
              opacity: 0.8;
            }
          }
        `)}
  ${props =>
    !props.isFolder &&
    (props.isSelected
      ? css`
          p {
            color: ${colors.blue};
          }
        `
      : css`
          &:hover {
            p {
              color: ${colors.blue};
            }
          }
        `)}
`;

export default HandbookFolder;
