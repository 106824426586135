import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import lang from '../../translationWrapper';

const StyledRow = styled.div`
  .PhoneInput {
    width: 100%;
    margin-top: 10px;
  }
  label {
    text-align: left;
    font: normal normal medium 16px/19px Lato;
    letter-spacing: 0px;
    color: #3b3d44;
  }
  .PhoneInputInput {
    font: normal normal bold 24px/36px Lato;
    height: 40px;
    border: 1px solid #3b3d4433;
    border-radius: 4px;
  }

  --PhoneInputCountryFlag-height: 40px;
  --PhoneInputCountryFlag-borderWidth: 0px;
  --PhoneInputCountryFlag-borderColor: #3b3d4433;
`;

const PhoneNumberInputWithCountry = ({ label = '', value, onChange }) => (
  <StyledRow className="row" >
    <label>{label}</label>
    <PhoneInput
      defaultCountry="NO"
      placeholder={lang.portal.enterPhoneNumber}
      international
      value={value}
      onChange={onChange}
    />
  </StyledRow>
);

PhoneNumberInputWithCountry.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
};

export default PhoneNumberInputWithCountry;
