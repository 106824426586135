import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { FormInputText, Button } from '../../components';
import MainImage from './MainImage';
import MainVideoTextInput from './MainVideoTextInput';
import BlockContent from './BlockContent';
import lang from '../../translationWrapper';
import regex from '../../config/constants/regex.constants';
import ArticleCarefeedItem from './ArticleCarefeedItem';
import ArticleSourceItems from './ArticleSourceItems';
import { ARTICLE_FIELD_MAINIMAGE, ARTICLE_FIELD_MAINVIDEO } from '../../config/constants/constants';

const ArticleTextAndInput = ({
  values,
  onChangeBlock,
  onChangeCarefeedItems,
  editing,
  onSubmit,
  abort,
  source,
  isFetching = false,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ shouldUnregister: true });
  return (
    <Wrapper id="submit-form" onSubmit={handleSubmit(onSubmit)}>
      <ButtonsBar isHidden={!editing && !isFetching}>
        <Button
          pendingPosition="start"
          pending={isFetching}
          className="ui button"
          type="submit"
          onClick={() => handleSubmit(onSubmit)}
        >
          {lang.buttons.Save}
        </Button>
        <Button className="ui button" onClick={abort}>
          {lang.buttons.Cancel}
        </Button>
      </ButtonsBar>
      {values ? (
        <Fragment>
          <MainImage
            mainImage={values.mainImage}
            editing={editing}
            hasMainVideo={values && !!values.mainVideoUrl}
            name={ARTICLE_FIELD_MAINIMAGE}
            onChange={onChangeBlock}
          />
          <MainVideoTextInput
            register={register}
            registerOptions={{ pattern: regex.youtubeUrlValidation }}
            value={values.mainVideoUrl}
            name={ARTICLE_FIELD_MAINVIDEO}
            editing={editing}
            validate={errors[ARTICLE_FIELD_MAINVIDEO]}
          />
          <ArticleCarefeedItem
            editing={editing}
            items={values.carefeedItems}
            onChange={onChangeCarefeedItems}
          />
          <FormInputText
            register={register}
            registerOptions={{ required: true, maxLength: 100, minLength: 3 }}
            value={values.title}
            name="title"
            editing={editing}
            validate={errors.title}
            rules={{ maxLength: 100, minLength: 3 }}
          />
          <FormInputText
            register={register}
            registerOptions={{ maxLength: 200 }}
            value={values.oneliner}
            name="oneliner"
            editing={editing}
            validate={errors.oneliner}
            rules={{ maxLength: 200 }}
          />
          <BlockContent
            onChange={onChangeBlock}
            blocks={values.summary}
            name={'summary'}
            editing={editing}
          />
          <BlockContent
            onChange={onChangeBlock}
            blocks={values.keyTakeaway}
            name={'keyTakeaway'}
            editing={editing}
          />
          <ArticleSourceItems editing={editing} source={source} />
          <BlockContent
            onChange={onChangeBlock}
            blocks={values.body}
            name={'body'}
            editing={editing}
          />
        </Fragment>
      ) : (
        <div />
      )}
    </Wrapper>
  );
};

const ButtonsBar = styled.div`
  width: 670px;
  height: 60px;
  position: fixed;
  bottom: 0px;
  padding: 0px;
  z-index: 999;
  ${props =>
    props.isHidden &&
    css`
      display: none;
    `}

  button {
    width: 50%;
    margin: 0;
    height: 60px;
    margin: 0 !important;
  }
  button:nth-of-type(1) {
    color: #fff;
    background: #285888;
    border-radius: 8px 0px 0px 0px;
  }
  button:nth-of-type(2) {
    border-radius: 0px 8px 0px 0px;
  }
`;

const Wrapper = styled.form`
  margin-bottom: 80px;

  #title {
    font: normal normal 900 40px/48px Lato;
  }
  #oneliner {
    font: normal normal bold 24px/36px Lato;
    margin-bottom: 20px;
  }
  #summary {
    margin-bottom: 50px;
  }
  #keyTakeaway {
    background: #f4f6fa 0% 0% no-repeat padding-box;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 50px;
  }

  @media all and (max-width: 670px) {
    ${ButtonsBar} {
      width: 100%;
      right: 0px;
    }
  }
`;

ArticleTextAndInput.propTypes = {
  abort: PropTypes.func,
  editing: PropTypes.bool,
  isFetching: PropTypes.bool,
  onChangeBlock: PropTypes.func,
  onChangeCarefeedItems: PropTypes.func,
  onSubmit: PropTypes.func,
  source: PropTypes.arrayOf(PropTypes.object),
  values: PropTypes.shape({
    _id: PropTypes.string,
    mainImage: PropTypes.object,
    title: PropTypes.string,
    oneliner: PropTypes.string,
    summary: PropTypes.any,
    keyTakeaway: PropTypes.any,
    body: PropTypes.any,
  }),
};

export default ArticleTextAndInput;
