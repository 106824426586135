import React, { useEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import VideoBlot from './VideoBlot';
import ImageBlot from './ImageBlot';
import rTConfig from './editorConfig.constants';
import lang from '../../translationWrapper';
import { registerSmartBreak } from './registerSmartBreak';

// This is a fork of the original code from the following link:
// https://codesandbox.io/p/sandbox/react-quill-line-break-br-fully-working-forked-sslxrt

Quill.register(ImageBlot);
Quill.register(VideoBlot);

const StyledReactQuill = styled(ReactQuill)`
  ${props =>
    props.videoTooltipLabel &&
    css`
      .ql-snow .ql-tooltip[data-mode='video']::before {
        content: '${props.videoTooltipLabel}';
      }
    `}
  ${props =>
    props.linkTooltipLabel &&
    css`
      .ql-snow .ql-tooltip[data-mode='link']::before {
        content: '${props.linkTooltipLabel}';
      }
    `}
  .ql-editor p, .ql-editor blockquote, .ql-editor ul, .ql-editor ol, 
  .ql-editor img, .ql-editor iframe {
    margin-bottom: 1em;
  }
`;

const Parchment = Quill.import('parchment');

registerSmartBreak();

function lineBreakMatcher() {
  var Delta = Quill.import('delta');
  var newDelta = new Delta();
  newDelta.insert({ break: '' });
  return newDelta;
}

function handleLinebreak(range, context) {
  const { quill } = this;
  const currentLeaf = quill.getLeaf(range.index)[0];
  const nextLeaf = quill.getLeaf(range.index + 1)[0];

  quill.insertEmbed(range.index, 'break', true, Quill.sources.USER);

  if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
    quill.insertEmbed(range.index, 'break', true, Quill.sources.USER);
  }
  quill.setSelection(range.index + 1, Quill.sources.SILENT);

  Object.keys(context.format).forEach(name => {
    if (Parchment.query(name, Parchment.Scope.BLOCK)) return;
    if (Array.isArray(context.format[name])) return;
    if (name === 'link') return;
    quill.format(name, context.format[name], Quill.sources.USER);
  });
}

function handleEnter(range, context) {
  const { quill } = this;

  if (range.length > 0) {
    quill.scroll.deleteAt(range.index, range.length);
  }
  const lineFormats = Object.keys(context.format).reduce((acc, format) => {
    if (Parchment.query(format, Parchment.Scope.BLOCK) && !Array.isArray(context.format[format])) {
      acc[format] = context.format[format];
    }
    return acc;
  }, {});

  const previousChar = quill.getText(range.index - 1, 1);

  quill.insertText(range.index, '\n', lineFormats, Quill.sources.USER);

  if (
    previousChar == '' ||
    (previousChar == '\n' && !(context.offset > 0 && context.prefix.length === 0))
  ) {
    quill.setSelection(range.index + 2, Quill.sources.SILENT);
  } else {
    quill.setSelection(range.index + 1, Quill.sources.SILENT);
  }
  Object.keys(context.format).forEach(name => {
    if (lineFormats[name] != null) return;
    if (Array.isArray(context.format[name])) return;
    if (name === 'link') return;
    quill.format(name, context.format[name], Quill.sources.USER);
  });
}

const Editor = ({
  value = '',
  onChange,
  videoTooltipLabel = lang.articleEdit.video_tooltip_label,
  linkTooltipLabel = lang.articleEdit.link_tooltip_label,
}) => {
  const quillRef = useRef(null);
  const removeExtraneousLines = () => {
    const quill = quillRef.current.getEditor();
    const length = quill.getLength();
    const text = quill.getText(length - 2, 2);
    if (text === '\n\n') {
      quill.deleteText(quill.getLength() - 2, 2);
    }
  };

  useEffect(() => {
    removeExtraneousLines();
  }, []);

  return (
    <StyledReactQuill
      ref={quillRef}
      theme="snow"
      videoTooltipLabel={videoTooltipLabel}
      linkTooltipLabel={linkTooltipLabel}
      value={value}
      onChange={onChange}
      modules={{
        ...rTConfig.modules,
        clipboard: {
          matchers: [['BR', lineBreakMatcher]],
          matchVisual: false,
        },
        keyboard: {
          bindings: {
            break: {
              key: 13,
              handler: handleEnter,
            },
            linebreak: {
              key: 13,
              shiftKey: true,
              handler: handleLinebreak,
            },
          },
        },
      }}
      formats={rTConfig.formats}
    />
  );
};

Editor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  videoTooltipLabel: PropTypes.string,
  linkTooltipLabel: PropTypes.string,
  modules: PropTypes.object,
};

export default Editor;
