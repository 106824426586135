import axios from 'axios';
import { api } from './endpoints';
import { auth } from '../firebase';

// const timeout = 2000
// const headers = {
//   'Content-Type' : 'application/json'
// }
// const axiosInstance = axios.create({
//   timeout,
//   headers,
//   withCredentials: true
// })

const changeUrlKeys = (url, urlKeys = {}) => {
  const keys = Object.keys(urlKeys).map(key => ({ urlKey: `:${key}`, value: urlKeys[key] }));

  return keys.reduce((url, { urlKey, value }) => url.replace(urlKey, value), url);
};

const endpoints = {};

Object.keys(api).forEach(key => {
  const { url, method } = api[key];

  endpoints[key] = ({ urlKeys, ...args } = {}) => {

    // getIdToken() returns the current token if it has not expired.
    // Otherwise, this will refresh the token and return a new one.

    return auth().currentUser.getIdToken()
      .then(token => {
        axios.defaults.headers.common['authtoken'] = token;
        return axios[method](changeUrlKeys(url, urlKeys), args);
      })
      .catch(err => {
        throw err;
      })
      .then(({ data }) => data);
  };
});

export default endpoints;
