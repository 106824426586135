import { handleActions } from 'redux-actions';
import {
  savePhoneNumbersFromPortalCollab,
  removePhoneNumberFromPortalCollab,
  savePhoneNumberToPortalCollab,
  updatePhoneNumberForPortalCollab,
  saveErrorOnLoadOfPhoneNumbersFromPortalCollab,
  selectEditUserInPortalCollab,
} from '../actions/adminActions';

const initialState = {
  selectedUser: null,
};

export default handleActions(
  {
    [selectEditUserInPortalCollab]: (state, { payload }) => ({ ...state, selectedUser: payload }),
    [savePhoneNumbersFromPortalCollab]: (state, { payload }) => ({ ...state, ...payload }),
    [saveErrorOnLoadOfPhoneNumbersFromPortalCollab]: (state, { payload }) => ({
      ...state,
      [payload.id]: { ...payload },
    }),
    [removePhoneNumberFromPortalCollab]: (
      state,
      { payload: { selectedPortalCollab, id } = {} }
    ) => ({
      ...state,
      [selectedPortalCollab]: {
        ...state[selectedPortalCollab],
        phoneNumbersId: state[selectedPortalCollab].phoneNumbersId.filter(key => key !== id),
      },
    }),
    [savePhoneNumberToPortalCollab]: (state, { payload }) => ({
      ...state,
      [payload.portalCollab]: {
        ...(state[payload.portalCollab]
          ? {
              phoneNumbersId: [...state[payload.portalCollab].phoneNumbersId, payload.id],
              phoneNumbers: { ...state[payload.portalCollab].phoneNumbers, [payload.id]: payload },
            }
          : {
              phoneNumbersId: [payload.id],
              phoneNumbers: { [payload.id]: payload },
            }),
      },
    }),
    [updatePhoneNumberForPortalCollab]: (state, { payload }) => ({
      ...state,
      [payload.portalCollab]: {
        ...state[payload.portalCollab],
        phoneNumbers: { ...state[payload.portalCollab].phoneNumbers, [payload.id]: payload },
      },
    }),
  },
  initialState
);
