import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import Home from '../home/Home';
import Admin from '../admin/Admin';
import Article from '../article/Article';
import Carefeed from '../carefeed/Carefeed';
import Login from '../auth/Login';
import Handbook from '../handbook/Handbook';
import NotFound from '../notFound/NotFound';
import ChoosePortal from '../auth/ChoosePortal';
import { auth } from '../../services/firebase';
import { authStateChange } from '../../actions/userAction';
import usePageTracking from './usePageTracking';
import useUserAnalyticProperties from './useUserAnalyticProperties';
import PrivateRoute from './PrivateRoute';
import Modals from './Modals';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Analytics from '../analytics/Analytics.page';
import DebugUserPage from '../debugUser/DebugUser.page';

const App = ({ authStateChange }) => {
  usePageTracking();
  useUserAnalyticProperties();

  const theme = useTheme();
  const isDesktopView = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged(authStateChange);
    return () => unsubscribe();
  }, [authStateChange]);

  return (
    <Fragment>
      <Modals />
      <div>
        <Nav />
        <div
          className="ui vertical stripe segment"
          style={{ marginTop: isDesktopView ? '170px' : '73px' }}
        >
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/not-found" component={NotFound} />
            <PrivateRoute path="/portals" component={ChoosePortal} />
            <PrivateRoute path="/admin" withSpecialAccess component={Admin} />
            <PrivateRoute path="/analytics" component={Analytics} superAdmin />
            <PrivateRoute path="/debuguser/:tlf" component={DebugUserPage} superAdmin />
            <Route exact path="/">
              <PrivateRoute portal />
            </Route>
            <PrivateRoute portal path="/:portalSlug/home" component={Home} />
            <PrivateRoute portal path="/:portalSlug/article/create" component={Article} />
            <PrivateRoute portal path="/:portalSlug/article/:id" component={Article} />
            <PrivateRoute portal path="/:portalSlug/handbook" component={Handbook} />
            <PrivateRoute portal path="/:portalSlug/carefeed/:module" component={Carefeed} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    </Fragment>
  );
};

App.propTypes = {
  authStateChange: PropTypes.func,
  hasAccessToPortal: PropTypes.bool,
  inited: PropTypes.bool,
  loggedIn: PropTypes.bool,
  municipality: PropTypes.string,
};

const dispatchToProps = { authStateChange };

export default connect(null, dispatchToProps)(App);
