import moment from 'moment';

export const WEEKS_IN_MONTHS = new Array(73)
  .fill(0)
  .map((item, index) => moment().add(index, 'M').diff(moment(), 'weeks'))
  .map((item, index, array) =>
    index !== array.length - 1 ?
      ({
        weekFrom: item,
        weekTo: array[index + 1]
      }) :
      null
  )
  .filter(item => item);
