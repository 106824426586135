import React from 'react';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import lang from '../../translationWrapper';
import {
  CurrentWeekCounterContainer,
  MainText,
  InfoContainer,
  InfoTextHeader,
  InfoSubHeaderContainer,
  UpTag,
  DownTag,
} from './CurrentWeekCounter.style';
const CurrentWeekCounter = () => {
  const { userCount, percentChange } = useSelector(mapStateToProps);
  return (
    <CurrentWeekCounterContainer>
      <MainText>{userCount}</MainText>
      <InfoContainer>
        <InfoTextHeader>{lang.stats.userCount}</InfoTextHeader>
        <InfoSubHeaderContainer>
          {percentChange > 0 ? (
            <Fragment>
              <UpTag />
              <div>{`${percentChange}% ${lang.stats.weekPercentUp}`}</div>
            </Fragment>
          ) : percentChange === 0 ? (
            <div>{lang.stats.weekNoChange}</div>
          ) : (
            <Fragment>
              <DownTag />
              <div>{`${percentChange}% ${lang.stats.weekPercentDown}`}</div>
            </Fragment>
          )}
        </InfoSubHeaderContainer>
      </InfoContainer>
    </CurrentWeekCounterContainer>
  );
};

const mapStateToProps = ({ stats: { countByWeek, userCount } = {} }) => {
  const values = Object.values(countByWeek);
  const currentWeek =
    (values && values.length && values[values.length - 1] && values[values.length - 1].count) || 0;
  const prevWeek =
    (values && values.length && values[values.length - 2] && values[values.length - 2].count) || 0;
  const percentChange = Math.floor((currentWeek / prevWeek) * 100 - 100);
  return {
    percentChange: isNaN(percentChange) ? 0 : percentChange,
    userCount,
  };
};

export default CurrentWeekCounter;
