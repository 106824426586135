import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../config/styles/colors';

import { useDispatch, useSelector } from 'react-redux';
import { getCarefeed } from '../../actions/carefeedAction';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import WeeksAndArticles from './WeeksAndArticles';
import PregnancyCalendar from './PregnancyCalendar';
import { StickyTable } from './Carefeed.styles';
import ParentCalendar from './ParentCalendar';
import { PREGNANCY_MODULE, PARENT_MODULE } from '../../config/constants/constants';
import lang from '../../translationWrapper';
import analytics, { Events } from '../../services/analytics/Analytics';

const HomeArticles = () => {
  let { module } = useParams();
  const dispatch = useDispatch();
  const mapStateToProps = module => ({ sanity: { carefeed } = {} }) => {
    const carefeedModule = carefeed[module];
    const { weeks, articles, weekTo, weekFrom, month } = carefeedModule;
    const weeksForCalendar =
      module === PREGNANCY_MODULE
        ? Object.keys(weeks).filter(weekNr => weekNr <= weekTo)
        : Object.keys(weeks).filter(weekNr => weekNr >= weekFrom && weekNr <= weekTo);

    return {
      month,
      weekTo,
      weeks: weeksForCalendar.map(weekNr => ({
        weekNr: Number(weekNr),
        items: weeks[weekNr].map(id => articles[id]),
      })),
    };
  };
  const [selectedWeek, selectWeek] = useState(1);
  const [selectedMonth, selectMonth] = useState(0);
  const { weeks, weekTo, month } = useSelector(mapStateToProps(module));
  useEffect(() => {
    if (module) {
      dispatch(getCarefeed(module));
    }
  }, [module, dispatch]);

  useEffect(() => {
    const EVENTS = {
      [PREGNANCY_MODULE]: Events.PREGGUIDE,
      [PARENT_MODULE]: Events.PARENTGUIDE,
    };
    if (module) {
      analytics.logEvent(EVENTS[module]);
    }
  }, [module]);

  useEffect(() => {
    if (weeks.length || month) {
      dispatch(getCarefeed(module));
    }
  }, [weekTo]);

  return (
    <ContainerWrapper maxWidth="xl">
      <Grid container justify="center" spacing={10}>
        <Grid item xs={12} lg={6} xl={5}>
          <StickyTable>
            <h1>
              {module === PREGNANCY_MODULE
                ? lang.carefeed.pregnantHeader
                : lang.carefeed.parentHeader}
            </h1>
            {module === PREGNANCY_MODULE ? (
              <PregnancyCalendar
                module={module}
                selectedWeek={selectedWeek}
                selectWeek={selectWeek}
              />
            ) : (
              <ParentCalendar
                module={module}
                selectedMonth={selectedMonth}
                selectMonth={selectMonth}
              />
            )}
          </StickyTable>
        </Grid>
        <Grid item xs={12} lg={5}>
          <WeeksAndArticles
            module={module}
            weeks={weeks}
            month={month}
            selectedMonth={selectedMonth}
            selectedWeek={selectedWeek}
          />
        </Grid>
      </Grid>
    </ContainerWrapper>
  );
};

const ContainerWrapper = styled(Container)`
  letter-spacing: 0px;
  color: ${colors.text};
`;

export default HomeArticles;
