import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledErrorLabel = styled.div`
  margin-bottom: ${props => props.className ? '10px' : 'initial'};
`;

const ErrorLabel = ({ message, className = null }) =>
  <StyledErrorLabel className={className}>
    <label className={className || "ui red basic label"}>
      {message}
    </label>
  </StyledErrorLabel>

ErrorLabel.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
};

export default ErrorLabel;
