import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getArticleContent } from '../../actions/articlesAction';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  updateArticle,
  publishArticle,
  addNewArticle,
  deSelectArticle,
  resetClusterPills,
  setArticleId,
} from '../../actions/articlesAction';
import { getValuesNewArticle, getValuesFromSelctedArticle } from './articleData/valueConvertions';
import { getLang } from '../../translationWrapper';
import ArticleButtons from './ArticleButtons';
import lang from '../../translationWrapper';
import ArticleList from '../../components/articleList/ArticleList';
import ArticleTextAndInput from './ArticleTextAndInput';
import ClusterPills from './ClusterPills';
import analytics, { Events } from '../../services/analytics/Analytics';
import 'react-quill/dist/quill.snow.css';
import contentParser from './articleData/contentParser';
import { convertCarefeedItemToOptions } from './articleData/carefeedItems';

const Article = ({
  portalCollabSlug,
  selectedArticle,
  selectedArticleId,
  getArticleContent,
  clusterPills,
  update,
  publish,
  isFetching,
  editableArticle,
  deSelectArticle,
  resetClusterPills,
  setArticleId,
  hasAccessToEditArticle,
}) => {
  let history = useHistory();
  const { id } = useParams();
  const location = useLocation();
  const [editing, setEditing] = useState(false);
  const [blockValues, setBlockValues] = useState({});
  const [values, setValues] = useState(null);
  const [prevArticle, setPrevArticle] = useState(null);
  const selectedArticleValues = useMemo(
    () => getValuesFromSelctedArticle(selectedArticle),
    [selectedArticle]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      if (prevArticle && selectedArticle && prevArticle === selectedArticle._id) {
        setBlockValues({});
        setValues(null);
        setPrevArticle(null);
        setEditing(false);
        deSelectArticle();
        resetClusterPills();
      }
    };
  }, [deSelectArticle, resetClusterPills, location, prevArticle, selectedArticle]);

  useEffect(() => {
    /*
    This suppport the 4 use this view have. 
    First 2 are connected to the url ../articles/create
    Last 2 are connected to the url ../articles/:articleId
    ////////////////// articles/create /////////////////
    // 1. Created new article => now gotten a draft ID
    // 2. First time opened an article page
    ////////////////// articles/:articleId /////////////
    // 3. Selecting article based on url param articleId
    // 4. Article has loaded id from selectedArticle => now setting values
    ////////////////////////////////////////////////////
    */
    if (~location.pathname.indexOf('/create')) {
      if (selectedArticle && selectedArticle._id) {
        // 1. Created new article => now gotten a draft
        history.push(`/${portalCollabSlug}/article/${selectedArticle._id}`);
      } else if (!values && !prevArticle) {
        // 2. First time opened an article page
        setEditing(true);
        setValues(getValuesNewArticle());
      }
    } else {
      if (!selectedArticle && !values && !prevArticle && id) {
        // 3. Selecting article based on url param articleId
        getArticleContent(id);
      } else if (selectedArticle && !prevArticle && selectedArticle._id && !values) {
        // 4. Article has loaded id from selectedArticle => now setting values
        setValues(selectedArticleValues);
        setPrevArticle(selectedArticle._id);
      } else if (selectedArticleId && values && selectedArticleId !== values._id) {
        // 5. Set article value if currenct selectedArticleId has been changed
        setValues(selectedArticleValues);
      }
    }
  }, [
    selectedArticleValues,
    location,
    selectedArticleId,
    prevArticle,
    getArticleContent,
    history,
    id,
    portalCollabSlug,
    values,
    selectedArticle,
  ]);

  const onChangeBlock = (name, blocks) => {
    setBlockValues(prevState => ({
      ...prevState,
      [name]: blocks,
    }));
  };

  const onChangeCarefeedItems = ({ patch, index, remove, add }) => {
    if (patch) {
      const { name, value } = patch;
      setValues(prevState => ({
        ...prevState,
        carefeedItems: prevState.carefeedItems.map((carefeedItem, prevStateIndex) => {
          if (prevStateIndex === index) {
            if (carefeedItem.values.hasOwnProperty(name)) {
              carefeedItem.values[name] = value;
            } else if (carefeedItem.hasOwnProperty(name)) {
              carefeedItem[name] = value;
            }
          }
          return carefeedItem;
        }),
      }));
    } else if (remove) {
      setValues(prevState => ({
        ...prevState,
        carefeedItems:
          prevState.carefeedItems.length > 1
            ? [
                ...prevState.carefeedItems.filter(
                  (item, prevStateIndex) => prevStateIndex !== index
                ),
              ]
            : [...convertCarefeedItemToOptions({})],
      }));
    } else if (add) {
      setValues(prevState => ({
        ...prevState,
        carefeedItems: [...prevState.carefeedItems, ...convertCarefeedItemToOptions({})],
      }));
    }
  };

  const onSubmit = data => {
    const articleContent = contentParser({
      article: selectedArticle,
      values,
      formValues: data,
      blockValues,
      setValues,
    });
    update(
      selectedArticle && selectedArticle._id,
      articleContent,
      selectedArticle && selectedArticle.title,
      setEditing
    );
  };

  const abort = e => {
    e.preventDefault();
    setEditing(false);
    if (location.pathname.includes('/create')) {
      history.goBack();
    }
    let items = convertCarefeedItemToOptions(selectedArticle.carefeedItems);
    setValues(prevState => ({ ...prevState, carefeedItems: items }));
  };

  return (
    <Wrapper>
      <div className="aside aside-left">
        {hasAccessToEditArticle && (editableArticle || isFetching) && (
          <ArticleButtons
            isDraft={selectedArticle && selectedArticle.draft}
            editing={editing}
            setEditing={setEditing}
            publish={publish}
          />
        )}
      </div>
      <Main className="main">
        {clusterPills && !!Object.keys(clusterPills).length && (
          <ClusterPills
            setArticleId={setArticleId}
            selectedArticleId={selectedArticleId}
            clusterPills={clusterPills}
          />
        )}
        <ArticleTextAndInput
          abort={abort}
          values={values}
          source={selectedArticle && selectedArticle.sources}
          editing={editing}
          isFetching={isFetching}
          onChangeBlock={onChangeBlock}
          onChangeCarefeedItems={onChangeCarefeedItems}
          changeMode={setEditing}
          onSubmit={onSubmit}
        />
      </Main>
      <div className="aside aside-right">
        {editableArticle && values && (
          <div className="preview">
            <label>{lang.articleEdit.preview}</label>
            <ArticleList articles={[selectedArticleValues]} clickable={false} />
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Main = styled.div`
  max-width: 670px;
  @media all and (min-width: 600px) {
    .main {
      max-width: 670px;
    }
  }
  @media all and (min-width: 870px) {
    .main {
      margin-left: 100px;
      margin-right: 100px;
    }
  }
  @media all and (min-width: 1500px) {
    .main {
      flex: 3 0px;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;

  > * {
    padding: 10px;
    flex: 1 100%;
  }

  .preview {
    background: #f4f6fa 0% 0% no-repeat padding-box;
    border-radius: 8px;
    padding: 20px;
    margin: 10px;
    label {
      text-align: left;
      font: normal normal medium 16px/19px Lato;
      letter-spacing: 0px;
      color: #3b3d44;
    }
    > div {
      margin-top: 20px;
    }
  }

  @media all and (min-width: 600px) {
    .aside {
      max-width: 670px;
    }

    .aside-left {
      .float-right {
        width: 100%;
        .buttons {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }
  @media all and (min-width: 870px) {
    .aside {
      margin-left: 100px;
      margin-right: 100px;
    }
  }

  @media all and (min-width: 1500px) {
    flex-direction: row;
    .aside {
      flex: 1 0 0;
      margin: 0;
    }
    .aside-left {
      order: 1;
      display: flex;
      justify-content: flex-end;
      .float-right {
        width: 210px;
        margin-right: 85px;
        .buttons {
          display: flex;
          flex-direction: column;
          position: fixed;
          top: 300px;
        }
      }
    }
    .main {
      order: 2;
      margin: 0;
    }
    .aside-right {
      order: 3;

      .preview {
        position: fixed;
        top: 290px;
      }
    }
  }
`;

const mapStateToProps = ({
  sanity: {
    articles: {
      isFetching,
      content,
      selectedArticleId,
      editableArticle,
      updateCycle,
      clusterPills,
    },
  },
  userInformation: { portalCollabSlug, portalAccess: { super_admin, admin, edit } = {} },
}) => ({
  clusterPills,
  selectedArticle: selectedArticleId && content[selectedArticleId],
  editableArticle,
  content,
  portalCollabSlug,
  hasAccessToEditArticle: super_admin || admin || edit,
  updateCycle,
  selectedArticleId,
  isFetching,
});

const mergeProps = (
  {
    editableArticle,
    selectedArticle,
    selectedArticleId,
    portalCollabSlug,
    updateCycle,
    isFetching,
    hasAccessToEditArticle,
    clusterPills,
  },
  {
    updateArticle,
    publishArticle,
    addNewArticle,
    getArticleContent,
    deSelectArticle,
    resetClusterPills,
    setArticleId,
  }
) => {
  return {
    portalCollabSlug,
    selectedArticleId,
    clusterPills,
    isFetching,
    hasAccessToEditArticle,
    editableArticle,
    updateCycle,
    selectedArticle,
    update: (id, doc, title, setEditing) => {
      if (id) {
        analytics.logEvent(Events.ARTICLE_EDITOLD, { title });
        updateArticle(selectedArticle._id, doc, setEditing);
      } else {
        analytics.logEvent(Events.ARTICLE_CREATENEW, { title: doc.title[getLang()] });
        addNewArticle(doc, setEditing);
      }
    },
    deSelectArticle,
    resetClusterPills,
    setArticleId,
    publish: () => {
      const title = selectedArticle && selectedArticle.title;
      if (selectedArticle._createdAt) {
        analytics.logEvent(Events.ARTICLE_PUBLISHOLD, { title });
      } else {
        analytics.logEvent(Events.ARTICLE_PUBLISHNEW, { title });
      }
      publishArticle(selectedArticle._id);
    },
    getArticleContent,
  };
};

export default connect(
  mapStateToProps,
  {
    updateArticle,
    publishArticle,
    addNewArticle,
    getArticleContent,
    deSelectArticle,
    resetClusterPills,
    setArticleId,
  },
  mergeProps
)(Article);
