import React from 'react';
import styled from 'styled-components';
import lang from '../../translationWrapper';
import colors from '../../config/styles/colors';

const TableHeaders = () => {
  return (
    <Wrapper className="middle aligned six column row">
      <div className="column">{lang.portal.name}</div>
      <div className="column">{lang.portal.surName}</div>
      <div className="column">{lang.portal.phoneNumber}</div>
      <div className="column">
        {lang.portal.admin}
        <div className="ui icon button" data-tooltip={lang.portal.admini} data-position="top left">
          <i className="info icon" />
        </div>
      </div>
      <div className="column">
        {lang.portal.editor}
        <div className="ui icon button" data-tooltip={lang.portal.editori} data-position="top left">
          <i className="info icon" />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .column .button {
    margin-left: 10px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid ${colors.darkMainColor};
    width: 20px;
    height: 20px;
    padding: 1px;

    i {
      font-size: 10px;
      color: ${colors.darkMainColor};
    }
  }
`;

export default TableHeaders;
