import React from 'react';
import { useSelector } from 'react-redux';
import { getValuesFromSelctedArticle } from '../article/articleData/valueConvertions';
import ArticleTextAndInput from '../article/ArticleTextAndInput';
import { TYPE_ARTICLE } from '../../config/constants/constants';

const HandbookArticle = () => {
  const mapStateToProps = ({ foldersAndArticles, sanity: { articles: { content } = {} } = {} }) => {
    const articleId = foldersAndArticles[TYPE_ARTICLE];

    return articleId && content[articleId] ? getValuesFromSelctedArticle(content[articleId]) : null;
  };

  const articleMapStateToProps = ({
    sanity: {
      articles: { content, selectedArticleId },
    },
  }) => selectedArticleId && content[selectedArticleId];

  const articleValues = useSelector(mapStateToProps);
  const selectedArticle = useSelector(articleMapStateToProps);

  return articleValues ? (
    <ArticleTextAndInput
      source={selectedArticle && selectedArticle.sources}
      values={articleValues}
    />
  ) : null;
};

export default HandbookArticle;
