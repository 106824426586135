import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import lang from '../../translationWrapper';
import colors from '../../config/styles/colors';
import fonts from '../../config/styles/fonts';
import ArticleSourceItem from './ArticleSourceItem';

const ArticleSourceItems = ({ source, editing }) => {
  return !editing && source && source.length ? (
    <ArticleSourceItemsWrapper>
      <SourceTitle>{lang.article.source.toUpperCase()}</SourceTitle>
      <div>
        {source.map(({ _id, name, sourceUrl, image, url }, index) => (
          <ArticleSourceItem
            sourceUrl={sourceUrl || url}
            image={image}
            key={_id}
            name={name}
            hasComma={index + 1 < source.length}
          />
        ))}
      </div>
    </ArticleSourceItemsWrapper>
  ) : null;
};

ArticleSourceItems.propTypes = {
  editing: PropTypes.bool,
  source: PropTypes.arrayOf(PropTypes.shape()),
};

export default ArticleSourceItems;

const ArticleSourceItemsWrapper = styled.div`
  margin-bottom: 20px;
`;

const SourceTitle = styled.h3`
  color: ${colors.lightGrey};
  ${fonts.lato(12, 400, 14)};
  margin-bottom: 0;
`;
