import { handleActions } from 'redux-actions';
import { saveErrorOnAddOfPhoneNumber } from '../actions/adminActions';

const initialState = null;

export default handleActions(
  {
    [saveErrorOnAddOfPhoneNumber]: (state, { payload }) => payload
  },
  initialState
);
