import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HandbookSearch from './HandbookSearch';
import HandbookSearchResults from './HandbookSearchResults';
import HandbookFolders from './HandbookFolders';
import { getArticlesInHandbookBySearch } from '../../actions/handbookActions';
import { getHandbookQuery } from './Handbook.helpers';
import colors from '../../config/styles/colors';
import analytics, { Events } from '../../services/analytics/Analytics';

const HandbookSearchAndFolders = ({ pathname, urlSearch }) => {
  const dispatch = useDispatch();
  const { search } = getHandbookQuery(urlSearch);
  const [searchText, onTextChange] = useState(search ? search : "");

  useEffect(() => {
    if (search) {
      dispatch(getArticlesInHandbookBySearch(search));
      analytics.logEvent(Events.HANDBOOK_SEARCHTERM, {term: search });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return <div>
    <HandbookSearch
      pathname={pathname}
      searchText={searchText}
      onTextChange={onTextChange} />
    <HandbookFoldersAndResultsWrapper>
      {
        searchText ?
          <HandbookSearchResults search={search} pathname={pathname} searchText={searchText} /> :
          <HandbookFolders search={search} pathname={pathname} id={0} />
      }
    </HandbookFoldersAndResultsWrapper>
  </div>;
};

HandbookSearchAndFolders.propTypes = {
  pathname: PropTypes.string,
  urlSearch: PropTypes.string,
};

const HandbookFoldersAndResultsWrapper = styled.div`
  min-height: 350px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${colors.lighterBlue};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export default HandbookSearchAndFolders;
