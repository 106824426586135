import React from 'react';
import { show } from 'redux-modal';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '../../components';
import { ADD_PHONE_NUMBER_TO_PORTAL_ACCESS_MODAL } from '../../config/constants/constants';
import lang from '../../translationWrapper';

const AddUserButton = ({ isDisabledButton }) => {
  const dispatch = useDispatch();
  return (
    <div className="ui secondary menu">
      <div className="right menu">
        <Button
          className="ui blue button"
          disabled={isDisabledButton}
          onClick={() => dispatch(show(ADD_PHONE_NUMBER_TO_PORTAL_ACCESS_MODAL))}
        >
          {lang.portal.addPhoneNumber}
        </Button>
      </div>
    </div>
  );
};

AddUserButton.propTypes = {
  isDisabledButton: PropTypes.bool,
};

export default AddUserButton;
