export default {
  text: '#3b3d44',
  hoverText: '#3B3D4499',
  white: '#FFF',
  darkMainColor: '#285888', // dark blue
  darkBlueColor: '#1E4368',
  backgroundSurface: 'rgb(202, 221, 241)', // light blue,
  chartBackgroundColor: 'rgba(147, 186, 225, 0.2)',
  chartPorderColor: '#285888',
  chartPointBorderColor: '#285888',
  chartPointBackgroundColor: '#FFF',
  chartToolTipBackgroundColor: 'rgb(40, 88, 136)',
  chartToolTipLabelTextColor: '#FFF',
  chartGridLines: 'rgba(40, 88, 136, 0.1)',
  lighterBlue: 'rgba(244, 246, 250, 1)',
  grey: 'rgba(59, 61, 68, 0.6)',
  lightGrey: 'rgba(59, 61, 68, 0.2)',
  lightBlue: 'rgb(222, 231, 241)',
  lightBlue200: '#CADDF1',
  blue: 'rgb(40, 88, 136)',
  yellowBackground: 'rgb(255, 244, 229)',
  warning: '#E53939',
};
