import { handleActions } from 'redux-actions';
import { startLoad, endLoad } from '../actions/loaderActions';

const initialState = false;

export default handleActions(
  {
    [startLoad]: () => true,
    [endLoad]: () => false,
  },
  initialState
);
