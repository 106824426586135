import { handleActions } from 'redux-actions';
import {
  setUserCount,
  setMostReadArticles,
  setCountByWeeks,
  setStats,
} from '../actions/statsAction';

const initialState = {
  userCount: null,
  mostReadArticles: [],
  countByWeek: {},
};

export default handleActions(
  {
    [setUserCount]: (state, { payload }) => ({
      ...state,
      userCount: payload,
    }),
    [setMostReadArticles]: (state, { payload }) => ({
      ...state,
      mostReadArticles: payload,
    }),
    [setCountByWeeks]: (state, { payload }) => ({
      ...state,
      countByWeek: payload,
    }),
    [setStats]: (state, { payload: { userCount, countByWeek, mostRead } }) => ({
      ...state,
      userCount,
      countByWeek,
      mostReadArticles: mostRead,
    }),
  },
  initialState
);
