import { css } from 'styled-components'

const fontStyles = (size = 14, weight = 400, height) => css`
  font-size: ${size}px;
  font-weight: ${weight};
  ${height && css`
    line-height: ${height}px;
  `}
`

const lato = (size, weight, height) => css`
  ${fontStyles(size, weight, height)};
  font-family: "lato";
`
export default { lato };
