import styled from 'styled-components';

export default styled.input`
  font: normal normal bold 24px/36px Lato;
  margin-top: 10px;
  height: 40px;
  width: 100%;
  border: 1px solid #3b3d4433;
  border-radius: 4px;
`;
