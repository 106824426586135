import { createAction } from 'redux-actions';
import { hide } from 'redux-modal';
import rest from '../services/helseoversikt/rest';
import { ADD_PHONE_NUMBER_TO_PORTAL_ACCESS_MODAL } from '../config/constants/constants';
import analytics, { Events } from '../services/analytics/Analytics';

export const selectEditUserInPortalCollab = createAction('ADMIN_PANEL/SELECT_EDIT_USERR');
export const selectPortalCollab = createAction('ADMIN_PANEL/SELECT_PORTAL_COLLAB');
export const setPortalCollabs = createAction('ADMIN_PANEL/SET_PORTAL_COLLABS', portalCollabs => ({
  portalCollabsIds: portalCollabs.map(({ _id }) => _id),
  portalCollabsData: portalCollabs.reduce((obj, ecms) => ({ ...obj, [ecms._id]: ecms }), {}),
}));
export const savePhoneNumbersFromPortalCollab = createAction(
  'ADMIN_PANEL/SAVE_PHONE_NUMBERS_FROM_PORTAL_COLLAB',
  ({ phoneNumbers, id }) => ({
    [id]: {
      phoneNumbersId: phoneNumbers.map(({ id }) => id),
      phoneNumbers: phoneNumbers.reduce(
        (obj, phoneNumber) => ({ ...obj, [phoneNumber.id]: phoneNumber }),
        {}
      ),
    },
  })
);
export const saveErrorOnLoadOfPhoneNumbersFromPortalCollab = createAction(
  'ADMIN_PANEL/SAVE_ERROR_ON_LOAD_OF_PHONE_NUMBERS'
);
export const savePhoneNumberToPortalCollab = createAction(
  'ADMIN_PANEL/SAVE_PHONE_NUMBER_TO_PORTAL_COLLAB'
);
export const saveErrorOnAddOfPhoneNumber = createAction(
  'ADMIN_PANEL/ADD_ERROR_ON_ADD_OF_PHONE_NUMBER_TO_PORTAL_COLLAB'
);
export const removePhoneNumberFromPortalCollab = createAction(
  'ADMIN_PANEL/REMOVE_PHONE_NUMBER_FROM_PORTAL_COLLAB'
);
export const updatePhoneNumberForPortalCollab = createAction(
  'ADMIN_PANEL/UPDATE_PHONE_NUMBER_FOR_PORTAL_COLLAB'
);

export const loadPortalCollabs = () => (dispatch, getState) => {
  const { sanity: { portalCollabs: { portalCollabsIds } = {} } = {} } = getState();

  portalCollabsIds &&
    portalCollabsIds.length === 0 &&
    rest.getPortalCollabs().then(({ items }) => {
      dispatch(setPortalCollabs(items));
    });
};

export const addPhoneNumberToPortalCollab = ({ name, surname, phoneNumber, admin, edit }) => (
  dispatch,
  getState
) => {
  const { sanity: { portalCollabs: { selectedPortalCollab } = {} } = {} } = getState();

  dispatch(saveErrorOnAddOfPhoneNumber(null));
  rest
    .addPhoneNumberToPortalCollab({
      name,
      surname,
      phoneNumber,
      admin,
      edit,
      urlKeys: {
        portalCollab: selectedPortalCollab,
      },
    })
    .then(data => {
      dispatch(savePhoneNumberToPortalCollab(data));
      dispatch(hide(ADD_PHONE_NUMBER_TO_PORTAL_ACCESS_MODAL));
      analytics.logEvent(Events.ADMIN_ADDNUMBER, { phoneNumber });
    })
    .catch(err => dispatch(saveErrorOnAddOfPhoneNumber(err.response.status)));
};

export const loadListOfPhoneNumbers = id => (dispatch, getState) => {
  const { adminPanelPhoneNumbers } = getState();

  !adminPanelPhoneNumbers[id] &&
    rest
      .getListOfPhoneNumbersForPortalCollab({ urlKeys: { portalCollab: id } })
      .then(({ phoneNumbers }) => {
        dispatch(savePhoneNumbersFromPortalCollab({ phoneNumbers: phoneNumbers || [], id }));
      })
      .catch(err =>
        dispatch(
          saveErrorOnLoadOfPhoneNumbersFromPortalCollab({ id, errStatus: err.response.status })
        )
      );
};

export const deletePhoneNumberFromPortalCollab = id => (dispatch, getState) => {
  const { sanity: { portalCollabs: { selectedPortalCollab } = {} } = {} } = getState();
  rest
    .deletePhoneNumberFromPortalCollab({ urlKeys: { id, portalCollab: selectedPortalCollab } })
    .then(() => dispatch(removePhoneNumberFromPortalCollab({ id, selectedPortalCollab })));
};

export const editPhoneNumberForPortalCollab = ({
  hideModal,
  portalCollab,
  endSuccessAction,
  ...data
}) => dispatch => {
  rest.updatePhoneNumberForPortalCollab({ ...data, urlKeys: { portalCollab } }).then(data => {
    dispatch(updatePhoneNumberForPortalCollab(data));
    dispatch(selectEditUserInPortalCollab(null));
    endSuccessAction && endSuccessAction();
    hideModal && dispatch(hide(ADD_PHONE_NUMBER_TO_PORTAL_ACCESS_MODAL));
  });
};
