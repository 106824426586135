import { PREGNANCY_WEEKS_LIMIT } from '../../config/constants/constants';
import lang from '../../translationWrapper';
import colors from '../../config/styles/colors';

export const PREGNANCY_WEEKS = new Array(6)
  .fill(0)
  .map((item, index) => new Array(8)
    .fill(0)
    .map((item, secondIndex) => {
      const weekNr = index * 8 + secondIndex + 1;
      return weekNr <= PREGNANCY_WEEKS_LIMIT ? weekNr : null;
    })
  );

const PARENT_MONTHS = new Array(12)
  .fill(0)
  .map((item, index) => index);

export const PARENT_YEARS = new Array(6)
  .fill(0)
  .map((item, index) => ({
    year: index,
    months: [...PARENT_MONTHS].map(monthNumber => ({
      monthNumber,
      childFullMonths: monthNumber + index * 12,
    })),
  }));

export const PARENT_CALENDAR_SUBHEADER = [{
  colSpan: "1",
  title: lang.carefeed.age,
  backgroundColor: colors.darkBlueColor,
},
{
  colSpan: "12",
  title: lang.carefeed.months,
}];
