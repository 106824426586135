import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import {
  setActivePortalCollab,
  setActivePortalCollabSlug,
  setActivePortalCollabName,
  setActivePortalCollabEndorsement,
} from '../../actions/userAction';

const PrivateRoute = ({ children, component: RouteComponent, inited, redirectTo, ...rest }) => {
  return (
    <Route
      {...rest}
      exact
      render={({ location }) =>
        inited ? (
          redirectTo ? (
            <Redirect
              to={{
                pathname: redirectTo,
                state: { from: location },
              }}
            />
          ) : (
            (RouteComponent && <RouteComponent />) || children
          )
        ) : (
          <div />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  portal: PropTypes.bool,
  component: PropTypes.elementType,
  withSpecialAccess: PropTypes.bool,
  superAdmin: PropTypes.bool,
};

const mapStateToProps = (
  {
    user: { uid, inited } = {},
    userInformation: {
      portalAccess: { admin, super_admin, portalConnections } = {},
      portalCollab,
      portalCollabSlug,
      portals,
    } = {},
  },
  {
    location: { pathname } = {},
    path,
    withSpecialAccess = false,
    portal = false,
    superAdmin = false,
  }
) => {
  const loggedIn = !!uid;
  let redirectTo = null;
  let portalCollabName = null;
  let portalCollabId = null;
  let urlSlugParam = null;
  let portalCollabEndorsement = null;
  if (
    loggedIn &&
    ((withSpecialAccess && !(admin || super_admin)) || (superAdmin && !super_admin))
  ) {
    redirectTo = '/not-found';
  } else if (loggedIn) {
    if (portal) {
      const portalCount = portalConnections ? Object.values(portalConnections).length : 0;
      if (!path) {
        if (super_admin || portalCount > 1) {
          redirectTo = '/portals';
        } else if (portalCount === 1) {
          portalCollabId = Object.values(portalConnections)[0].portalCollab;
          portalCollabName = Object.values(portalConnections)[0].name;
          urlSlugParam = Object.values(portalConnections)[0].slug;

          redirectTo = `/${urlSlugParam}/home`;
        } else {
          redirectTo = `/not-found`;
        }
      } else {
        const params = pathname.split('/');
        if (
          portals &&
          Object.values(portals).filter(
            portal => portal.slug === params[1] || portal._id === params[1]
          ).length === 1
        ) {
          urlSlugParam = params[1];
          const currentPortalCollab = Object.values(portals).find(
            portal => portal.slug === urlSlugParam
          );

          portalCollabId = currentPortalCollab && currentPortalCollab._id;
          portalCollabName = currentPortalCollab && currentPortalCollab.name;
          portalCollabEndorsement = currentPortalCollab && currentPortalCollab.endorsement;
        } else {
          redirectTo = '/not-found';
        }
      }
    }
  } else {
    redirectTo = '/login';
  }
  if (redirectTo === path) {
    redirectTo = null;
  }

  return {
    inited,
    redirectTo,
    portalCollabId,
    portalCollabName,
    portalCollab,
    portalCollabSlug,
    urlSlugParam,
    portalCollabEndorsement,
  };
};

const mergeProps = (
  {
    inited,
    redirectTo,
    portalCollabId,
    portalCollabName,
    portalCollab,
    urlSlugParam,
    portalCollabSlug,
    portalCollabEndorsement,
  },
  {
    setActivePortalCollab,
    setActivePortalCollabSlug,
    setActivePortalCollabName,
    setActivePortalCollabEndorsement,
  },
  ownProps
) => {
  !portalCollab && portalCollabId && setActivePortalCollab(portalCollabId);
  portalCollabName && setActivePortalCollabName(portalCollabName);
  setActivePortalCollabEndorsement(portalCollabEndorsement);

  !portalCollabSlug && urlSlugParam && setActivePortalCollabSlug(urlSlugParam);
  return {
    inited,
    redirectTo,
    ...ownProps,
  };
};

export default connect(
  mapStateToProps,
  {
    setActivePortalCollab,
    setActivePortalCollabSlug,
    setActivePortalCollabName,
    setActivePortalCollabEndorsement,
  },
  mergeProps
)(PrivateRoute);
