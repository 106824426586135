import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sanityImage } from '../../services/sanity/sanityClient';
import { setActivePortalCollab, setActivePortalCollabSlug } from '../../actions/userAction';
import lang from '../../translationWrapper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const ChoosePortal = () => {
  const mapStateToProps = ({ sanity: { portalCollabs: { portalCollabsData } = {} } = {} }) =>
    Object.values(portalCollabsData);
  const dispatch = useDispatch();
  const portalCollabs = useSelector(mapStateToProps);
  let history = useHistory();
  const loadPortal = ({ id, slug }) => {
    // Use for url id if slug is missing
    const urlSlug = slug || id;
    dispatch(setActivePortalCollab(id));
    dispatch(setActivePortalCollabSlug(urlSlug));
    history.push(`/${urlSlug}/home`);
  };

  return (
    <ContainerWrapper maxWidth="lg">
      <Header align="left" variant="h4">
        {lang.portal.choosePortal}
      </Header>
      <Grid container direction="column">
        {portalCollabs &&
          portalCollabs.length &&
          portalCollabs.map(({ name, logo, _id, slug }) => (
            <PortalItem
              xs={12}
              lg={6}
              alignItems="center"
              direction="row"
              key={_id}
              onClick={() => loadPortal({ id: _id, slug })}
            >
              {logo && logo.asset && (
                <PortalImage
                  alt={lang.alt.portal}
                  src={sanityImage(logo.asset).size(100, 100).crop('center').format('png').url()}
                />
              )}
              <PortalTitle className="header">{name}</PortalTitle>
            </PortalItem>
          ))}
      </Grid>
    </ContainerWrapper>
  );
};

export default ChoosePortal;

const ContainerWrapper = styled(Container)`
  letter-spacing: 0px;
  color: #3b3d44;
`;

const Header = styled(Typography)`
  font-size: 28px;
  font-weight: 900;
`;

const PortalTitle = styled(Typography)`
  font-size: 20px;
`;

const PortalItem = styled(Grid)`
  display: flex;
  cursor: pointer;
  padding: 48px 0 25px;
  border-bottom: 1px solid #3b3d4433;
  &:last-child {
    border-bottom: none;
  }
`;

const PortalImage = styled.img`
  height: 37px;
  width: 37px;
  margin-right: 28px;
`;
