import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import { Link } from 'react-router-dom';
import icons from '../../../assets/img';
import lang from '../../../translationWrapper';
import MobileViewDrawer from './MobileViewDrawer';

const MobileNavigation = ({ portalCollab, hasMorePortals }) => {
  const goToPortalUrl = useMemo(() => {
    if (hasMorePortals) {
      return '/portals';
    } else if (portalCollab) {
      return `/${portalCollab.slug}/home`;
    }
  }, [hasMorePortals, portalCollab]);

  return (
    <NavAppBar>
      <StyledToolbar style={{ height: 53 }}>
        <LogoWrapper>
          <NavLink to={portalCollab ? `/${portalCollab.slug}/home` : '/portals'}>
            <img src={icons.logo} height="45" width="45" alt={lang.alt.logo} />
          </NavLink>
          <PortalLink to={goToPortalUrl}>
            {portalCollab ? portalCollab.name : lang.nav.choosePortal}
          </PortalLink>
        </LogoWrapper>
        <MobileViewDrawer />
      </StyledToolbar>
    </NavAppBar>
  );
};

const mapStateToProps = ({
  userInformation: { portalCollab } = {},
  sanity: { portalCollabs: { portalCollabsData } = {} } = {},
}) => ({
  portalCollab: portalCollabsData && portalCollabsData[portalCollab],
  hasMorePortals: portalCollabsData && Object.keys(portalCollabsData).length > 1,
});

export default connect(mapStateToProps)(MobileNavigation);

const NavAppBar = styled(AppBar)`
  display: flex;
  height: 63px;
  min-height: inherit;
  background-color: #f4f6fa;
`;

const StyledToolbar = styled(Toolbar)`
  ${({ theme }) => `
  justify-content: space-between;
  ${theme.breakpoints.down('md')} {
    min-height: 63px;
  }
  `}
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 16px;
  height: 100%;
  color: #3b3d44;
  &:hover {
    color: #3b3d4499;
  }
`;

const PortalLink = styled(NavLink)`
  padding: 0 5px;
`;
