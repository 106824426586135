import React from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';
import getYouTubeId from 'get-youtube-id';
import lang from '../../translationWrapper';
import { useYoutubeUrlValidation } from '../../hooks';
import { Label } from 'semantic-ui-react';
import Description from '../../components/label/Label';
import styled, { css } from 'styled-components';

const MainVideoTextInput = ({
  value,
  name,
  editing,
  register,
  registerOptions,
  validate,
  rules,
}) => {
  const id = getYouTubeId(value);
  const validation = useYoutubeUrlValidation(validate, rules, value);

  return (
    <Container isHidden={!(value || editing)}>
      {editing ? (
        <div>
          <Description>
            {lang.articleEdit[`fieldName_${name}`] ? lang.articleEdit[`fieldName_${name}`] : name}
          </Description>
          <Input type="text" {...register(name, registerOptions)} defaultValue={value} />
          {validation ? (
            <Label basic color="red" pointing>
              {validation}
            </Label>
          ) : null}
        </div>
      ) : (
        <YouTube videoId={id} id={id} />
      )}
    </Container>
  );
};

export default MainVideoTextInput;

const Container = styled.div`
  margin: 10px 0;
  ${props =>
    props.isHidden &&
    css`
      display: none;
    `}
`;

const Input = styled.input`
  font: normal normal bold 24px/36px Lato;
  margin-top: 10px;
  height: 40px;
  width: 100%;
  border: 1px solid #3b3d4433;
  border-radius: 4px;
`;

MainVideoTextInput.propTypes = {
  editing: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  validate: PropTypes.object,
  rules: PropTypes.object,
  register: PropTypes.func,
  registerOptions: PropTypes.object,
};
