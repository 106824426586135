import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '../../components'; 
import lang from '../../translationWrapper';
import { useDispatch } from 'react-redux';
import { show } from 'redux-modal';
import { DELETE_ARTICLE_MODAL } from '../../config/constants/constants';

const ArticleButtons = ({ isDraft, editing, setEditing, publish }) => {
  const dispatch = useDispatch();
  return (
    <div className="float-right">
      <ButtonsWrapper className="buttons">
        <Button
          className={`ui button primary ${isDraft && !editing ? '' : 'disabled'}`}
          onClick={() => publish()}
        >
          <i className="paper plane outline icon" />
          {lang.buttons.Publish}
        </Button>
        <Button
          className={`ui button ${editing ? 'disabled' : ''}`}
          onClick={() => setEditing(true)}
        >
          <i className="edit outline icon" />
          {lang.buttons.Edit}
        </Button>
        <Button className="ui button" onClick={() => dispatch(show(DELETE_ARTICLE_MODAL))}>
          <i className="trash icon" />
          {lang.buttons.Delete}
        </Button>
      </ButtonsWrapper>
    </div>
  );
};

const ButtonsWrapper = styled.div`
  .button {
    margin-bottom: 10px;
    width: 210px;
    height: 48px;
    background: #f4f6fa 0% 0% no-repeat padding-box;
    border-radius: 8px;
  }
  .button.primary {
    background: #285888;
  }
`;

ArticleButtons.propTypes = {
  isDraft: PropTypes.bool,
  setEditing: PropTypes.func,
  editing: PropTypes.bool,
  publish: PropTypes.func,
};

export default ArticleButtons;
