import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import colors from '../../config/styles/colors';
import fonts from '../../config/styles/fonts';
import { Row } from './Carefeed.styles';

const CalendarHeader = ({ title, subHeader = null, colSpan, backgroundColor }) => (
  <thead>
    <Row>
      <Header backgroundColor={backgroundColor} colSpan={colSpan}>
        <Title>{title}</Title>
      </Header>
    </Row>
    {subHeader ? (
      <Row>
        {subHeader.map(({ colSpan, title, backgroundColor }) => (
          <SubHeader backgroundColor={backgroundColor} colSpan={colSpan} key={title}>
            <h3>{title}</h3>
          </SubHeader>
        ))}
      </Row>
    ) : null}
  </thead>
);

CalendarHeader.propTypes = {
  title: PropTypes.string,
  subHeader: PropTypes.arrayOf(
    PropTypes.shape({
      colSpan: PropTypes.string,
      backgroundColor: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

export default CalendarHeader;

const Header = styled.th`
  padding: 20px 0;
  ${props =>
    css`
      background-color: ${props.backgroundColor
        ? props.backgroundColor
        : colors.backgroundSurface} !important;
    `};

  border-width: 0px !important;
  color: ${colors.text} !important;
`;

const SubHeader = styled.th`
  padding: 20px;
  ${props =>
    css`
      background-color: ${props.backgroundColor
        ? props.backgroundColor
        : colors.backgroundSurface} !important;
    `};

  &:first-child {
    color: ${colors.white} !important;
    border-radius: 8px 0 0 0;
  }

  &:last-child {
    border-radius: 0 8px 0 0;
  }

  border-width: 0px !important;
  color: ${colors.text} !important;
`;

const Title = styled.h3`
  text-align: center;
  text-align: left;
  ${fonts.lato(28, 900, 34)};
`;
