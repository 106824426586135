import { handleActions } from 'redux-actions';
import { selectFolderOrArticle, cleanFolderAndArticle } from '../actions/handbookActions';
import { TYPE_ARTICLE, TYPE_FOLDER } from '../config/constants/constants';

const initialState = {
  [TYPE_FOLDER]: null,
  [TYPE_ARTICLE]: null
};

export default handleActions(
  {
    [selectFolderOrArticle]: (state, { payload: { type, id } }) => ({
      ...state,
      [type]: id,
    }),
    [cleanFolderAndArticle]: state => initialState,
  },
  initialState
);
