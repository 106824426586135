import React from 'react';
import { sanityImage } from '../../services/sanity/sanityClient';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import lang from '../../translationWrapper';

const ThumbnailImage = styled.div`
  div {
    height: 54px;
    width: 80px;
    text-align: center;
    padding-top: 10px;

    i {
      font-size: 30px;
    }
  }
`;

const Thumbnail = ({ image }) => {
  return (
    <ThumbnailImage className="image">
      {image && image.asset ? (
        <img
          alt={lang.alt.article}
          src={sanityImage(image.asset).width(80).height(54).url()}
          style={{ height: 54, width: 80 }}
        />
      ) : (
        <div>
          <i className="file alternate icon" />
        </div>
      )}
    </ThumbnailImage>
  );
};

Thumbnail.propTypes = {
  image: PropTypes.shape({
    _type: PropTypes.oneOf(['image']),
    asset: PropTypes.shape({
      _ref: PropTypes.string,
      type: PropTypes.oneOf(['reference']),
    }),
  }),
};

export default Thumbnail;
