import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Thumbnail from './Thumbnail';
import NumberThumbnails from './NumberThumbnails';
import { getArticleContent } from '../../actions/articlesAction';
import colors from '../../config/styles/colors';
// import lang from '../../translationWrapper';

const ArticleItem = ({
  article: { _id, title, oneliner, mainImage, parentWeeks, pregnantWeeks } = {},
  index = 0,
  analyticsEvent,
  useNumbers = false,
  clickable = true,
}) => {
  const portalCollabSlug = useSelector(
    ({ userInformation: { portalCollabSlug } = {} }) => portalCollabSlug
  );
  const dispatch = useDispatch();
  const loadArticle = id => {
    if (clickable) {
      dispatch(getArticleContent(id));
      analyticsEvent && analyticsEvent(title);
    }
  };

  const ArtcileWrapper = clickable ? StyledLink : NoneClickableWrapper;

  return (
    <ArtcileWrapper
      to={clickable && `/${portalCollabSlug}/article/${_id}`}
      className="item"
      onClick={() => loadArticle(_id)}
    >
      {useNumbers ? <NumberThumbnails number={index + 1} /> : <Thumbnail image={mainImage} />}
      <div className="middle aligned">
        <h3>{title}</h3>
        <div className="meta">
          <span>{oneliner}</span>
        </div>
        {/* {pregnantWeeks ? (
                  <div className="extra">
                    {lang.article.pregnantWeek}: {pregnantWeeks}
                  </div>
                ) : null}
                {parentWeeks ? (
                  <div className="extra">
                    {lang.article.parentWeek}: {parentWeeks}
                  </div>
                ) : null} */}
      </div>
    </ArtcileWrapper>
  );
};

ArticleItem.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      title: PropTypes.string,
      oneliner: PropTypes.string,
      mainImage: PropTypes.shape({
        _type: PropTypes.oneOf(['image']),
        asset: PropTypes.shape({
          _ref: PropTypes.string,
          type: PropTypes.oneOf(['reference']),
        }),
      }),
      parentWeeks: PropTypes.string,
      pregnantWeeks: PropTypes.string,
    })
  ),
  analyticsEvent: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  useNumbers: PropTypes.bool,
};

export default ArticleItem;

const StyledLink = styled(Link)`
  color: ${colors.text};
  &:hover {
    color: ${colors.hoverText};
  }
`;

const NoneClickableWrapper = styled.div`
  color: ${colors.text};
`;
