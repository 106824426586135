import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    window.gtag('config', 'G-KXESVNVZ8S', {
      page_title: document.title,
      page_path: window.location.pathname + window.location.search,
    });
  }, [location]);
};

export default usePageTracking;
