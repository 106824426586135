import React from 'react';
import styled, { css } from 'styled-components';

const MobileMenuButton = ({ onClickHandler, open }) => {
  return (
    <Container onClick={onClickHandler}>
      <LineTop open={open} className="line"/>
      <LineMiddle open={open} className="line" />
      <LineBottom open={open} className="line" />
    </Container>
  );
};

const Container = styled.div`
  height: 45px;
  width: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  background: #f4f6fa;
  & .line {
    height: 4px;
    width: 30px;
    background: #295989;
    border-radius: 10px;
    transition: all 0.2s ease;
  }
`;

const LineTop = styled.div`
  margin-bottom: 5px;
  transform-origin: top left;
  ${props =>
    props.open &&
    css`
      transform: translateX(6px) translateY(-2px) rotate(45deg);
    `}
`;

const LineMiddle = styled.div`
  opacity: 1;
  transform: none;
  ${props =>
    props.open &&
    css`
      opacity: 0;
      transform: translateX(-16px);
    `}
`;

const LineBottom = styled.div`
  transform-origin: top left;
  transform: none;
  margin-top: 5px;
  ${props =>
    props.open &&
    css`
      transform: translateX(3px) translateY(1px) rotate(-45deg);
    `}
`;

export default MobileMenuButton;
