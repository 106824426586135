import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../config/styles/colors';
import { useDispatch } from 'react-redux';
import { Table, Row, Cell } from './Carefeed.styles';
import lang from '../../translationWrapper';
import CalendarCell from './CalendarCell';
import CalendarHeader from './CalendarHeader';
import { setCarefeedMonthRange } from '../../actions/carefeedAction';
import { PARENT_YEARS, PARENT_CALENDAR_SUBHEADER } from './Carefeed.constants';
import analytics, { Events } from '../../services/analytics/Analytics';
const ParentCalendar = ({ module, selectMonth, selectedMonth }) => {
  const dispatch = useDispatch();
  const mapStateToProps = module => ({ sanity: { carefeed } = {} }) => {
    const { month } = carefeed[module];

    return month;
  };

  return (
      <Table>
        <CalendarHeader
          title={lang.carefeed.childAge}
          colSpan="7"
          backgroundColor={colors.white}
          subHeader={PARENT_CALENDAR_SUBHEADER}
        />
        <tbody>
          {PARENT_YEARS.map(({ year, months }) => (
            <Fragment key={year}>
              <Row>
                <YearCell>{year}</YearCell>
                {months.map(({ monthNumber, childFullMonths }) => (
                  <CalendarCell
                    key={`${monthNumber} ${childFullMonths}`}
                    selected={childFullMonths === selectedMonth}
                    item={monthNumber}
                    onClick={() => {
                      analytics.logEvent(Events.PARENTGUIDE_CLICKMONTH);
                      dispatch(setCarefeedMonthRange({ module, month: childFullMonths }));
                      selectMonth(childFullMonths);
                    }}
                  />
                ))}
              </Row>
            </Fragment>
          ))}
        </tbody>
      </Table>
  );
};

ParentCalendar.propTypes = {
  module: PropTypes.string,
  selectedMonth: PropTypes.number,
  selectMonth: PropTypes.func,
};

export default ParentCalendar;

const YearCell = styled(Cell)`
  color: ${colors.white};
  background-color: ${colors.darkMainColor};
`;
