import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import ReactJsonPrint from 'react-json-print';
import analytics, { Events } from '../../services/analytics/Analytics';
import { useDispatch, useSelector } from 'react-redux';
import lang from '../../translationWrapper';
import { getListUsers } from '../../actions/analyticsAction';

const Stats = () => {
  const dispatch = useDispatch();
  const { json, municipalities, listUsers } = useSelector(
    ({
      analytics: {
        json,
        loadProgress: { municipalities, listUsers },
      },
    }) => ({
      json,
      municipalities,
      listUsers,
    })
  );
  useEffect(() => {
    analytics.logEvent(Events.LANDING_PAGE);
    dispatch(getListUsers());
  }, []);

  return (
    <Wrapper>
      {json ? (
        <ReactJsonPrint dataObject={json} />
      ) : (
        <div>
          <h3>{lang.analytics.loading}</h3>
          <h3>{`${lang.analytics.loadingMunicipality} ${municipalities}`}</h3>
          <h3>{`${lang.analytics.loadingUserInsight} ${listUsers}`}</h3>
        </div>
      )}
    </Wrapper>
  );
};

export default Stats;

const Wrapper = styled.div`
  padding: 20px;
`;
