import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Checkbox } from '../../components';
import ButtonsForUser from './ButtonsForUser';
import { editPhoneNumberForPortalCollab } from '../../actions/adminActions';
import colors from '../../config/styles/colors';

const UserInPortalCollab = ({ id, name, surname, phoneNumber, admin, edit, portalCollab }) => {
  const dispatch = useDispatch();
  const [adminStatus, toggleAdmin] = useState(admin);
  const [editStatus, toggleEdit] = useState(edit);

  return (
    <Wrapper className="middle aligned six column row">
      <div className="column">{name}</div>
      <div className="column">{surname}</div>
      <div className="column">{phoneNumber}</div>
      <div className="column">
        <Checkbox
          checked={adminStatus}
          name={`${id}_admin`}
          onChange={() => {
            dispatch(
              editPhoneNumberForPortalCollab({
                id,
                name,
                surname,
                phoneNumber,
                admin: !adminStatus,
                edit: editStatus,
                portalCollab,
                endSuccessAction: () => toggleAdmin(!adminStatus),
              })
            );
            
          }}
        />
      </div>
      <div className="column">
        <Checkbox
          checked={editStatus}
          name={`${id}_edit`}
          onChange={() => {
            dispatch(
              editPhoneNumberForPortalCollab({
                id,
                name,
                surname,
                phoneNumber,
                admin: adminStatus,
                edit: !editStatus,
                portalCollab,
                endSuccessAction: () => toggleEdit(!editStatus),
              })
            );
          }}
        />
      </div>
      <div className="column deletebutton">
        <div className="ui secondary menu">
          <div className="right menu">
            <ButtonsForUser
              id={id}
              name={name}
              surname={surname}
              phoneNumber={phoneNumber}
              adminStatus={adminStatus}
              editStatus={editStatus}
              portalCollab={portalCollab}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

UserInPortalCollab.propTypes = {
  admin: PropTypes.bool,
  edit: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  surname: PropTypes.string,
  phoneNumber: PropTypes.string,
  portalCollab: PropTypes.string,
};

const Wrapper = styled.div`
  border-bottom: 1px solid #3b3d4433;
  .column.deletebutton .button {
    margin-left: 32px;
  }
  .ui.icon.button.warning {
    background: ${colors.warning};

    i {
      color: #fff;
    }
  }
`;

export default UserInPortalCollab;
