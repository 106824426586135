const HELSEOVERSIKT_URL = process.env.REACT_APP_HELSEOVERSIKT_API + 'portal/';
const PORTAL_COLLAB = 'portals/:portalCollab';

export const api = {
  getUserData: {
    method: 'get',
    url: `${HELSEOVERSIKT_URL}user/getData`,
  },
  getArticlesInMunicipality: {
    method: 'get',
    url: `${HELSEOVERSIKT_URL}${PORTAL_COLLAB}/articles/list`,
  },
  getArticle: {
    method: 'get',
    url: `${HELSEOVERSIKT_URL}${PORTAL_COLLAB}/articles/:articleId`,
  },
  updateArticle: {
    method: 'post',
    url: `${HELSEOVERSIKT_URL}${PORTAL_COLLAB}/articles/:articleId`,
  },
  publishArticle: {
    method: 'get',
    url: `${HELSEOVERSIKT_URL}${PORTAL_COLLAB}/articles/:articleId/publish`,
  },
  addNewArticle: {
    method: 'post',
    url: `${HELSEOVERSIKT_URL}${PORTAL_COLLAB}/articles/create`,
  },
  deleteArticle: {
    method: 'delete',
    url: `${HELSEOVERSIKT_URL}${PORTAL_COLLAB}/articles/:articleId`,
  },
  getCarefeed: {
    method: 'get',
    url: `${HELSEOVERSIKT_URL}${PORTAL_COLLAB}/carefeed/list/:module/:weekFrom/:weekTo`,
  },
  getModules: {
    method: 'get',
    url: `${HELSEOVERSIKT_URL}sanity/modules`,
  },
  getPortalCollabs: {
    method: 'get',
    url: `${HELSEOVERSIKT_URL}admin/`,
  },
  addPhoneNumberToPortalCollab: {
    method: 'post',
    url: `${HELSEOVERSIKT_URL}${PORTAL_COLLAB}/admin/addPhoneNumber`,
  },
  deletePhoneNumberFromPortalCollab: {
    method: 'delete',
    url: `${HELSEOVERSIKT_URL}${PORTAL_COLLAB}/admin/deletePhoneNumber/:id`,
  },
  getListOfPhoneNumbersForPortalCollab: {
    method: 'post',
    url: `${HELSEOVERSIKT_URL}${PORTAL_COLLAB}/admin/getListOfPhoneNumbersForPortalCollab`,
  },
  updatePhoneNumberForPortalCollab: {
    method: 'post',
    url: `${HELSEOVERSIKT_URL}${PORTAL_COLLAB}/admin/updatePhoneNumber`,
  },
  getStats: {
    method: 'get',
    url: `${HELSEOVERSIKT_URL}${PORTAL_COLLAB}/stats/`,
  },
  listUsers: {
    method: 'get',
    url: `${HELSEOVERSIKT_URL}root/analytics/listUsers/:nextPageToken`,
  },
  debugUser: {
    method: 'get',
    url: `${HELSEOVERSIKT_URL}root/debugUser/:phoneNr`,
  },
  getMunicipalityNameAndConsentByUid: {
    method: 'get',
    url: `${HELSEOVERSIKT_URL}root/analytics/municipalityNameAndConsentByUid/:nextPageToken`,
  },
  searchArticlesInHandbook: {
    method: 'post',
    url: `${HELSEOVERSIKT_URL}${PORTAL_COLLAB}/handbook/search`,
  },
  getFoldersAndArticels: {
    method: 'get',
    url: `${HELSEOVERSIKT_URL}${PORTAL_COLLAB}/handbook/getFoldersAndArticles`,
  },
};
