import Schema from '@sanity/schema';

export default Schema.compile({
  name: 'articles',
  types: [
    {
      type: 'object',
      name: 'article',
      fields: [
        {
          title: 'Block Content',
          name: 'blockContent',
          type: 'array',
          of: [
            {
              title: 'Block',
              type: 'block',
              styles: [
                { title: 'Normal', value: 'normal' },
                { title: 'H1', value: 'h1' },
                { title: 'H2', value: 'h2' },
                { title: 'H3', value: 'h3' },
                { title: 'H4', value: 'h4' },
                { title: 'Quote', value: 'blockquote' },
                // { title: 'Hidden', value: 'blockComment' },
              ],
              lists: [
                { title: 'Bullet', value: 'bullet' },
                { title: 'Numbered', value: 'number' },
              ],
              marks: {
                decorators: [
                  { title: 'Strong', value: 'strong' },
                  { title: 'Emphasis', value: 'em' },
                  // { title: 'Code', value: 'code' },
                  // { title: 'Underline', value: 'underline' },
                  // { title: 'Strike', value: 'strike-through' },
                ],
              },
            },
            {
              title: 'Image',
              type: 'image',
              options: { hotspot: true },
            },
            // {
            //   name: 'videoUrl',
            //   title: 'Video URL',
            //   type: 'videoUrl',
            // },
            {
              name: 'podcastUrl',
              title: 'Podcast URL',
              type: 'object',
              //   icon: faIcons.FaPlayCircleO,
              fields: [
                { title: 'Title', name: 'title', type: 'string' },
                { title: 'URL', name: 'url', type: 'url' },
                { title: 'Thumbnail', name: 'thumbnail', type: 'image' },
              ],
            },
            {
              title: 'Collapsible Panel',
              name: 'collapsiblePanel',
              type: 'object',
              //   icon: faIcons.FaCompress,
              fields: [
                { title: 'Title', name: 'title', type: 'string' },
                { title: 'Summary', name: 'summary', type: 'text' },
                { title: 'Text', name: 'innerBlockContent', type: 'blockContent' },
                { title: 'Image', name: 'image', type: 'image' },
                { title: 'Video Url', name: 'videoUrl', type: 'url' },
                { title: 'Podcast Url', name: 'podcastUrl', type: 'url' },
              ],
            },
          ],
        },
      ],
    },
  ],
});
