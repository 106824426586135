import { createAction } from 'redux-actions';
import { startLoad, endLoad } from './loaderActions';
import rest from '../services/helseoversikt/rest';

export const saveSearchHandbookArticles = createAction('HANDBOOK/SAVE_SEARCH_ARTICLES');
export const saveHandbookFoldersAndArticles = createAction('HANDBOOK/SAVE_HANDBOOK_FOLDERS_AND_ARTICLES');
export const selectFolderOrArticle = createAction('HANDBOOK/SELECT_FOLDER_OR_ARTICLE');
export const cleanFolderAndArticle = createAction('HANDBOOK/CLEAN_FOLDER_AND_ARTICLE');

export const getArticlesInHandbookBySearch = searchText => (dispatch, getState) => {
  const { userInformation: { portalCollab } = {} } = getState();
  dispatch(startLoad());
  rest.searchArticlesInHandbook({ searchText, urlKeys: { portalCollab } })
    .then(({ articles }) => {
      dispatch(saveSearchHandbookArticles(articles));
      dispatch(endLoad());
    })
    .catch(() => dispatch(endLoad()));
};

export const getFolderAndArticles = () => (dispatch, getState) => {
  const { userInformation: { portalCollab } = {} } = getState();
  rest.getFoldersAndArticels({ urlKeys: { portalCollab } })
    .then(({ folderAndArticles, levelsOfFolders }) =>
      dispatch(saveHandbookFoldersAndArticles({ folderAndArticles, levelsOfFolders }))
    );
};
