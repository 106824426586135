import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import lang from '../../translationWrapper';
import styled from 'styled-components';
import colors from '../../config/styles/colors';
import { Label } from '../../components';
import CarefeeedItemWeeksModal from './CarefeeedItemWeeksModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Button = styled.a`
  :hover {
    color: ${colors.text};
  }
  background: #caddf1 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: fit-content;
  padding: 9px 30px 9px 20px;
  color: ${colors.text};
`;

const Item = styled.div`
  background: #caddf1 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: fit-content;
  padding: 8px 30px 8px 20px;
  color: ${colors.text};
`;

const I = styled(Icon)`
  color: ${colors.text};
  margin: 0 10px !important;
`;

const CarefeedItemWeeksButton = ({ editing, weekFrom, weekTo, onSave }) => {
  const content = (
    <Fragment>
      <I name="calendar" />
      {weekFrom ? (
        <Fragment>
          {`${lang.carefeed.week} ${weekFrom}`}
          {weekTo && (
            <Fragment>
              <I name="arrow right" />
              {`${lang.carefeed.week} ${weekTo}`}
            </Fragment>
          )}
        </Fragment>
      ) : (
        lang.articleEdit.carefeedItemWeekButton
      )}
    </Fragment>
  );
  return editing ? (
    <Container>
      <Label>{lang.articleEdit.chooseCarefeedItemWeeks}</Label>
      <CarefeeedItemWeeksModal
        triggerElement={<Button>{content}</Button>}
        onSave={onSave}
        weekFrom={weekFrom}
        weekTo={weekTo}
      />
    </Container>
  ) : (
    <Item>{content}</Item>
  );
};

CarefeedItemWeeksButton.propTypes = {
  editing: PropTypes.bool,
  weekFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  weekTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSave: PropTypes.func,
};

export default CarefeedItemWeeksButton;
