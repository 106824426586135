import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { auth } from '../../services/firebase';
import { useHistory } from 'react-router-dom';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { authStateChange } from '../../actions/userAction';
import analytics, { Events } from '../../services/analytics/Analytics';

const Login = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  let unregisterAuthObserver;
  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      {
        provider: auth.PhoneAuthProvider.PROVIDER_ID,
        defaultCountry: 'NO',
      },
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  };
  useEffect(() => {
     // eslint-disable-next-line react-hooks/exhaustive-deps
     unregisterAuthObserver = auth().onAuthStateChanged(async user => {
      dispatch(authStateChange(user));
      
      if (user) {
        analytics.logEvent(Events.LOGIN);
        history.push('/');
      }
    });
    return function cleanup() {
      unregisterAuthObserver();
    };
  }, [dispatch]);

  return (
    <div className="contianer">
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth()} />
    </div>
  );
};

export default Login;
