import { ARTICLE_FIELD_MAINIMAGE, ARTICLE_FIELD_MAINVIDEO } from '../../../config/constants/constants';
import { getLang } from '../../../translationWrapper';
import { convertCarefeedItemToOptions } from './carefeedItems';

export const getValuesFromSelctedArticle = selectedArticle => {
  const language = getLang();
  return selectedArticle
    ? { ...selectedArticle,
        _id: selectedArticle._id,
        [ARTICLE_FIELD_MAINIMAGE]: selectedArticle[ARTICLE_FIELD_MAINIMAGE],
        title: selectedArticle.title && selectedArticle.title[language],
        oneliner: selectedArticle.oneliner && selectedArticle.oneliner[language],
        summary: selectedArticle.summary && selectedArticle.summary[language],
        keyTakeaway: selectedArticle.keyTakeaway && selectedArticle.keyTakeaway[language],
        body: selectedArticle.body && selectedArticle.body[language],
        carefeedItems: convertCarefeedItemToOptions(selectedArticle.carefeedItems),
      }
    : {};
};

export const getItemContentFromValue = value => {
  const language = getLang();
  let values = {...value};
  if (value.title && value.title[language]) values.title = value.title[language];
  if (value.oneliner && value.oneliner[language]) values.oneliner = value.oneliner[language];
  if (value.carefeedItems) values.carefeedItems = value.carefeedItems;
  if (value[ARTICLE_FIELD_MAINIMAGE])
    values[ARTICLE_FIELD_MAINIMAGE] = value[ARTICLE_FIELD_MAINIMAGE];
  return values;
};

export const getValuesNewArticle = () => ({
  _id: null,
  [ARTICLE_FIELD_MAINIMAGE]: {},
  [ARTICLE_FIELD_MAINVIDEO]: null,
  title: '',
  oneliner: '',
  summary: [],
  keyTakeaway: [],
  body: [],
  carefeedItems: convertCarefeedItemToOptions(),
});
