import React from 'react';
import { ThemeProvider } from 'styled-components';
import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme,
  StylesProvider,
} from '@material-ui/core/styles';

const themeConfig = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: 'lato',
    fontWeight: 700,
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      light: '#CADDF1',
      main: '#F4F6FA',
      dark: '#285888',
      contrastText: '#285888',
    },
  },
  shadows: ['none'],
  props: {
    // Name of the component
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true // disable ripple on the whole application
    }
  }
});

const CustomMuiTheme = ({ children }) => {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={themeConfig}>
        <ThemeProvider theme={themeConfig}>{children}</ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export default CustomMuiTheme;
