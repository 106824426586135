import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { loadPortalCollabs, selectPortalCollab } from '../../actions/adminActions';
import { ColumnsWrapper, Container } from '../../components';
import PortalCollabsPanel from './PortalCollabsPanel';
import AddUserButton from './AddUserButton';
import UsersWithAccessToPortalCollab from './UsersWithAccessToPortalCollab';
import analytics, { Events } from '../../services/analytics/Analytics';

const Admin = () => {
  const dispatch = useDispatch();
  const mapStateToProps = ({
    sanity: { portalCollabs: { selectedPortalCollab, portalCollabsIds } = {} } = {},
    userInformation: { portalAccess: { super_admin, status: { adminId } = {} } = {} },
  }) => ({
    oneAccess: portalCollabsIds && portalCollabsIds.length === 1 && portalCollabsIds[0],
    isDisabledButton:
      !selectedPortalCollab ||
      !(
        selectedPortalCollab &&
        (super_admin || (adminId && adminId.includes(selectedPortalCollab)))
      ),
  });
  const { oneAccess, isDisabledButton } = useSelector(mapStateToProps);
  useEffect(() => {
    if (oneAccess) {
      dispatch(selectPortalCollab(oneAccess));
    }
  }, [oneAccess, dispatch]);

  useEffect(() => {
    dispatch(loadPortalCollabs());
  });

  useEffect(() => {
    return () => dispatch(selectPortalCollab(null));
  }, [dispatch]);

  useEffect(() => {
    analytics.logEvent(Events.ADMIN);
  }, []);

  return (
    <Wrapper>
      <Container className="ui contianer">
        <ColumnsWrapper className="ui two a grid">
          {!oneAccess && <PortalCollabsPanel />}
          <div className="ui main container">
            <AddUserButton isDisabledButton={isDisabledButton} />
            <UsersWithAccessToPortalCollab />
          </div>
        </ColumnsWrapper>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 40px;
`;

export default Admin;
