import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Week from './Week';
import { setCarefeedWeeksRange } from '../../actions/carefeedAction';
import lang from '../../translationWrapper';
import { PARENT_MODULE } from '../../config/constants/constants';
import { PREGNANCY_WEEKS_LIMIT, PREGNANCY_MODULE } from '../../config/constants/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@material-ui/core/CircularProgress';

const WeeksAndArticles = ({ weeks, selectedWeek, module, month, selectedMonth }) => {
  const dispatch = useDispatch();
  const mapStateToProps = module => ({ sanity: { carefeed } = {} }) => {
    const { weekTo } = carefeed[module];
    return weekTo;
  };
  const lastWeek = useSelector(mapStateToProps(module));

  return (
    <InfiniteScroll
      style={{ overflow: 'initial' }}
      dataLength={weeks ? weeks.length : 0}
      next={() => dispatch(setCarefeedWeeksRange({ module, weekUpdate: lastWeek + 5 }))}
      hasMore={module === PREGNANCY_MODULE && weeks.length < PREGNANCY_WEEKS_LIMIT}
      loader={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      }
    >
      {weeks.map((week, index) => (
        <Week
          hasLabel={module === PARENT_MODULE}
          label={`${lang.carefeed.age} ${Math.trunc(month / 12)} ${lang.carefeed.month} ${
            month % 12
          }`}
          key={index}
          week={week}
          month={month}
          module={module}
          selectedMonth={selectedMonth}
          selectedWeek={selectedWeek}
        />
      ))}
    </InfiniteScroll>
  );
};

WeeksAndArticles.propTypes = {
  module: PropTypes.string,
  month: PropTypes.number,
  selectedMonth: PropTypes.number,
  selectedWeek: PropTypes.number,
  weeks: PropTypes.arrayOf(PropTypes.shape()),
};

export default WeeksAndArticles;
