import React from 'react';
import PropTypes from 'prop-types';
import lang from '../../translationWrapper';
import { Button, Modal } from 'semantic-ui-react';
import { InputText } from '../../components';
import { useEffect } from 'react';
import regex from '../../config/constants/regex.constants';

const CarefeeedItemWeeksModal = ({ triggerElement, onSave, weekFrom: from, weekTo: to }) => {
  const [open, setOpen] = React.useState(false);
  const [weekFrom, setWeekFrom] = React.useState(from || '');
  const [weekTo, setWeekTo] = React.useState(to || '');
  const [validate, setValidation] = React.useState({ weekFrom: null, weekTo: null });
  useEffect(() => {
    setValidation({
      weekFrom: weekFrom
        ? regex.weekValidation.test(weekFrom)
          ? null
          : { type: 'carefeedItemsWeek' }
        : { type: 'required' },
      weekTo: weekTo
        ? regex.weekValidation.test(weekTo)
          ? weekFrom && parseInt(weekFrom) < parseInt(weekTo)
            ? null
            : { type: 'higherThenWeekFrom' }
          : { type: 'carefeedItemsWeek' }
        : null,
    });
  }, [weekFrom, weekTo]);
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={triggerElement}
    >
      <Modal.Header>{lang.articleEdit.carefeeedItemWeeksModal}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <InputText
            validate={validate.weekFrom}
            name="weekFrom"
            label={lang.articleEdit.carefeedItemWeekFromLabel}
            value={weekFrom}
            onChange={val => setWeekFrom(val)}
          />
          <InputText
            validate={validate.weekTo}
            name="weekTo"
            label={lang.articleEdit.carefeedItemWeekToLabel}
            value={weekTo}
            onChange={val => setWeekTo(val)}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>{lang.buttons.Cancel}</Button>
        <Button
          positive
          disabled={!!(validate.weekFrom || validate.weekTo)}
          onClick={() => {
            onSave({ weekFrom, weekTo });
            setOpen(false);
          }}
        >
          {lang.buttons.Confirm}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

CarefeeedItemWeeksModal.propTypes = {
  weekFrom: PropTypes.string,
  weekTo: PropTypes.string,
  triggerElement: PropTypes.object,
  onSave: PropTypes.func,
};

export default CarefeeedItemWeeksModal;
