import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import H1 from '../../components/Text/H1';
import lang from '../../translationWrapper';
import colors from '../../config/styles/colors';

const EditArticleHeader = () => {
  let history = useHistory();
  const { portalCollabSlug, portalCollabEndorsement, articles } = useSelector(
    ({
      sanity: { articles: { articles = {} } = {} },
      userInformation: { portalCollabSlug, portalCollabEndorsement } = {},
    }) => ({
      portalCollabSlug,
      articles,
      portalCollabEndorsement,
    })
  );

  const hasAccessToEditArticle = useSelector(hasAccessToEditArticleSelector);

  const canCreateNewArticle = useMemo(() => {
    const municipalitiesArticlesCount = Object.values(articles)?.length;
    return portalCollabEndorsement || (!portalCollabEndorsement && municipalitiesArticlesCount < 3);
  }, [articles, portalCollabEndorsement]);

  return (
    <StyledWrapper>
      {hasAccessToEditArticle && (
        <AddArticleButton
          dissable
          className={`ui button ${canCreateNewArticle ? 'primary' : 'disabled'} right floated`}
          onClick={() => history.push(`/${portalCollabSlug}/article/create`)}
        >
          <i className="paper plane outline icon" />
          {lang.articleEdit.createNewArticle}
        </AddArticleButton>
      )}
      <ArticleTitle
        title={hasAccessToEditArticle ? lang.article.editArticles : lang.article.yourArticles}
      />
      {!canCreateNewArticle && <Info>{lang.articleEdit.reachedTheMaxAmountOfArticles}</Info>}
    </StyledWrapper>
  );
};
const hasAccessToEditArticleSelector = ({
  userInformation: { portalAccess: { super_admin, admin, edit } = {} },
}) => super_admin || admin || edit;

export default EditArticleHeader;

const StyledWrapper = styled.div`
  .ui.button {
    margin-top: -1px;
  }
`;

const ArticleTitle = styled(H1)`
  margin-bottom: 45px;
`;

const AddArticleButton = styled.button`
  background-color: rgb(19, 88, 140) !important;
  color: #fff !important;
`;

const Info = styled.div`
  align-items: center;
  display: flex;
  padding: 16px 16px;
  margin-bottom: 40px;
  border-radius: 4px;
  box-shadow: none;
  background-color: ${colors.yellowBackground};
`;
