import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

// reducers
import { reducer as modal } from 'redux-modal';
import user from './userReducer';
import userInformation from './userInformationReducer';
import articles from './articlesReducer';
import carefeed from './carefeedReducer';
import handbook from './handbookReducer';
import stats from './statsReducer';
import analytics from './analyticsReducer';
import portalCollabs from './sanityPortalCollabsReducer';
import adminPanelPhoneNumbers from './adminPanelPhoneNumbersReducer';
import addPhoneModal from './addPhoneModalReducer';
import loader from './loaderReducer';
import foldersAndArticles from './foldersAndArticlesReducer';

//middlewares
import adminPanelMiddleware from './middlewares/adminPanelMiddleware';

const sanity = combineReducers({
  articles,
  carefeed,
  portalCollabs,
  handbook,
});

const rootReducer = combineReducers({
  adminPanelPhoneNumbers,
  user,
  userInformation,
  analytics,
  stats,
  modal,
  sanity,
  addPhoneModal,
  loader,
  foldersAndArticles,
});

const middleware = [thunk, adminPanelMiddleware];

let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = () => {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));
};

export default store;
