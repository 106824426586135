import React from 'react';
import styled from 'styled-components';
import { sanityImage } from '../../services/sanity/sanityClient';
import PropTypes from 'prop-types';
import lang from '../../translationWrapper';

const Thumbnail = ({ image }) => {
  return (
    <ImageWrapper className="image">
      {image && image.asset ? (
        <img
          alt={lang.alt.article}
          src={sanityImage(image.asset).width(38).height(38).url()} />
      ) : (
          <i className="file icon" />
        )}
    </ImageWrapper>
  );
};

Thumbnail.propTypes = {
  image: PropTypes.shape({
    _type: PropTypes.oneOf(['image']),
    asset: PropTypes.shape({
      _ref: PropTypes.string,
      type: PropTypes.oneOf(['reference']),
    }),
  }),
};

const ImageWrapper = styled.div`
  height: 38px;
  width: 38px;
  > img {
    height: 38px;
    width: 38px;
  }
`;

export default Thumbnail;
