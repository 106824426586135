import React from 'react';
import { useRef, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ListOfItems } from '../../components';
import { PREGNANCY_MODULE, PARENT_MODULE } from '../../config/constants/constants';
import lang from '../../translationWrapper';
import colors from '../../config/styles/colors';
import fonts from '../../config/styles/fonts';
import analytics, { Events } from '../../services/analytics/Analytics';

const Week = ({ week, selectedWeek, month, module, selectedMonth, hasLabel, label }) => {
  const divRef = useRef(null);

  useEffect(() => {
    if (module === PREGNANCY_MODULE) {
      if (selectedWeek !== 0 && selectedWeek === week.weekNr) {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        const { top } = divRef.current.getBoundingClientRect();
        window.scrollTo(0, scrollPosition + top - 150);
      }
    } else {
      if (selectedMonth === month) {
        window.scrollTo(0, 0);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWeek, divRef, week, selectedMonth, month]);

  const onClickEventHandler = title => {
    const EVENTS = {
      [PREGNANCY_MODULE]: Events.PREGGUIDE_CLICKARTICLE,
      [PARENT_MODULE]: Events.PARENTGUIDE_CLICKARTICLE,
    };
    analytics.logEvent(EVENTS[module], { title });
  };

  return (
    <div ref={divRef}>
      <div className="header">
        {hasLabel ? (
          <Fragment>
            <Label>{label}</Label>
            <WeekTitle>
              {lang.carefeed.week} {week.weekNr}
            </WeekTitle>
          </Fragment>
        ) : (
          <Title>
            {lang.carefeed.week} {week.weekNr}
          </Title>
        )}
      </div>
      <Weeks className="ui large list">
        <ListOfItems onClickHandler={onClickEventHandler} articles={week.items} />
      </Weeks>
    </div>
  );
};

Week.propTypes = {
  hasLabel: PropTypes.bool,
  label: PropTypes.string,
  module: PropTypes.string,
  month: PropTypes.number,
  selectedMonth: PropTypes.number,
  selectedWeek: PropTypes.number,
  week: PropTypes.shape({
    weekNr: PropTypes.number,
    weeks: PropTypes.arrayOf(PropTypes.shape()),
  }),
};

const Label = styled.h2`
  font-size: 14px;
  color: ${colors.grey};
  margin: 0;
  margin-bottom: 5px;
`;

const Weeks = styled.div`
  margin-bottom: 40px !important;
`;

const WeekTitle = styled.h3`
  margin: 0;
  font-size: 1.71428571rem;
`;

const Title = styled.h2`
  color: ${colors.text};
  ${fonts.lato(28, 800, 34)};
`;

export default Week;
