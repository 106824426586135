import React from 'react';
import PropTypes from 'prop-types';
import ArticleItem from '../articleItem/ArticleItem';

const ArticleList = ({ articles, analyticsEvent, useNumbers = false, clickable = true }) => (
  <div className="one column row">
    <div className="ui relaxed divided items">
      {articles.map((article, i) => (
        <ArticleItem
          analyticsEvent={analyticsEvent}
          useNumbers={useNumbers}
          article={article}
          index={i}
          key={`articleItem${article._id}`}
          clickable={clickable}
        />
      ))}
    </div>
  </div>
);

ArticleList.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape()),
  useNumbers: PropTypes.bool,
  analyticsEvent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.any
  ]),
  clickable: PropTypes.bool,
};

export default ArticleList;
