import lang from '../../translationWrapper';

export default {
  modules: {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, false] }],
        ['bold', 'italic', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image', 'video'],
      ],
      handlers: {
        // change placeholder value for video input
        video: function () {
          const that = this;
          const tooltip = that.quill.theme.tooltip;
          const input = tooltip.root.querySelector('input[data-video]');

          input.dataset.video = lang.articleEdit.video_tooltip_input_placeholder;
          input.placeholder = lang.articleEdit.video_tooltip_input_placeholder;

          // Add default behavior because custom handler overrides default handler
          // https://github.com/quilljs/quill/blob/e9f628b5562e78126291bb25f63d0dfc3f6c6f86/themes/base.js#L183
          tooltip.edit('video');
        },
      },
    },
  },
  formats: [
    'header',
    'bold',
    'italic',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image',
    'video',
    'break',
  ],
};
