import React, { Fragment, useEffect } from 'react';
import HomeArticles from './HomeArticles';
import HomeStatistics from './HomeStatistics';
import analytics, { Events } from '../../services/analytics/Analytics';

const Home = () => {
  useEffect(() => {
    analytics.logEvent(Events.LANDING_PAGE);
  }, []);

  return (
    <Fragment>
      <div className="ui two column stackable padded grid">
        <div
          className="column"
          style={{
            paddingLeft: '60px',
            paddingRight: '60px',
            paddingTop: 0,
            marginTop: '20px',
            borderRight: '1px solid #3B3D4433',
          }}
        >
          <HomeStatistics />
        </div>
        <div
          className="column"
          style={{
            paddingLeft: '60px',
            paddingRight: '60px',
            marginTop: '20px',
            paddingTop: 0,
          }}
        >
          <HomeArticles />
        </div>
      </div>
    </Fragment>
  );
};

export default Home;
