import React from 'react';
import PropTypes from 'prop-types';
import lang from '../../translationWrapper';
import { useTextValidation } from '../../hooks';
import { Label } from 'semantic-ui-react';
import Description from '../label/Label';
import styled, { css } from 'styled-components';
import Input from './Input';

const Container = styled.div`
  margin-bottom: 10px;
  ${props =>
    props.isHidden &&
    css`
      display: none;
    `}
`;

const FormInputText = ({ value, name, editing, register, registerOptions, validate, rules }) => {
  const validation = useTextValidation(validate, rules, value);
  return (
    <Container isHidden={!(value || editing)}>
      {editing ? (
        <div>
          <Description>
            {lang.articleEdit[`fieldName_${name}`] ? lang.articleEdit[`fieldName_${name}`] : name}
          </Description>
          <Input
            type="text"
            name={name}
            defaultValue={value}
            {...register(name, registerOptions)}
          />
          {validation ? (
            <Label basic color="red" pointing>
              {validation}
            </Label>
          ) : null}
        </div>
      ) : (
        <div id={name}>{value}</div>
      )}
    </Container>
  );
};

FormInputText.propTypes = {
  editing: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  validate: PropTypes.object,
  rules: PropTypes.object,
  register: PropTypes.func,
  registerOptions: PropTypes.object,
};

export default FormInputText;
