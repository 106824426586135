import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CalendarCell from './CalendarCell';
import PregnancyCalendarHeader from './PregnancyCalendarHeader';
import { setCarefeedWeeksRange } from '../../actions/carefeedAction';
import { PREGNANCY_WEEKS } from './Carefeed.constants';
import { Table, Row } from './Carefeed.styles';
import lang from '../../translationWrapper';
import analytics, { Events } from '../../services/analytics/Analytics';

const PregnancyCalendar = ({ selectedWeek, selectWeek, module }) => {
  const dispatch = useDispatch();
  const mapStateToProps = module => ({ sanity: { carefeed } = {} }) => {
    const { weekTo } = carefeed[module];

    return weekTo;
  };
  const lastWeek = useSelector(mapStateToProps(module));

  return (
      <Table>
        <PregnancyCalendarHeader title={lang.carefeed.weeks} colSpan="8" />
        <tbody>
          {PREGNANCY_WEEKS.map((rowItems, index) => (
            <Row key={index}>
              {rowItems.map((item, index) => (
                <CalendarCell
                  key={`${item} ${index}`}
                  selected={item === selectedWeek}
                  item={item}
                  onClick={() => {
                    if (item > lastWeek) {
                      dispatch(setCarefeedWeeksRange({ module, weekUpdate: item }));
                    }
                    analytics.logEvent(Events.PREGGUIDE_CLICKWEEK, { week: item });
                    selectWeek(item);
                  }}
                />
              ))}
            </Row>
          ))}
        </tbody>
      </Table>
  );
};

PregnancyCalendar.propTypes = {
  module: PropTypes.string,
  selectedWeek: PropTypes.number,
  selectWeek: PropTypes.func,
};

export default PregnancyCalendar;
