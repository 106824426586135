import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStatistics } from '../../actions/statsAction';
import lang from '../../translationWrapper';
import ArticleList from '../../components/articleList/ArticleList';
import H1 from '../../components/Text/H1';
import H2 from '../../components/Text/H2';
import CountByWeeksChart from '../../components/CountByWeeksChart/CountByWeeksChart';
import CurrentWeekCounter from './CurrentWeekCounter';
import analytics, { Events } from '../../services/analytics/Analytics';

const analyticsEventHadler = title => analytics.logEvent(Events.LANDING_PAGE_CLICKTOP10, { title });

const HomeStatistics = ({ getStatistics, articles, countByWeek }) => {
  useEffect(() => {
    getStatistics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Fragment>
      <H1 title={lang.stats.statistics} />
      <CurrentWeekCounter />
      <H2 title={lang.stats.newUsersInWeeks} />
      <CountByWeeksChart countByWeek={countByWeek} />
      <H2 title={lang.stats.top10Read} />
      {articles && (
        <ArticleList analyticsEvent={analyticsEventHadler} articles={articles} useNumbers />
      )}
    </Fragment>
  );
};

HomeStatistics.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape()),
  getStatistics: PropTypes.func,
  name: PropTypes.string,
  userCount: PropTypes.number,
};

const mapStateToProps = ({ stats: { mostReadArticles, countByWeek } }) => ({
  articles: mostReadArticles.sort((a, b) => b.count - a.count).slice(0, 10),
  countByWeek,
});

const dispatchToProps = { getStatistics };

export default connect(mapStateToProps, dispatchToProps)(HomeStatistics);
