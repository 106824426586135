import colors from '../../config/styles/colors';

export default ticks => ({
  tooltips: {
    backgroundColor: colors.chartToolTipBackgroundColor,
    callbacks: {
      labelTextColor: function (tooltipItem, chart) {
        return colors.chartToolTipLabelTextColor;
      },
    },
  },
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          color: colors.chartGridLines,
        },
        ticks,
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        scaleLabel: {
          display: false,
        },
      },
    ],
  },
});
