import { handleActions } from 'redux-actions';
import { setCarefeedArticles, setCarefeedWeeksRange, setCarefeedMonthRange } from '../actions/carefeedAction';
import { PARENT_MODULE, PREGNANCY_MODULE, PREGNANCY_WEEKS_LIMIT } from '../config/constants/constants';
import { WEEKS_IN_MONTHS } from '../config/constants/weeksInMonthForChild.constants';
import { formModuleWeeks } from '../helpers/articles.helpers';

const initialStateForModule = {
  articlesIds: [],
  articles: {},
  weeks: {},
};

const initialState = {
  [PARENT_MODULE]: {
    ...initialStateForModule,
    ...WEEKS_IN_MONTHS[0],
    month: 0,
  },
  [PREGNANCY_MODULE]: {
    ...initialStateForModule,
    weekFrom: 1,
    weekTo: 10,
  },
};

export default handleActions(
  {
    [setCarefeedArticles]: (
      state,
      { payload: { articlesIds, articles, module } = {} }
    ) => {
      const allArticlesMap = { ...state[module].articles, ...articles };
      const allWeeksArticles = formModuleWeeks(Object.values(allArticlesMap));
      const weeks = Object.keys(allWeeksArticles)
        .filter(weekNr => weekNr <= state[module].weekTo && weekNr >= state[module].weekFrom)
        .map(weekNr => ({ weekNr, articles: allWeeksArticles[weekNr] }))
        .reduce((obj, item) => {
          obj[item.weekNr] = item.articles;
          return obj;
        }, {});

      return {
        ...state,
        [module]: {
          ...state[module],
          weeks: { ...state[module].weeks, ...weeks },
          articles: allArticlesMap,
          articlesIds: [...state[module].articlesIds, ...articlesIds],
        },
      };
    },
    [setCarefeedWeeksRange]: (state, { payload: { module, weekUpdate } = {} }) => ({
      ...state,
      [module]: {
        ...state[module],
        ...(
          weekUpdate > PREGNANCY_WEEKS_LIMIT ?
            {
              weekFrom: state[module].weekTo,
              weekTo: PREGNANCY_WEEKS_LIMIT,
            } :
            {
              weekFrom: state[module].weekTo,
              weekTo: weekUpdate,
            }
        )
      },
    }),
    [setCarefeedMonthRange]: (state, { payload: { module, month } = {} }) => ({
      ...state,
      [module]: {
        ...state[module],
        month,
        ...WEEKS_IN_MONTHS[month],
      },
    }),
  },
  initialState
);
