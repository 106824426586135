import { handleActions } from 'redux-actions';
import { setPortalCollabs, selectPortalCollab } from '../actions/adminActions';
import { setUserData } from '../actions/userAction';

const initialState = {
  portalCollabsIds: [],
  portalCollabsData: {},
  selectedPortalCollab: null,
};

export default handleActions(
  {
    [setPortalCollabs]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [selectPortalCollab]: (state, { payload }) => ({
      ...state,
      selectedPortalCollab: payload,
    }),
    [setUserData]: (state, { payload: { portalIds, portals } }) => ({
      ...state,
      portalCollabsIds: portalIds,
      portalCollabsData: portals,
    }),
  },
  initialState
);
