import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Thumbnail from './Thumbnail';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import colors from '../../config/styles/colors';

const ListOfItems = ({ articles, onClickHandler }) => {
  const portalCollabSlug = useSelector(
    ({ userInformation: { portalCollabSlug } = {} }) => portalCollabSlug
  );

  return articles.map(
    ({ _id, title, oneliner, mainImage, filters, weekFrom, weekTo, languages }, i) => (
      <Link
        className="item"
        key={`${_id}-${i}`}
        onClick={() => onClickHandler(title)}
        to={`/${portalCollabSlug}/article/${_id}`}
      >
        <Thumbnail image={mainImage} />
        <div className="content">
          <TextContainer>
            <h4 className="ui header">{title}</h4>
            {weekFrom !== weekTo && (
              <WeekInterval>
                ({weekFrom} - {weekTo})
              </WeekInterval>
            )}
            {languages && (
              <WeekInterval>
                {Object.keys(languages)
                  ?.filter(key => key !== '_type')
                  .join(', ')}
              </WeekInterval>
            )}
          </TextContainer>
          <Description className="description">{oneliner}</Description>
          <TextContainer>
            {filters?.map(({ label, match }) => (
              <Filter className="description">
                {match === 'match' ? '' : 'IKKE '} {label}
              </Filter>
            ))}
          </TextContainer>
        </div>
        <div className="ui divider" />
      </Link>
    )
  );
};

ListOfItems.defaultProps = {
  onClickHandler: () => {},
  articles: [],
};

ListOfItems.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape()),
  onClickHandler: PropTypes.func,
};

const WeekInterval = styled.div`
  padding-left: 10px;
  color: ${colors.grey} !important;
  font-size: 0.9em;
`;
const Description = styled.div`
  color: ${colors.grey} !important;
  font-size: 0.9em;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const Filter = styled.div`
  background-color: ${colors.lightGrey} !important;
  font-size: 0.9em;
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
`;

export default ListOfItems;
