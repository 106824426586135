import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import withProps from '../../hocs/withProps';
import optionsConfig from './CountByWeeksChart.config';
import lang from '../../translationWrapper';
import colors from '../../config/styles/colors';

const CountByWeeksChartWrapper = styled.div`
  margin-bottom: 70px;
`;

const CountByWeeksChart = ({ options, data }) => {
  return (
    <CountByWeeksChartWrapper>
      <Line data={data} options={options} />
    </CountByWeeksChartWrapper>
  );
};

CountByWeeksChart.propTypes = {
  countByWeek: PropTypes.object,
};

export default withProps(({ countByWeek }) => {
  const weeks = Object.values(countByWeek).sort((weekA, weekB) => weekA.index - weekB.index);
  const values = weeks.map(({ count }) => count);
  const min = Math.min(...values);
  const max = Math.max(...values);
  let ticks = {};
  if (max === 0) {
    ticks.beginAtZero = true;
    ticks.suggestedMax = 10;
  }
  if (Math.abs(max - min) > 6) {
    ticks.stepSize = 1;
  }
  return {
    options: optionsConfig(ticks),
    data: {
      labels: weeks.map(({ weekNr }) => `${lang.stats.week} ${weekNr}`),
      datasets: [
        {
          label: lang.stats.newUserChartLabel,
          backgroundColor: colors.chartBackgroundColor,
          borderColor: colors.chartPorderColor,
          pointBorderColor: colors.chartPointBorderColor,
          pointBackgroundColor: colors.chartPointBackgroundColor,
          pointRadius: 6,
          pointBorderWidth: 2,
          data: values,
        },
      ],
    },
  };
})(CountByWeeksChart);
