import React, { useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getArticleList } from '../../actions/articlesAction';
import ArticleList from '../../components/articleList/ArticleList';
import EditArticleHeader from './EditArticleHeader';
import analytics, { Events } from '../../services/analytics/Analytics';

const analyticsEventHadler = title => {
  analytics.logEvent(Events.LANDING_PAGE___CLICK_EDITABLE_ARTICLE, { title });
};

const HomeArticles = () => {
  const dispatch = useDispatch();
  const articles = useSelector(mapStateToProps);
  const portalCollab = useSelector(({ userInformation: { portalCollab } }) => portalCollab);

  useEffect(() => {
    dispatch(getArticleList());
  }, [dispatch, portalCollab]);

  return (
    <Fragment>
      {<EditArticleHeader />}
      <ArticleList articles={articles} analyticsEvent={analyticsEventHadler} />
    </Fragment>
  );
};

const mapStateToProps = ({
  sanity: {
    articles: { articles: articlesMap, articlesIds },
  },
}) => articlesIds.map(id => articlesMap[id]);

export default HomeArticles;
