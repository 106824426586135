import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import colors from '../../config/styles/colors';

const PregnancyCalendarHeader = ({ title, colSpan }) => (
  <thead>
    <Row>
      <Header colSpan={colSpan}>
        <Title>{title}</Title>
      </Header>
    </Row>
  </thead>
);

PregnancyCalendarHeader.propTypes = {
  title: PropTypes.string,
  colSpan: PropTypes.string,
};

export default PregnancyCalendarHeader;

const Header = styled.th`
  padding: 20px 0;
  color: ${colors.text};
  background-color: ${colors.backgroundSurface};
`;

const Title = styled.h3`
  text-align: center;
  font-size: 28px;
  font-weight: 900;
  line-height: 34px;
`;

export const Row = styled.tr`
  background-color: ${colors.lighterBlue};
  &:first-child > th {
    border-radius: 8px 8px 0 0;
  }
`;
