import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({ children, basic }) => (
  <div className="ui dimmer modals page visible active">
    <div className={`ui ${basic ? 'basic' : 'standard'} modal visible active`}>{children}</div>
  </div>
);

Modal.propTypes = {
  children: PropTypes.node,
};

export default Modal;
