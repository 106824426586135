export const parseReferenceCarefeedItem = articles =>
  articles.map(({ _id, mainImage, oneliner, referencedCareFeedItems, title, draft = false }) => {
    referencedCareFeedItems.sort((a, b) => a.weekFrom > b.weekFrom);
    let pregnantWeeks = carefeedItemOfTypeToWords(referencedCareFeedItems, 'Gravid');
    let parentWeeks = carefeedItemOfTypeToWords(referencedCareFeedItems, 'Foreldre');
    return {
      _id,
      mainImage,
      oneliner,
      draft,
      title,
      parentWeeks: parentWeeks.length ? parentWeeks.join(', ') : null,
      pregnantWeeks: pregnantWeeks.length ? pregnantWeeks.join(', ') : null,
    };
  });

const carefeedItemOfTypeToWords = (referencedCareFeedItems, moduleTitle) =>
  referencedCareFeedItems &&
  referencedCareFeedItems
    .filter(({ module }) => module && module.title === moduleTitle && module.weekFrom)
    .map(
      carefeedItem =>
        `${carefeedItem.weekFrom}${carefeedItem.weekTo ? '-' + carefeedItem.weekTo : ''}`
    );

const findWeekNrs = item =>
  item.weekTo && typeof item.weekFrom === 'number'
    ? new Array(item.weekTo - item.weekFrom + 1).fill(item.weekFrom).map((item, index) => item + index)
    : [item.weekFrom || item.weekTo];

export const formModuleWeeks = articles =>
  articles.reduce((obj, article) => {
    findWeekNrs(article).forEach(weekNr => {
      if (obj[weekNr]) {
        obj[weekNr].push(article.carefeedItemId);
      } else {
        obj[weekNr] = [article.carefeedItemId];
      }
    });
    return obj;
  }, {});

export const hasWeeksInRange = ({ weeks, weekFrom, weekTo }) =>
  new Array(weekTo - weekFrom)
    .fill(weekFrom)
    .map((week, index) => week + index)
    .map(week => weeks[week])
    .every(weeks => !!weeks);
